import GarbageBin from 'styles/Icons/custom/GarbageBin';
import React from 'react';
import css from 'scenes/User/Settings/Privacy/PrivacyLists/ListHeader.module.css';
import { usePrivacyListContext } from 'scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';

export function ListHeader({ list }) {
  const { handleRemove } = usePrivacyListContext();

  return (
    <div className={css.container}>
      <div className={css.text}>{list.name}</div>
      <div className={css.removeButton} onClick={() => handleRemove(list)}>
        <GarbageBin
          className={css.removeIcon}
          backgroundClassName={css.removeIconBackground}
          detailLinesClassName={css.removeIconDetailLines}
          outlineClassName={css.removeIconOutline}
        />
      </div>
    </div>
  );
}
