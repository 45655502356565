import cloneDeep from 'lodash/cloneDeep';

const setFieldUpdeep = (object, path, value) => {
  let obj = cloneDeep(object);
  let keyList = path.split('.');
  let node = obj;

  for (let i = 0; i < keyList.length - 1; i++) {
    let key = keyList[i];
    if (!node[key]) node[key] = {};
    node = node[key];
  }
  node[keyList[keyList.length - 1]] = value;
  return obj;
};

export default setFieldUpdeep;
