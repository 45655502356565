import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDropzoneStyle } from 'scenes/User/Settings/Blog/Dropzone.style';
import styled from 'styled-components';

const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

const handleDropRejected = (fileRejections, event, maxSize) => {
  const rejection = fileRejections[0];

  if (!rejection.file) {
    window.alert(`Случилось что-то непредвиденное: файл не найден`);
    return;
  }

  if (rejection.file.size > maxSize) {
    window.alert(
      `Превышен максимальный размер в ${maxSize / 1024 / 1024}Mб (актуальный размер ${(Math.round(
        rejection.file.size / 1024 / 1024 * 20) / 20).toFixed(1)}Мб).`
    );
  } else if (!ALLOWED_FILE_TYPES.includes(rejection.file.type)) {
    window.alert(`Разрешены только .png, .jpeg или .gif файлы.`);
  } else {
    window.alert(
      `Случилось что-то непредвиденное, обратитесь к разработчикам, предоставив информацию ниже:\n${rejection.errors
        .map((error) => `Message: ${error.message} Code: ${error.code}`)
        .join('\n')}`
    );
  }
};

export const Container = styled.div`
  margin-left: 16px;
  padding: 16px;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.backgroundLighter};
  font-weight: bold;
  border: none;
  border-radius: 0px;
  text-transform: lowercase;
  font-size: 16px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${(props) => props.width || 'auto'};
  background: ${(props) => props.theme.backgroundDark};
  color: ${(props) => props.theme.textLighter};
  font-weight: bold;
  padding: 15px 30px;
  font-weight: bold;
  font-size: 16px;
`;

function DropzoneImage({maxSize, accept, onAccepted}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept,
    maxFiles: 1,
    maxSize,
    onDropAccepted: (files, event) => {
      onAccepted(files);
    },
    onDropRejected: (fileRejections, event) => handleDropRejected(fileRejections, event, maxSize)
  });
  const {style} = useDropzoneStyle(isDragActive, isDragAccept, isDragReject);

  return (
    <Container {...getRootProps({className: 'dropzone', style})}>
      <input {...getInputProps()} />
      <div>загрузить</div>
    </Container>
  );
}

export default DropzoneImage;
