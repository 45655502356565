import { getProfileApi } from 'api/profiles';
import { SetCommunitiesAction } from 'store/user/actions/SetCommunitiesAction';

export const setCommunitiesThunk = ({ profileId, token }) => async (
  dispatch
) => {
  try {
    const resProfile = await getProfileApi({ profileId, token });
    if (!resProfile.error) {
      const profile = resProfile.data;
      dispatch(new SetCommunitiesAction(profile.id, profile.communities ?? []));
      return { status: 'ok' };
    } else {
      alert(`Что-то пошло не так, попробуйте позднее.`);
      return { error: resProfile.error };
    }
  } catch (e) {
    console.error(e);
    const message = `Не получается загрузить данные о профиле, попробуйте позднее.`;
    alert(message);
    return { error: message };
  }
};
