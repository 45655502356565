import XRegExp from 'xregexp';
import config, { UNSUPPORTED_EMAIL_DOMAIN_LIST } from 'configs/dybr';
import { getProfileByBlogSlug } from 'api/profiles';
import { checkNickname } from 'api/nicknames';

export async function validateName(name, { token }) {
  const unicode = XRegExp(
    '^[\\p{Hiragana}\\p{Cyrillic}\\p{Katakana}\\p{Latin}\\p{Common}\\p{Sc}\\pN+$]'
  );

  let errors = [];

  const MIN_LENGTH = 1;
  const MAX_LENGTH = config.MAX_LENGTH.nickname;
  const INVALID_LENGTH = `ник должен быть от ${MIN_LENGTH} до ${MAX_LENGTH} символов`;
  const INVALID_CHARACTERS =
    'символы, которые можно использовать: - _ ~ ! @ # $ & * ( ) + ? = / |  . , ; : < > [ ]';
  const NO_MIXING = 'смешивать латиницу и кирилицу нельзя';
  const NO_SIDE_SPACES = 'имя не может начинаться или заканчиваться пробелом';
  const NO_DOUBLE_SPACES =
    'имя не может содержать больше одного пробела подряд';

  if (name.length < MIN_LENGTH || name.length > MAX_LENGTH) {
    errors.push(INVALID_LENGTH);
  }

  // Side spaces
  if (name[0] === ' ' || name[name.length - 1] === ' ') {
    errors.push(NO_SIDE_SPACES);
  }

  // Double spaces after first or before last character
  if (name.search('  ') > -1) {
    errors.push(NO_DOUBLE_SPACES);
  }

  const LATIN = /[a-zöä]/gi;
  const CYRILLICS = /[а-яё]/gi;
  // The final space before the closing ] is IMPORTANT! LEAVE IT THERE.
  const INVALID = /[^a-zöäа-яё0-9\-_~!^@#$&*()+?=/|\\.,;:<>[\] ]/gi;

  const hasLatin = LATIN.test(name);
  const hasCyrillics = CYRILLICS.test(name);

  // Invalid characters
  if (INVALID.test(name) && !unicode.test(name))
    errors.push(INVALID_CHARACTERS);

  // Mixed characters
  if (hasLatin && hasCyrillics) errors.push(NO_MIXING);

  // async validation
  if (!errors.length) {
    const res = await checkNickname({ nickname: name, token });
    if (res.error) errors = ['никнейм занят'];
  }

  return errors.join('; ');
}

export const validateSlug = async ({ slug, token }) => {
  if (!slug || !slug.length) {
    return 'придумайте короткий адрес';
  } else if (/[^a-z0-9_-]/i.test(slug) || slug.indexOf(' ') !== -1) {
    return 'можно использовать только латиницу, цифры, подчеркивания и дефис';
  } else if (slug.length > config.MAX_LENGTH.blogSlug) {
    return `не больше ${config.MAX_LENGTH.blogSlug} символов`;
  }
  // async validation

  const res = await getProfileByBlogSlug({ slug, token });
  if (!res.error) return 'короткий адрес занят'; // a blog was found

  return '';
};

export const validateTitle = title => {
  if (!title || !title.length) {
    return 'придумайте название своему дневнику';
  } else if (title.length > config.MAX_LENGTH.blogTitle) {
    return `не больше ${config.MAX_LENGTH.blogTitle} символов`;
  }
};

export const validate = (value, rules) => {
  for (const [rule, message] of rules) {
    if (rule(value)) {
      return message;
    }
  }
  return '';
};

export const validateBlogSlug = value => {
  return validate(value, [
    [
      v => v.length > config.MAX_LENGTH.blogSlug || v.length < 4,
      'Адрес должен быть от 4 до 20 символов'
    ],
    [
      v => !/^[a-z][a-z0-9-_]*[a-z0-9]+$/.test(v),
      'Допустимы строчные латинские буквы, цифры и знаки _ -'
    ]
  ]);
};

/**
 * Check email against the list of unsupported email domains.
 *
 * @param email
 * @returns emailDomain unsuppored email domain
 */
export const validateEmailDomain = email => {
  const bannedEmailDomain = UNSUPPORTED_EMAIL_DOMAIN_LIST?.find(
    bannedEmailDomain => email.endsWith(`@${bannedEmailDomain}`)
  );
  return bannedEmailDomain;
};

// export function validate(values, { token }) {
//   const errors = {};
//
//   if (!values.confirmationToken) {
//     errors.confirmationToken = 'Нужен код, который мы прислали вам на email.';
//   }
//
//   if (!values.email) {
//     errors.email = 'Required';
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = 'Не похоже на email';
//   }
//   if (!values.termsOfService) {
//     errors.termsOfService = 'Надо принять правила, иначе никак';
//   }
//   if (
//     values.nickname === '!' &&
//     (!values.newNickname || !values.newNickname.length)
//   ) {
//     errors.newNickname = 'Безымянным у нас тут быть нельзя';
//   } else if (values.newNickname) {
//     const e = validateName(values.newNickname, { token });
//     if (e) errors.newNickname = e;
//   }
//
//   if (!values.title) {
//     errors.title = 'Придумайте название своему дневнику';
//   } else if (values.title.length > 75) {
//     errors.title = 'Слишком длинное';
//   }
//
//   if (!values.slug) {
//     errors.slug =
//       'Придумайте короткое название, чтобы мы могли использовать его в ссылках.';
//   } else if (
//     /[^a-z0-9_-]/i.test(values.slug) ||
//     values.slug.indexOf(' ') !== -1
//   ) {
//     errors.slug =
//       'Можно использовать только латиницу, цифры, подчеркивания и дефис.';
//   } else if (values.slug.length > 20) {
//     errors.slug = 'Короткий адрес - это до 20 символов.';
//   }
//
//   return errors;
// }

/*
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


export const asyncValidate = (values) => {
  return sleep(1000).then(() => {
    // simulate server latency
    if (!['invite123'].includes(values.invite)) {
      const error = { invite: 'Недействительный код приглашения' };
      throw error;
    }

    if (values.nickname && ['simoroshka'].includes(values.nickname)) {
      const error = { nickname: 'Ник занят' };
      throw error;
    }
  });
};
*/
