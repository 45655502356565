import produce from 'immer';
import keyBy from 'lodash/keyBy';

import { MarkNotificationReadAction } from 'store/notifications/actions/MarkNotificationReadAction';
import { ClearNotificationsAction } from '../actions/ClearNotificationsAction';
import { StoreNotificationsAction } from 'store/notifications/actions/StoreNotificationsAction';
import { StoreEntriesAction } from 'store/notifications/actions/StoreEntriesAction';
import { LogoutAction } from 'store/user/actions/LogoutAction';
import { defaultNotificationState } from 'store/_setup/initialState';
import cloneDeep from 'lodash/cloneDeep';
import { MarkAllNotificationsReadAction } from 'store/notifications/actions/MarkAllNotificationsReadAction';

/**
  structure:

  list of notifications comes like this:
  items: {
    "457416": {
      type: "notifications"
      id: "457416"
      blog: "1",
      comment: "1482630",
      entry: "384832",
      state: "new"
    }
  }

  then we load entries by these ids

  entries: {
    '1': { id: '1', content, title, etc.}
  }

  then, when the sidebar is opened and comments expanded, they are loaded, with profiles

  comments: {
      '1482630': {
        "type": "comments",
        "id": "1482630",
        "content": "my content",
        "createdAt": "2020-05-30T21:59:28.272793Z",
        "updatedAt": "2020-05-30T21:59:28.272793Z"
        profile: { nickname, id, ...}
      }
      '1482631': { ... }
  }

 */

export const notificationsReducer = produce((draft, action) => {
  switch (action.type) {
    case StoreNotificationsAction.type:
      draft.notifications.items = keyBy(action.items, 'id');
      return;
    case StoreEntriesAction.type:
      draft.notifications.entries = keyBy(action.entries, 'id');
      return;
    // case StoreCommentsAction.type:
    //   draft.notifications.comments = keyBy(action.comments, 'id');
    //   return;
    case MarkNotificationReadAction.type:
      delete draft.notifications.items[action.notificationId];
      return;
    case MarkAllNotificationsReadAction.type:
      draft.notifications.items = {};
      return;
    case ClearNotificationsAction.type:
      draft.notifications = cloneDeep(defaultNotificationState);
      return;
    case LogoutAction.type:
      draft.notifications = cloneDeep(defaultNotificationState);
      return;
    default:
      return;
  }
});
