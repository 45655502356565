import React from 'react';

function ErrorIcon() {
  return (
    <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.2442 8.73177C33.7512 -0.761287 18.304 -0.762223 8.81002 8.73177C-0.683969 18.2258 -0.683032 33.672 8.81002 43.166C18.3031 52.66 33.7502 52.66 43.2442 43.166C52.7373 33.672 52.7373 18.2248 43.2442 8.73177V8.73177Z"
        stroke="currentColor"
        strokeWidth="2.99672"
      />
      <path
        d="M34.8214 14.4487L26.027 23.2431L17.2326 14.4487L14.5266 17.1547L23.321 25.949L14.5266 34.7435L17.2326 37.4494L26.027 28.655L34.8214 37.4494L37.5273 34.7435L28.7329 25.949L37.5273 17.1547L34.8214 14.4487Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default ErrorIcon;
