import { confirmEmailApi } from 'api/auth';

export const confirmEmailThunk = ({ email, token }) => async (
  dispatch,
  getState
) => {
  const res = await confirmEmailApi({ email, token });
  if (!res.error) {
    return { status: 'ok', token: res.token };
  } else {
    // todo dispatch error
    return { error: res.error };
  }
};
