import React from 'react';

const Icon = ({ className }) => (
  <svg
    viewBox="-25 -25 580 580"
    className={`icon icon-profile ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="square-fill secondary-filling-area"
      d="M 0,5.394e-4 V 14.945851 551.68413 H 551.68359 V 5.394e-4 Z"
    />
    <path
      className="head-fill filling-area"
      d="m 275.14431,300.80451 c 60.47812,0 109.53188,-49.05449 109.53188,-109.53187 0,-60.47739 -49.0545,-109.196311 -109.53188,-109.196311 -60.47738,0 -109.53187,49.054481 -109.53187,109.196311 0,60.14182 49.05376,109.53187 109.53187,109.53187 z"
      id="path4520"
    />
    <path
      className="head-outline main-outline"
      d="m 275.14431,300.80451 c 60.47812,0 109.53188,-49.05448 109.53188,-109.53187 0,-60.47739 -49.0545,-109.196312 -109.53188,-109.196312 -60.47738,0 -109.53187,49.054482 -109.53187,109.196312 0,60.14183 49.05376,109.53187 109.53187,109.53187 z m 0,-190.16912 c 44.35009,0 80.63724,36.28644 80.63724,80.63725 0,44.35081 -36.28715,80.63651 -80.63724,80.63651 -44.35008,0 -80.63724,-36.28643 -80.63724,-80.63724 0,-44.35081 36.28643,-80.63652 80.63724,-80.63652 z"
      id="path6"
    />
    <path
      className="body-fill filling-area"
      d="M 78.345498,478.22354 H 473.3386 c 8.37437,0 15.00399,-6.23934 15.00399,-14.1207 0,-73.88823 -63.85463,-134.31243 -142.71401,-134.31243 H 206.05552 c -78.51013,0 -142.714009,60.0955 -142.714009,134.31243 0,7.88136 6.629634,14.1207 15.003987,14.1207 z"
      id="path4558"
    />
    <path
      className="body-outline main-outline"
      d="M 78.345497,478.22354 H 473.3386 c 8.37436,0 15.00399,-6.23935 15.00399,-14.1207 0,-73.88824 -63.85463,-134.31243 -142.71401,-134.31243 H 206.05552 c -78.51013,0 -142.71401,60.0955 -142.71401,134.31243 0,7.88135 6.629634,14.1207 15.003987,14.1207 z M 206.05552,358.03182 h 139.57306 c 57.225,0 104.33093,40.06413 111.65905,91.94961 H 94.396472 c 7.327368,-51.55679 54.434048,-91.94961 111.659048,-91.94961 z"
      id="path8"
    />
    <path
      className="square-outline main-outline"
      d="M 0,0 V 14.945312 551.68359 H 551.68359 V 0 Z M 29.888672,29.888672 H 521.79492 V 521.79492 H 29.888672 Z"
      id="rect4560"
    />
  </svg>
);

export default Icon;
