import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { classNames } from 'utils/helpers/classNames';

const Wrapper = styled.label`
  display: block;
  position: relative;
  &.alwaysOn {
    padding-left: 60px;
  }

  &:not(.alwaysOn) {
    padding-left: 50px;
  }

  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 39px;
    height: 20px;
    background: ${p => p.theme.backgroundScroll};
    border-radius: 15px;
    border: solid;
    border-width: 1px;
    border-color: ${p => p.theme.backgroundDark};
    transition-property: opacity, left, right, background;
    transition-duration: 0s, 0.2s, 0.2s;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    background: ${p => p.theme.backgroundDark};
    border-radius: 10px;
    transition-property: opacity, left, right;
    transition-duration: 0s, 0.2s, 0.2s;
  }

  input:checked ~ .checkmark:before,
  input:checked ~ .checkmark {
    background: ${p => p.theme.accentLighter};
  }

  &.alwaysOn input ~ .checkmark:before,
  &.alwaysOn input ~ .checkmark {
    background: ${p => p.theme.accentLighter};
  }

  input:checked ~ .checkmark:after {
    left: 20px;
    background: ${p => p.theme.accent};
  }

  &.alwaysOn input ~ .checkmark:after {
    background: ${p => p.theme.accent};
  }
  text-transform: lowercase;

  &.checked {
    color: ${p => p.theme.accent};
    font-weight: bold;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 26px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const LeftLabel = styled.div`
  margin-right: 20px;
  font-weight: bold;
  &.checked {
    color: ${p => p.theme.accent};
  }
`;

const propTypes = {
  label: PropTypes.string,
  labelElement: PropTypes.element,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const defaultProps = {
  label: '',
  labelElement: null,
  error: ''
};

const Switch = ({
  label,
  labelElement,
  checked,
  onClick,
  leftLabel,
  testId
}) => (
  <Container>
    {leftLabel && (
      <LeftLabel className={!checked ? 'checked' : ''}>{leftLabel}</LeftLabel>
    )}
    <Wrapper
      data-testid={testId}
      className={classNames({
        alwaysOn: Boolean(leftLabel),
        checked: checked
      })}
    >
      {label || labelElement}
      <input
        type="checkbox"
        checked={checked}
        onClick={onClick}
        onChange={() => {}}
      />
      <span className="checkmark" />
    </Wrapper>
  </Container>
);

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

export default Switch;
