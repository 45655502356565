import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

const NickWrapper = styled.span`
  display: inline-block;
  position: relative;
  margin: 5px 12px 0 0px;
  word-break: break-word;

  border-bottom: ${(props) =>
    (props.isReader && props.isFavorite) || props.isReader
      ? `2px solid ${props.theme.accent};`
      : props.isFavorite
      ? `2px solid ${props.theme.favUnderline};`
      : 'none;'};

  ${(props) =>
    props.isReader &&
    props.isFavorite &&
    `
  :before {
    position: absolute;
    bottom: -2px;
    left: 0px;
    display: block;
    content: '';
    border-bottom: 2px solid ${props.theme.favUnderline};
    width: 50%;
    box-sizing: border-box;
  }
  `};

  a:link,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: ${(props) => props.theme.text};
    text-decoration: none;
  }
`;

const NickWrapperBig = styled(NickWrapper)`
  margin: 20px;
  padding: 0 2px;
  min-height: 24px;
  font-size: 16px;
  font-weight: bold;
  border-width: 3px;

  :before {
    top: 3px;
    border-width: 3px;
    bottom: -3px;
  }
`;

export const NicknameUnderlinedBig = ({ profile }) => {
  const { isReader, isFavorite } = profile;

  return (
    <NickWrapperBig isReader={isReader} isFavorite={isFavorite}>
      <NicknameLink profile={profile} />
    </NickWrapperBig>
  );
};

export const NicknameUnderlinedSmall = ({ profile }) => {
  const { isReader, isFavorite } = profile;

  return (
    <NickWrapper isReader={isReader} isFavorite={isFavorite}>
      <NicknameLink profile={profile} />
    </NickWrapper>
  );
};

export const NicknameLink = ({ profile }) => {
  const { nickname, id, isReader, isFavorite } = profile;
  return (
    <Link
      to={`/profile/${id}`}
      title={
        isReader && isFavorite
          ? 'взаимная подписка'
          : isReader
          ? 'мой читатель'
          : isFavorite
          ? 'в избранном'
          : ''
      }
    >
      {nickname}
    </Link>
  );
};

export default {
  Small: NicknameUnderlinedSmall,
  Big: NicknameUnderlinedBig,
  Link: NicknameLink
};
