import css from 'components/DybrComponents/ScrollArea.module.css';
import React from 'react';
import { classNames } from 'utils/helpers/classNames';

function ScrollArea({ children, className }) {
  return (
    <div
      className={classNames({
        [css.scrollArea]: true,
        [className]: Boolean(className)
      })}
    >
      {children}
    </div>
  );
}

export default ScrollArea;
