import React from 'react';
import { HOSTNAME } from 'configs/dybr';

export const helpSections = [
  {
    title: 'Сайт, профиль, настройки',
    items: [
      {
        title: 'Куда я попал?',
        content: (
          <p>
            Дыбр – некоммерческий проект группы энтузиастов, искренне верящих в
            востребованность блог-платформы для тех, кто хотел бы вести
            электронный дневник в несколько ином, нежели соцсети или сервисы для
            размещения визуального контента, формате. Мы не стремимся
            конкурировать с другими ресурсами или заменить их, но будем рады,
            если вы создадите здесь собственное комфортное пространство для
            обмена информацией, реализации творческих задумок и новых знакомств.
          </p>
        )
      },
      {
        title: 'Каковы правила пользования сайтом?',
        content: (
          <p>
            Наши правила можно прочитать здесь:{' '}
            <a href={HOSTNAME + '/terms'}>{`${HOSTNAME}/terms`}</a>.
            Регистрируясь на сайте, вы соглашаетесь их соблюдать и понимаете,
            что в случае нарушения будете нести ответственность за свои
            действия. У нас нет жестких ограничений и запретов, основные
            требования – вести себя уважительно по отношению к другим
            пользователям, не спамить и не нарушать закон.
          </p>
        )
      },
      {
        title: 'В безопасности ли мои персональные данные после регистрации?',
        content: (
          <p>
            Наша политика конфиденциальности не предполагает передачу ваших
            персональных данных третьим лицам. При необходимости вы можете
            попросить администраторов удалить всю информацию о своем аккаунте и
            все ваши записи.
          </p>
        )
      },

      {
        title: 'Как настроить мой профиль?',
        content: (
          <p>
            В настройки можно попасть, кликнув на ссылку «к настройкам» на
            главной странице или кликнув на иконку своего профиля в шапке сайта.
            Пока раздел настроек содержит следующее:
            <ul>
              <li>
                <strong>Вкладка «Общие».</strong> Здесь можно включить/выключить
                получение уведомлений на e-mail о новых записях в Избранном или
                комментариях в дискуссиях, где вы принимаете участие, а также
                настроить кнопку быстрого доступа для перехода на любую нужную
                лично вам страницу сайта.
              </li>
              <li>
                <strong>Вкладка «Профиль».</strong> Здесь расположена краткая
                (пока) информация о вас – никнейм, подпись, которая будет
                отображаться под ним в комментариях и ваших записях, и аватар.
                На данный момент можно загрузить один аватар весом до 300 кб.
              </li>
              <li>
                <strong>Вкладка «Приватность».</strong> Здесь вы выбираете,
                будут ли записи из дневника показываться в общей ленте и кому
                виден ваш дневник в целом.
              </li>
              <li>
                <strong>Вкладка «Блог».</strong> Здесь можно поменять название
                своего дневника и настроить его дизайн, а также загрузить архив
                своего дневника на Дыбр. Подробнее про эту функцию –{' '}
                <a href={HOSTNAME + '/blog/dybr/59550'}>в блоге Дыбра</a>.
              </li>
            </ul>
          </p>
        )
      },
      {
        title: 'Как настроить дизайн?',
        content: (
          <p>
            На вкладке «Блог» в настройках вы можете создавать и изменять
            дизайны. Для создания дизайна дайте ему название и нажмите кнопку
            «Создать». Доступны изменения цветов блоков, ширины текстового поля,
            расположения текста, добавление фонового изображения самого дневника
            и шапки, выбор размера и типа шрифта. В будущем будет возможность
            более гибкой пользовательской настройки с помощью CSS. Для удобства
            просмотра изменения в дизайне отображаются сразу, но применяются
            только тогда, когда будет нажата кнопка «сохранить и применить».
            Можно создать несколько дизайнов и переключаться между ними по
            желанию.
          </p>
        )
      }
    ]
  },
  {
    title: 'Общая лента, подписки, фильтрация',
    items: [
      {
        title: 'Как подписаться на чей-нибудь дневник?',
        content: (
          <p>
            В каждом дневнике есть кнопка добавления в избранное (звездочка с
            сердечком). Повторный клик на нее удаляет блог из вашего избранного.
            Также эта кнопка есть в профиле под названием дневника и доступна по
            клику на аватарку пользователя.
          </p>
        )
      },
      {
        title:
          'Что делать, если чьи-то записи в общей ленте меня раздражают/расстраивают?',
        content: (
          <p>
            Можно скрыть записи этого автора, нажав на перечеркнутый кружок. Это
            никак не повлияет на ваше избранное, если человек в нем
            присутствует, но в общей ленте его записи больше показываться не
            будут. Если вы захотите отменить это действие, то кликните на
            аватарку пользователя и выберите опцию «вернуть в общую ленту».
          </p>
        )
      },
      {
        title: 'Что такое «кнопка с кисточкой» в дневнике?',
        content: (
          <p>
            Кнопка с кисточкой рядом с кнопкой добавления в избранное отключает
            пользовательский дизайн и дает возможность просматривать дневник в
            цветах по умолчанию. Используйте ее, если у автора неудобные для вас
            цветовые настройки или размер шрифта.
          </p>
        )
      },
      {
        title: 'Как подписаться на дискуссию?',
        content: (
          <p>
            Если вы оставляете комментарий к какой-то записи, то подписка
            происходит автоматически, и в дальнейшем вы будете получать
            уведомления о новых комментариях в этой дискуссии. Также можно
            нажать на иконку-глаз в правом углу внизу записи, чтобы подписаться
            на обновления и без комментирования. Чтобы отписаться, нажмите
            иконку повторно.
          </p>
        )
      },
      {
        title:
          'Как посмотреть список моих (или чужих) избранных и подписчиков?',
        content: (
          <p>
            Зайдите в свой профиль или профиль интересующего пользователя. В нем
            отображаются подписчики и избранные, а также двухцветным
            подчеркиванием отмечены взаимные подписки, зеленым – ваши читатели,
            а оранжевым – авторы из вашего избранного.
          </p>
        )
      },
      {
        title: 'Как найти пользователя по нику?',
        content: (
          <p>
            Пока поиск в разработке. Как только эта функция появится, мы
            обязательно о ней сообщим.
          </p>
        )
      },
      {
        title: 'Как заблокировать неприятного мне человека?',
        content: (
          <p>
            Кликнув на аватарку, выберите опцию «заблокировать пользователя».
            После этого ему перестанут быть видны любые ваши записи и не будут
            приходить уведомления о новых комментариях. Для отмены этого
            действия снова кликните на аватарку и выберите опцию «разблокировать
            пользователя».
          </p>
        )
      },
      {
        title:
          'Можно ли как-то сохранить интересный пост, чтобы иметь к нему доступ впоследствии?',
        content: (
          <p>
            Да, можно сохранить запись в закладки, нажав на иконку «добавить в
            закладки» в углу. Посмотреть свои закладки можно отдельной вкладкой
            на странице Избранного.
          </p>
        )
      }
    ]
  },
  {
    title: 'Записи и комментарии',
    items: [
      {
        title: 'Какой допустимый объем знаков для записи?',
        content: (
          <p>
            У нас нет ограничений на длину записей. Но, если вы выкладываете
            текст большого объема и запись участвует в общей ленте, пожалуйста,
            пользуйтесь тэгом MORE для удобства. То же самое касается крупных
            изображений.
          </p>
        )
      },
      {
        title: 'Как вставить картинку/видео/аудиоплеер?',
        content: (
          <>
            <p>
              Картинку можно вставить тремя способами.
              <ol>
                <li>Просто перетащить ее в окно новой записи.</li>
                <li>
                  Воспользоваться кнопкой «вставка изображений», с помощью
                  которой можно либо загрузить изображение с
                  компьютера/мобильного устройства, либо указать ссылку на него
                  на стороннем ресурсе (внимание: не все сайты разрешают
                  отображение загруженных на них картинок).
                </li>
                <li>
                  Вставить html-код в режиме html-редактора (самая последняя
                  кнопка в панели редактирования).
                </li>
              </ol>
              Ограничение на размер загружаемого на Дыбр изображения – 5
              мегабайт.
            </p>
            <p>
              Видео можно вставить либо по кнопке «вставка видео», указав его
              адрес, либо в режиме html-редактора, указав его код (обычно
              видеохостинги предоставляют код при нажатии на кнопку
              «поделиться»).
            </p>
            <p>
              Аудиоплеер можно вставить в режиме html-редактора, указав его код,
              предоставленный аудиохостингом..
            </p>
          </>
        )
      },
      {
        title:
          'Как изменить размер вставленного изображения? Почему картинка автоматически уменьшается?',
        content: (
          <p>
            При загрузке изображение уменьшается для отображения до 300px по
            ширине, загружаясь при этом в полном размере. Если вы хотите
            изменить размер по умолчанию, кликните на картинку и нажмите кнопку
            «изменить размер» в выпадающем под ней меню (внимание –
            поддерживается только изменение в поле «ширина», высота при этом
            меняется пропорционально, как в исходном изображении). Также можно
            изменить параметр «width» в режиме html-редактора.
          </p>
        )
      },
      {
        title:
          'Можно ли вставить видео/аудио непосредственно на сайт, минуя сторонние хостинги?',
        content: (
          <p>
            К сожалению, это невозможно, доступна только вставка изображений.
          </p>
        )
      },
      {
        title: 'Есть ли ограничения по типу контента?',
        content: (
          <p>
            Ограничения оговорены Правилами, в остальном владелец блога вправе
            сам решать, о чем ему писать, его дневник – его территория. Однако
            старайтесь принимать во внимание, что мы стремимся создать
            дружелюбное и комфортное пространство и, если ваш дневник участвует
            в общей ленте, пользуйтесь тэгом MORE для потенциально триггерных
            тем. Особенно это касается «материалов для взрослых».
          </p>
        )
      },
      {
        title: 'Как цитировать текст, на который хочется написать комментарий?',
        content: (
          <p>
            Можно нажать кнопку «оформление цитаты» в визуальном редакторе и
            вставить нужный текст после появления символа цитирования в поле
            записи. Можно – и гораздо проще – выделить нужный текст и нажать на
            кнопку «ответить». Выделенный текст вставится цитатой вместе с
            никнеймом пользователя, которому вы отвечаете.
          </p>
        )
      },
      {
        title:
          'Если у меня открытый для всех дневник, могу ли я ограничить доступ к отдельной записи?',
        content: (
          <p>
            Да, конечно. Перед публикацией выберите уровень доступа на странице
            новой записи. Вы можете установить ограничение для
            зарегистрированных, для избранных или сделать запись полностью
            закрытой для всех, кроме себя.
          </p>
        )
      },
      {
        title: 'Как спрятать часть длинной записи/картинки?',
        content: (
          <p>
            Выберите кнопку «скрыть часть текста» на панели визуального
            редактора или пропишите тэг [MORE][/MORE] вручную, заключив в него
            нужную часть записи.
          </p>
        )
      },
      {
        title:
          'Как развернуть при просмотре видео, размещенное в посте, в полноэкранный режим, не переходя при этом на сайт видеохостинга?',
        content: (
          <p>
            К сожалению, редактор в нынешнем его виде не позволяет этого сделать
            по умолчанию (в перспективе мы постараемся это исправить). Однако вы
            можете вставить в атрибут «allowfullscreen» значение "yes" между
            кавычек в режиме html-редактора в коде вставленного видеоплеера,
            тогда разворачивание будет возможным.
          </p>
        )
      },
      {
        title: 'Как сохранить черновик записи?',
        content: (
          <p>
            На настоящий момент черновик сохраняется в браузере – даже если вы
            случайно закроете страницу новой записи, то при восстановлении
            текст, скорее всего, никуда не пропадет. В будущем планируется
            функция полноценного сохранения черновиков на сайте.
          </p>
        )
      },
      {
        title: 'Могу ли я поделиться записью на других ресурсах?',
        content: (
          <p>
            Функция «поделиться» пока реализована только в мобильном приложении.
          </p>
        )
      }
    ]
  },
  {
    title: 'Технические вопросы',
    items: [
      {
        title:
          'Не открывается общая лента при клике на иконку в шапке сайта, что делать?',
        content: (
          <p>
            По умолчанию «быстрая кнопка» в шапке настроена на общую ленту, но
            вы можете поменять эту настройку на то, что вам действительно нужно.
            Скорее всего, вы случайно выставили ссылку, которая не существует.
            Исправить можно в настройках во вкладке «общие»: пропишите «feed» в
            поле адреса быстрой кнопки.
          </p>
        )
      },
      {
        title:
          'Почему у меня не отображаются смайлы при нажатии на соответствующую кнопку?',
        content: (
          <p>
            Мы используем стандартные символы юникод для отображения смайлов.
            Если вы их не видите, скорее всего, их нет в шрифтах установленных в
            вашей системе.
          </p>
        )
      },
      {
        title:
          'Что делать, если сайт работает некорректно в части отображения или публикации записей, комментариев, изменения настроек и т.п.?',
        content: (
          <p>
            Отправьте, пожалуйста, отчет об ошибке со страницы{' '}
            <a
              href={`${HOSTNAME}/errorlog`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${HOSTNAME}/errorlog`}
            </a>
            . Если проблема продолжает воспроизводиться, вам может помочь
            очистка данных сайта из браузера. Сделать это можно по той же
            ссылке, нажав на серую кнопку «очистить и закрыть».
          </p>
        )
      },
      {
        title: 'Куда еще можно сообщить об ошибке?',
        content: (
          <p>
            Самое лучшее – сообщить об ошибке в нашем{' '}
            <a
              href={'https://gitlab.com/groups/dybr-ru/-/issues'}
              target="_blank"
              rel="noopener noreferrer"
            >
              баг-трекере
            </a>
            , но для этого необходима регистрация в Гитлабе. Также можно
            оставить комментарий в блоге{' '}
            <a
              href={`${HOSTNAME}/blog/dybr`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Дыброновости
            </a>
            , там мы отвечаем наиболее оперативно. Или можно написать на нашу{' '}
            <a href="mailto:support@dybr.ru">электронную почту</a> (ответ в
            течение недели, если он необходим).
          </p>
        )
      },
      {
        title:
          'Как узнать, что планируется ввести на сайте в ближайшем будущем?',
        content: (
          <p>
            О том, над чем мы работаем, и о наших ближайших планах развития вы
            можете узнать на нашей{' '}
            <a
              href={'https://gitlab.com/groups/dybr-ru/-/boards'}
              target="_blank"
              rel="noopener noreferrer"
            >
              доске с приоритетами
            </a>
            . Также вы всегда можете спросить нас об интересующих функциях в
            комментариях к блогу{' '}
            <a
              href={`${HOSTNAME}/blog/dybr`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Дыброновости
            </a>
            . У нас много планов, и мы надеемся рано или поздно воплотить их в
            жизнь.
          </p>
        )
      },
      {
        title: 'Есть ли мобильное приложение для сайта?',
        content: (
          <p>
            Да, вы можете{' '}
            <a href="https://play.google.com/store/apps/details?id=com.kanedias.dybr.fair">
              скачать
            </a>{' '}
            его в Google Play. Приложение бесплатно и регулярно обновляется. На
            настоящий момент существует только приложение под Android. Если вы
            разработчик на iOS и хотите создать клиент для Дыбра, мы будем очень
            рады видеть вас в нашей команде!
          </p>
        )
      },
      {
        title: 'Могу ли я как-то поддержать проект и разработчиков?',
        content: (
          <p>
            Если вы хотите поддержать нас материально – можно сделать это через{' '}
            <a href="https://www.patreon.com/bePatron?u=8122465">Патреон</a>.
            Работает это как ежемесячная подписка на любую сумму от 1$, которую
            можно остановить в любой момент. Если есть желание помочь «руками»,
            например, поучаствовать в разработке, присоединиться к тестированию,
            написать мобильное приложение под iOS или сделать еще что-то
            полезное, – напишите нам на почту{' '}
            <a href="mailto:support@dybr.ru">support@dybr.ru</a>. :) Также вы
            можете рассказать о нашем проекте друзьям и знакомым – возможно, они
            тоже хотят добавить в свою жизнь немного дыброты!
          </p>
        )
      },
      {
        title: 'Что делать, если здесь нет ответа на мой вопрос?',
        content: (
          <p>
            Задайте свой вопрос в комментариях{' '}
            <a href={HOSTNAME + '/blog/dybr/19547'}>
              к этой записи в Дыброновостях
            </a>
            , мы ответим, а потом при необходимости обновим информацию на этой
            странице.
          </p>
        )
      }
    ]
  }
];
