import React from 'react';
import { EntryWithCommentsWrapper } from '../styled';
import NotificationDetailsForConversation from './NotificationDetailsForConversation';

function GroupedNotificationsForConversation({
  profiles,
  notifications,
  messagesGroupedByProfileId
}) {
  if (!profiles.length || !notifications.length) {
    return (
      <EntryWithCommentsWrapper>
        <div style={{ textAlign: 'center', width: '100%' }}>
          нет непрочитанных
        </div>
      </EntryWithCommentsWrapper>
    );
  }
  return profiles.map(profile => {
    const messages = messagesGroupedByProfileId[profile.id];
    return (
      <NotificationDetailsForConversation
        key={profile.id}
        messages={messages}
        profile={profile}
      />
    );
  });
}

export default GroupedNotificationsForConversation;
