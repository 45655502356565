import { getRelativeUrl } from 'utils/helpers/hostNames';

export default ({ colors, header, fonts, layout }) => `
  .blog-header--thin {
    height: 200px;
    padding: 30px 20px;
    text-align: center;
    background-color: ${header.background};
  }

  .blog-header--thin .blog-header-avatar {
    margin: auto;

  }

  .blog-header--thin .blog-header-avatar img {
    cursor: pointer;
    transition: all 0.2s;
    max-width: 120px;
    max-height: 120px;
    border: 2px solid ${colors.blocks};
  }

  .blog-header-avatar:hover img {
      border-color: ${colors.action};
  }

  .blog-header-tabs {
    background: ${colors.elementsBack};
    text-align: center;
  }

  .blog-header-tabs a {
    display: inline-block;
    padding: 15px 25px;
    text-align: center;
    color: ${colors.elements};
    font-family: ${fonts.elements.family};
    font-size: ${fonts.elements.size}px;
    font-weight: ${fonts.elements.weight};
    font-style: ${fonts.elements.style};
    text-decoration: ${fonts.elements.textDecoration};

  }

  a.blog-header-tabs--active {
    border-bottom: 4px solid ${colors.accent};
    font-weight: bold;
    color: ${colors.accent};
  }

  .blog-header--main,
  .blog-header--thin {
    background-color: ${header.background};

    ${
      // prettier-ignore
      header.image && header.image.enable
        ? `background-image: url("${getRelativeUrl(header.image.url)}");
           background-repeat: ${header.image.repeat};
           background-position: ${header.image.position};
           background-size: ${header.image.size};`
        : ''
    }
    color: ${header.color};
  }

  .blog-header--main {
    display: flex;
    flex-direction: row;
    height: 265px;
    align-items: stretch;
    justify-content: center;
  }

  .blog-header--main, .blog-header--thin {
    flex: 0 1 auto;
    font-size: 16px;
    font-weight: bold;
    flex: 0 1 auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
  }

  .blog-header--main h1 {
    font-family: ${header.font.family};
    font-size: ${header.font.size}px;
    font-style: ${header.font.style};
    font-weight: ${header.font.weight};
    line-height: normal;
    text-decoration: ${header.font.textDecoration};
    text-decoration-color: ${header.color};
    flex: 0 1 auto;
  }

  .blog-header--main h4 {
    font-family: ${fonts.entries.family};
    font-size: ${fonts.entries.size}px;
    font-style: ${fonts.entries.style};
    font-weight: bold;
    line-height: normal;
    text-decoration: ${fonts.entries.textDecoration};
    text-decoration-color: ${header.color};
    flex: 0 1 auto;
  }

  @media (max-width: 700px) {
    .blog-header--main h1 {
      font-size: ${Math.min(70, Math.ceil(header.font.size * 0.8)) + 'px'};
    }
  }

  @media (max-width: 600px) {
    .blog-header--main h1 {
      font-size:  ${Math.min(55, Math.ceil(header.font.size * 0.6)) + 'px'};
    }
  }

  @media (max-width: 450px) {
    .blog-header--main h1 {
      font-size: ${Math.min(40, Math.ceil(header.font.size * 0.5)) + 'px'};
    }
  }

  .blog-header--main h1 a {
    color: ${header.color};
  }

  .blog-header-profile a {
    color: ${header.color};
    position: relative;
    bottom: -10px;
  }

  .blog-header--main .blog-header-profile a {
    bottom: -25px;
  }

`;
