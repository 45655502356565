/**
 *
 * buttons for adding to favs and turning of design
 */

import React from 'react';
import styled from 'styled-components';
import { changeRgbaAlpha } from 'utils/helpers/colors';
import { AddToFavoritesContainer } from 'components/Shared/AddToFavorites';
import { AddToFavoritesStyled } from 'components/Shared/AddToFavorites.style';
import { BrushForUserStylesIcon } from 'styles/Icons/BrushForUserStyles';
import CommunitySideButtons
  from 'components/BlogComponents/SidePanel/CommunitySideButtons';

const StyledSidePanel = styled.div`
  position: absolute;

  ${(props) => {
    switch (props.theme.layout.align) {
      case 'left':
        return 'right: 0; border-radius: 5px 0 0 5px;';
      case 'right':
        return 'left:0; border-radius: 0 5px 5px 0;';
      case 'center':
      default:
        return ' right: 0; border-radius: 5px 0 0 5px;';
    }
  }}


  top: ${(props) => (props.higher ? '86px' : '117px')};

  padding: 15px;
  background-color: ${(p) => changeRgbaAlpha(p.colors.background, 0.7)};
  z-index: 500;

  @media (max-width: 700px) {
    padding: 10px;
    top: 150px;
    top: ${(props) => (props.heigher ? '150px' : '177px')};
  }

  @media (max-width: 480px) {
    padding: 8px;
    top: 200px;
    top: ${(props) => (props.heigher ? '200px' : '227px')};
  }

  ${AddToFavoritesStyled} {
    --main-color: ${(p) => p.theme.colors.text};
    --fill-color: ${(p) => p.theme.colors.accent};
    border:none;

    width: 40px;
    height: 41px;
    margin-bottom 15px;

    @media (max-width: 480px) {
      width: 33px;
      height: 34px;
      margin-bottom 8px;
    }

    @media (max-width: 700px) {
      margin-bottom 10px;
    }
  }
`;

const BlogStyleButton = styled.div`
  .icon {
    display: block;
  }

  margin-bottom: 0;

  .icon-brush-for-user-styles {
    fill: ${(p) => p.colors.text};
    width: 41px;
    height: 40px;

    @media (max-width: 480px) {
      width: 34px;
      height: 33px;
    }

    cursor: pointer;

    .filling-area {
      transition: all 0.4s;

      opacity: ${(p) => (p.active ? '1' : '0')};
      fill: ${(p) => p.colors.fillColor};
    }

    &:hover .brush-handle-outline,
    &:hover .brush-hole {
      fill: ${(props) => props.colors.fillColor};
    }
  }
`;

function BlogActionsSidePanel({
  profile,
  isDefaultStyleOn,
  toggleDefaultStyle,
  loading,
  blogTheme,
  higher
}) {
  const colors = {
    text: blogTheme.colors.text,
    background: blogTheme.colors.blocks,
    fillColor: blogTheme.colors.accent
  };

  return (
    <StyledSidePanel higher={higher} colors={colors}>
      <CommunitySideButtons community={profile} colors={colors} />
      <AddToFavoritesContainer profile={profile} sidebarMode={true} />
      {/* <StyledIconButton
          colors={colors}
          fillColor={blogTheme.colors.accent}
          active={isFavorite}
          onClick={loading ? () => {} : toggleFavorite}
          title={isFavorite ? 'удалить из избранного' : 'добавить в избранное'}
        >
          <AddToFavsIcon />
        </StyledIconButton> */}

      <BlogStyleButton
        colors={colors}
        active={isDefaultStyleOn}
        onClick={loading ? () => {} : toggleDefaultStyle}
        title={
          isDefaultStyleOn
            ? 'включить пользовательские стили'
            : 'отключить пользовательские стили'
        }
      >
        <BrushForUserStylesIcon />
      </BlogStyleButton>
    </StyledSidePanel>
  );
}

export default BlogActionsSidePanel;
