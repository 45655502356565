import React, { useState } from 'react';
import { getAvatar } from 'utils/helpers/profiles';
import {
  getConversationMessageUrl,
  getConversationUrl
} from 'utils/helpers/getUrl';
import {
  EntryWithCommentsWrapper,
  StyledComment,
  StyledCommentCounter,
  StyledEntry
} from '../styled';
import { truncateEntry } from 'utils/helpers/strings';

function NotificationDetailsForConversation({ profile, messages }) {
  const [open, setOpen] = useState(false);
  const conversationId = profile.id;

  const earliestMessageUrl = getConversationMessageUrl(
    messages && messages.length && messages[0].id,
    conversationId
  );

  const conversationAvatar = getAvatar(profile);

  return (
    <EntryWithCommentsWrapper
      className={open ? 'comments--open' : 'comments--closed'}
    >
      <StyledEntry key={conversationId}>
        <span
          className="plus"
          onClick={() => setOpen(!open)}
          title="раскрыть сообщения"
        >
          {open ? '–' : '+'}
        </span>
        <a href={earliestMessageUrl} title="к сообщениям">
          <div className="conversation-profile-avatar">
            <img width="30px" src={conversationAvatar} alt="" />
          </div>
          <div className="conversation-profile-name">{profile.nickname}</div>
          <StyledCommentCounter className="comment-amount">
            {messages?.length ?? 0}
          </StyledCommentCounter>
        </a>
      </StyledEntry>
      {open && messages && messages.length && (
        <div className={`comments`}>
          {messages.map(message => {
            if (!profile || !message) {
              return null;
            }

            const messageUrl = message.id
              ? getConversationMessageUrl(message.id, conversationId)
              : getConversationUrl(conversationId);

            return (
              <StyledComment key={message.id}>
                <a href={messageUrl} title="к комментарию">
                  <div>
                    <div className="comment-text">
                      {truncateEntry({ content: message.content }, 50)}
                    </div>
                  </div>
                </a>
              </StyledComment>
            );
          })}
        </div>
      )}
    </EntryWithCommentsWrapper>
  );
}

export default NotificationDetailsForConversation;
