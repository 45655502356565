import { query } from './Query';

export const getMessagesInChatApi = async ({
  ownProfileId,
  targetProfileId,
  pageNumber,
  pageSize,
  token
}) => {
  try {
    const queryParams = {
      'filters[other_profile_id]': targetProfileId,
      'page[number]': pageNumber,
      'page[size]': pageSize,
      sort: 'created-at',
      include: 'profile'
    };
    const res = await query({
      method: 'GET',
      resource: `profiles/${ownProfileId}/messages`,
      type: 'messages',
      queryParams,
      token
    });
    if (res.error) return res;

    return { success: true, data: res.data, total: res.meta?.totalMessages };
  } catch (err) {
    console.log('getMessagesApi', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const createMessageApi = async ({
  content,
  ownProfileId,
  targetProfileId,
  token
}) => {
  try {
    const res = await query({
      method: 'POST',
      resource: `profiles/${ownProfileId}/messages`,
      type: 'messages',
      queryParams: {
        content,
        receiver: { type: 'profiles', id: targetProfileId },
        relationshipNames: ['receiver']
      },
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('createMessageApi', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const markMessageAsReadApi = async ({ profileId, messageId, token }) => {
  try {
    const res = await query({
      method: 'PATCH',
      resource: `profiles/${profileId}/messages/${messageId}`,
      type: 'messages',
      queryParams: {
        status: 'read'
      },
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('markMessageAsReadApi', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const getConversationsApi = async ({ profileId, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `profiles/${profileId}/dialogs`,
      type: 'messages',
      queryParams: {
        sort: '-last_message_created'
      },
      token
    });
    return {
      status: 'ok',
      data: res.data,
      total: res.meta.totalDialogs,
      totalUnread: res.meta.unreadDialogs
    };
  } catch (err) {
    console.log('getDialogsApi', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};
