import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import {
  AsyncPaginate,
  reduceGroupedOptions
} from 'react-select-async-paginate';

import styled from 'styled-components';

import theme from 'styles/DybrTheme';

// wrapper for https://github.com/JedWatson/react-select

/** props:
 *
 * // accept options as an array of value+label objects
 *
 blogSelect: false - if set to true, styled component will not be used, styles should be defined in css
 options: [{ value: 'chocolate', label: 'Chocolate', otherKey: '' }]
 selectedOptionID: 0,
 onChange: (selectedOptionID) => {}
 className - for the container
 classNamePrefix - prefix for inner elements
 autoFocus - focus the control when it mounts
 isDisabled - disable the control
 isMulti - allow the user to select multiple values
 isSearchable - allow the user to search for matching options
 isPaginated - works with isAsync, loads more options when scrolling down, uses react-select-async-paginate wrapper
 name - generate an HTML input with this name, containing the current value
 placeholder - change the text displayed when no option is selected
 *
 * USE CLASSNAMES inside styled components
 * or in BLOG CSS
 *


 //***********************************/
// NOTE: react-select expects the value to be the option OBJECT, not its value or id (WHYYYYY)
// We should get option ID (= array index) from the outside component.
// We return the same back.
class DybrSelect extends Component {
  // returns index of the option
  onChangeReturnIndex = option => {
    let index = this.props.options.findIndex(o => o === option);
    if (index === -1) index = 0;
    this.props.onChange(index);
  };

  render() {
    const {
      blogSelect,
      options,
      selectedOptionID, // index on the option array, heavy lifting is done in the specific component
      // that uses this select (see selects in designerPanel for an example)
      className,
      classNamePrefix,
      maxMenuHeight,
      value,
      creatable,
      controlledValue,
      isAsync,
      isPaginated,
      isGrouped,
      ...restProps
    } = this.props;

    const props = {
      //  eslint-disable-next-line  no-use-before-define
      styles: blogSelect ? {} : customStyles,
      maxMenuHeight: maxMenuHeight || 130,
      ...this.props,
      className: blogSelect
        ? className || 'blog-select'
        : className + ' dybr-select',
      classNamePrefix: blogSelect
        ? classNamePrefix || 'blog-select'
        : className + ' dybr-select',
      onChange: controlledValue
        ? this.props.onChange
        : this.onChangeReturnIndex,
      value: controlledValue
        ? value
        : typeof selectedOptionID !== 'undefined'
        ? options[selectedOptionID]
        : null
    };

    const select = isAsync ? (
      isPaginated ? (
        <AsyncPaginate
          {...props}
          {...restProps}
          reduceOptions={isGrouped ? reduceGroupedOptions : undefined}
        />
      ) : (
        <AsyncSelect {...props} {...restProps} />
      )
    ) : creatable ? (
      <CreatableSelect {...props} />
    ) : (
      <Select {...props} />
    );

    if (blogSelect) return select;

    // NOTE styled select assumes that classname prefixes are dybr-select
    return <StyledSelectWrapper>{select}</StyledSelectWrapper>;
  }
}

export default DybrSelect;

//***********************************/
// this is used only for dybr pages
const StyledSelectWrapper = styled.div`
  .dybr-select__dropdown-indicator {
    transition: 0.2s;
  }
  .dybr-select:hover .dybr-select__dropdown-indicator {
    color: ${p => p.theme.accent};
  }
`;

// this is used only for dybr pages
// the styles are defined in this fashion because the dropdown cannot be styled
// with styled component (it is mounted in the bottom of page body)
const customStyles = {
  container: (base, state) => ({
    ...base,
    borderTop: '1px solid ' + theme.backgroundDark,
    borderBottom: '1px solid ' + theme.backgroundDark,
    minHeight: '35px',
    borderRadius: '0px',
    borderColor: state.isFocused ? theme.accent : theme.backgroundDark,
    '&:hover': { borderColor: theme.accent },
    '&.sidebar-select': {
      borderTop: 'none'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '90%',
    margin: '0px'
  }),
  control: base => ({
    ...base,
    border: 0,
    background: 'none',
    minHeight: '33px',
    boxShadow: 'none',
    borderRadius: '0px'
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px'
  }),
  input: base => ({
    ...base,
    margin: '0px',
    padding: '0px'
  }),
  menu: (base, state) => ({
    ...base,
    borderRadius: '0px'
  }),
  menuList: base => ({
    ...base,
    scrollbarWidth: 'thin',
    scrollbarColor: theme.backgroundDark + ' ' + theme.backgroundScroll,
    '::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: theme.backgroundScroll
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.backgroundDark
    }
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    fontWeight: state.isFocused ? 'bold' : 'normal',
    color: theme.text,
    transition: '0.2s',
    backgroundColor:
      state.isFocused || state.isSelected
        ? theme.backgroundLight
        : theme.backgroundLighter,
    borderLeft:
      '8px solid ' +
      (state.isFocused || state.isSelected
        ? theme.accent
        : theme.backgroundLighter),
    '&:active': { backgroundColor: theme.backgroundLight }
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? theme.accent : theme.backgroundDark
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  })
};
