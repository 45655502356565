import styled from 'styled-components';

export const IconButton = styled.a`
  position: relative;
  cursor: pointer;
  display: flex;
  border: none;
  background-color: transparent;
  justify-content: center;
  align-items: center;

  .icon {
    .main-outline,
    .filling-outline,
    .filling-area {
      fill: ${props =>
        props.light ? props.theme.textLight : props.theme.text};
      transition: opacity 0.3s;
    }
    .filling-area,
    .secondary-filling-area {
      opacity: 0;
    }
  }

  &:hover .icon,
  .icon.icon-active {
    .filling-area {
      opacity: 1;
    }
  }
`;

export const ArrowIconWithBackground = styled(IconButton)`
  .icon {
    .main-outline.arrow {
      fill: #dcdcdc;
    }
  }

  &:hover .icon {
    .main-outline.circle {
      fill: #676767;
    }
  }
`;
