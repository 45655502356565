import React from 'react';
import styled from 'styled-components';

const Animated = styled.div`
  opacity: 1;
  animation: loading 10s linear infinite;
  width: 100%;
  height: 100%;
  min-height: 50px;
  text-align: center;
  font-size: ${p => p.size || '40px'};

  @keyframes loading {
    0% {
      color: transparent;
    }
    10% {
      color: transparent;
    }
    15% {
      color: ${p => p.theme.backgroundDark};
    }
    20% {
      color: transparent;
    }
    25% {
      color: ${p => p.theme.backgroundDark};
    }
    30% {
      color: transparent;
    }
    35% {
      color: ${p => p.theme.backgroundDark};
    }
    40% {
      color: transparent;
    }
    45% {
      color: ${p => p.theme.backgroundDark};
    }
    50% {
      color: transparent;
    }
    55% {
      color: ${p => p.theme.backgroundDark};
    }
    60% {
      color: transparent;
    }
    65% {
      color: ${p => p.theme.backgroundDark};
    }
    70% {
      color: transparent;
    }
    75% {
      color: ${p => p.theme.backgroundDark};
    }
    80% {
      color: transparent;
    }
    85% {
      color: ${p => p.theme.backgroundDark};
    }
    90% {
      color: transparent;
    }
    95% {
      color: ${p => p.theme.backgroundDark};
    }
    100% {
      color: transparent;
    }
  }
`;

const Loading = () => {
  return <Animated>...</Animated>;
};

export default Loading;
