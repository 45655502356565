import React, { useEffect } from 'react';
import { HeaderGeneral } from '../../../components/DybrComponents/HeaderGeneral';
import { ServiceBackground } from '../../../components/DybrComponents/ServiceBackground';
import WithActiveProfileId from '../../../store/localStorage/WithActiveProfileId';
import { connect } from 'react-redux';

function AllConversationsScene(props) {
  useEffect(() => {
    document.title = `Личные сообщения - Дыбр`;
  }, []);

  return (
    <ServiceBackground data-testid="feed-scenes">
      <HeaderGeneral paddingBottom="45px">
        <h1>разговоры</h1>
      </HeaderGeneral>
      {props.children}
    </ServiceBackground>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = {};

export const AllConversationsContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(AllConversationsScene)
);
