import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getEntryApi, removeEntryApi } from 'api/entries';
import queryString from 'query-string';
import Entry, { canManipulate } from 'components/Shared/Entry/Entry';
import Loading from 'components/Shared/Loading';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { useBookmark } from 'scenes/hooks/useBookmark';
import { BlogEntryPageCommentsContainer } from 'scenes/Blog/BlogEntryPage/BlogEntryPageComments';
import { useSubscribe } from 'scenes/hooks/useSubscribe';
import {
  EntryPageProvider,
  useEntryPageContext
} from 'scenes/Blog/BlogEntryPage/EntryPageContext';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { readEntryNotifications } from '../../../store/notifications/operations/readEntryNotifications';

function EntryWrapper(props) {
  const { insertReply } = useEntryPageContext();

  return <Entry {...props} handleReply={insertReply} />;
}

function BlogEntryPage({
  eid,
  blog,
  location,
  ownProfile,
  readMentionsInEntry
}) {
  const parsed = queryString.parse(location.search);
  const scrollToComments = parsed.scroll;
  const page = Number(parsed.page || parsed.p) || 1;
  const toComment = (location.hash || '').replace('#', '');

  const [entry, setEntry] = useState({});
  const [loadingEntry, setLoadingEntry] = useState(true);

  const [error, setError] = useState('');
  const [token] = useTokenState();
  const loggedIn = Boolean(token);

  const { toggleBookmark } = useBookmark({ setEntry, token });
  const { toggleSubscribe } = useSubscribe({ setEntry, token });

  useEffect(() => {
    document.title = `${blog.blogTitle ?? ' '}${
      entry.title ? ` - ${entry.title}` : ''
    } - Дыбр`;
  }, [blog.blogTitle, entry.title]);

  const loadEntry = useCallback(async () => {
    const res = await getEntryApi({
      entryId: eid,
      include: 'profile,community',
      token: token
    });

    if (res.error) {
      setError(res.error);
    }
    setEntry(res.data ?? {});
    setLoadingEntry(false);
  }, [eid, token]);

  useEffect(() => {
    setError('');
    setLoadingEntry(true);

    loadEntry();
    readMentionsInEntry({
      entryId: eid,
      token,
      activeProfileId: ownProfile.id
    });
  }, [loadEntry]);

  // todo deletion itself should be inside Entry component
  const deleteEntry = async () => {
    const res = await removeEntryApi({ entryId: eid, token });

    if (res.error) {
      window.alert(res.error);
      return;
    }
    window.history.back();
  };

  const { settings } = entry;

  const isCommentingAllowed = settings?.permissions?.comments
    ? // actually it is only [private, registered, favorites], but maybe 'allow' is a good idea
      // because if we calculate it on backend side, we won't need to get favorite list for each entry on the page.
      settings?.permissions?.comments?.[0]?.allow
    : true;

  if (error) {
    return (
      <div className="blog-info-block">По этому адресу ничего не нашлось.</div>
    );
  }

  return (
    <>
      {loadingEntry ? (
        <Loading />
      ) : (
        <>
          <EntryWrapper
            entry={entry}
            type="entry-page"
            canManipulate={canManipulate(loggedIn, ownProfile.id, entry)}
            canManageWatching={loggedIn && entry.profile.id !== ownProfile.id}
            hasUserFunctions={loggedIn}
            onDelete={deleteEntry}
            onToggleSubscribe={() => toggleSubscribe(entry)}
            onToggleBookmark={() => toggleBookmark(entry)}
          />
          <BlogEntryPageCommentsContainer
            eid={eid}
            blog={blog}
            page={page}
            scrollToComments={scrollToComments}
            toComment={toComment}
            commentIds={entry.meta?.commentIds ?? []}
            isCommentingAllowed={isCommentingAllowed}
            reloadEntry={loadEntry}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

function BlogEntryPageWrapper(props) {
  return (
    <EntryPageProvider>
      <BlogEntryPage {...props} />
    </EntryPageProvider>
  );
}

const mapDispatchToProps = {
  readMentionsInEntry: readEntryNotifications
};

export const BlogEntryPageContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(BlogEntryPageWrapper)
);
