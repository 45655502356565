export function parseToken(token) {
  if (!token) {
    return {
      userId: undefined,
      tokenExp: undefined,
      activeProfileId: undefined
    };
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  let parsed = JSON.parse(window.atob(base64));

  const { sub: userId, exp: tokenExp, aud: activeProfileId } = parsed;

  return { userId, tokenExp, activeProfileId };
}
