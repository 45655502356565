import { markAllNotificationsReadAPI } from 'api/notifications';
import { MarkAllNotificationsReadAction } from 'store/notifications/actions/MarkAllNotificationsReadAction';

export const markAllNotificationsRead = ({ token, activeProfileId }) => async (
  dispatch
) => {
  const res = await markAllNotificationsReadAPI({
    profileId: activeProfileId,
    token
  });
  if (!res.error) {
    dispatch(new MarkAllNotificationsReadAction());
  } else {
    // dispatch error
  }
};
