import React, { useEffect, useState } from 'react';
import {
  ProfileNotFoundContainer,
  ProfileNotFoundSubtitle,
  ProfileNotFoundTitle,
  Wrapper
} from './Styled';
import { useTokenState } from 'store/localStorage/useTokenState';
import { getProfileApi } from '../../../api/profiles';
import Loading from '../Loading';
import { AvatarMenuContainer } from './AvatarMenu';

export function AvatarMenuPreload({ profileId }) {
  const [token] = useTokenState();
  const [isLoading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [error, setError] = useState(false);

  const profileNotFoundTitle = 'Не удалось загрузить профиль';
  const linkToProfileMessage = 'Перейти на страницу';

  useEffect(() => {
    getProfileApi({ profileId, token })
      .then(res => {
        setLoading(false);
        if (res.data) {
          setProfile(res.data);
        } else if (res.error) {
          setError(true);
        }
      })
      .catch(error => {
        console.error(error);
        setError(true);
        setLoading(false);
      });
  }, []);

  return !isLoading && !error && profile ? (
    <AvatarMenuContainer profile={profile} />
  ) : (
    <Wrapper>
      {isLoading && <Loading />}
      {!isLoading && error && (
        <ProfileNotFoundContainer>
          <ProfileNotFoundTitle>{profileNotFoundTitle}</ProfileNotFoundTitle>
          <ProfileNotFoundSubtitle>
            <a href={`/profile/${profileId}`}>{linkToProfileMessage}</a>
          </ProfileNotFoundSubtitle>
        </ProfileNotFoundContainer>
      )}
    </Wrapper>
  );
}
