import { selectDraftDesign } from 'store/designer/selectors';

import { createDesignAPI, updateDesignAPI } from 'api/designs';

import { AddDesignAction, UpdateDesignAction } from 'store/designer/actions';

export const saveDesign = ({ token, activeProfileId }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const design = selectDraftDesign(state);

  if (design.id === '0') {
    return await saveNewDesign();
  } else {
    return await updateExistingDesign();
  }

  /***/
  async function saveNewDesign() {
    const res = await createDesignAPI({ design, activeProfileId, token });
    if (!res.error) {
      dispatch(new AddDesignAction(res.data));
      return res.data.id;
    } else {
      // dispatchError(dispatch, res);
      return false;
    }
  }

  async function updateExistingDesign() {
    const res = await updateDesignAPI({
      design,
      designId: design.id,
      activeProfileId,
      token
    });
    if (!res.error) {
      dispatch(new UpdateDesignAction(design));
      return res.data.id;
    } else {
      // dispatchError(dispatch, res);
      return false;
    }
  }
  // ??? I have no clue what else was planned
  // TODO
};
