import { query } from 'api/Query';

/*
action: hide
kind: profile
scope: feed
я не хочу видеть эти профили в ленте

action: hide
kind: entry
scope: feed
я не хочу видеть эти записи в ленте - не работает сейечас?

action: ban
kind: profile
scope: my-blog
добавить эти пофили в черный список

POST /lists  with: {
    "data": {
        "relationships": {
            "profiles": [
                {
                    "data": {
                        "type": "profiles",
                        "id": "9088"
                    }
                }
            ]
        },
        "attributes": {
            // параметры выше
            "action": "hide",
            "scope": "feed",
            "kind": "profile"
        },
        "type": "hide"
    }
}
GET /v2/lists
{
    "included": [
        {
            "attributes": {
                "blog-title": "xngegma",
                "settings": {
                    "notifications": {},
                    "permissions": {}
                },
                "updated-at": "2019-01-04T21:03:42+01:00",
                "created-at": "2019-01-04T21:03:42+01:00",
                "nickname": "3m4vlf5",
                "blog-slug": "y2lpjzr"
            },
            "meta": {
                "entries": {
                    "public": 0
                }
            },
            "type": "profiles",
            "id": "9088"
        }
    ],
    "data": [
        {
            "relationships": {
                "profiles": {
                    "data": [
                        {
                            "type": "profiles",
                            "id": "9088"
                        }
                    ]
                }
            },
            "attributes": {
                "action": "hide",
                "scope": "feed",
                "kind": "profile"
            },
            "type": "profile-list",
            "id": "0"
        }
    ]
}
*/

export const getListsApi = async ({ token }) => {
  try {
    return await query({
      method: 'GET',
      resource: 'lists',
      // TODO it looks like we don't need profile details, only ids
      queryParams: { include: 'profile' },
      token
    });
  } catch (err) {
    console.error('getListsApi', err);
    // alert(
    //   'Не получилось загрузить данные. Попробуйте выйти и залогиниться снова'
    // );
    return { error: 'что-то пошло не так', data: [], ...err.response };
  }
};

export const getCustomListsApi = async ({ token }) => {
  try {
    return await query({
      method: 'GET',
      resource: 'lists',
      action: 'custom',
      scope: 'blog',
      queryParams: { include: 'profile' },
      token
    });
  } catch (err) {
    console.error('getListsApi', err);
    return { error: 'что-то пошло не так', data: [], ...err.response };
  }
};

export const createCustomProfileListApi = async ({
  profileIds,
  listName,
  token
}) => {
  const queryParams = {
    action: 'custom',
    scope: 'blog',
    name: listName,
    kind: 'profile',
    profiles: profileIds.map((profile) => ({ id: profile, type: 'profiles' })),
    relationshipNames: ['profiles']
  };

  try {
    return await query({
      method: 'POST',
      resource: 'lists',
      queryParams,
      token
    });
  } catch (e) {
    console.error(e);
    return { error: 'что-то пошло не так' };
  }
};

export const removeCustomListApi = async ({ listId, token }) => {
  try {
    return await query({
      method: 'DELETE',
      resource: `lists/${listId}`,
      token
    });
  } catch (err) {
    console.error('removeCustomListApi', err);

    return { error: 'что-то пошло не так' };
  }
};

export const updateCustomListAPI = async ({ list, profileIds, token }) => {
  const queryParams = {
    action: 'custom',
    scope: 'blog',
    name: list.name,
    profiles: profileIds.map((profile) => ({ id: profile, type: 'profiles' })),
    relationshipNames: ['profiles']
  };
  try {
    const res = await query({
      method: 'PATCH',
      resource: `lists/${list.id}`,
      type: 'profile-list',
      queryParams,
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('updateCustomListAPI', err);
    return { error: 'что-то пошло не так' };
  }
};

export const hideProfileFromFeedApi = async ({ profileId, token }) => {
  const queryParams = {
    action: 'hide',
    scope: 'feed',
    kind: 'profile',
    profiles: [{ id: profileId, type: 'profiles' }],
    relationshipNames: ['profiles']
  };

  try {
    return await query({
      method: 'POST',
      resource: 'lists',
      queryParams,
      token
    });
  } catch (e) {
    console.error(e);
    return { error: 'что-то пошло не так' };
  }
};

export const banProfileApi = async ({ profileId, token }) => {
  const queryParams = {
    action: 'ban',
    kind: 'profile',
    scope: 'blog',
    profiles: [{ id: profileId, type: 'profiles' }],
    relationshipNames: ['profiles']
  };
  try {
    return await query({
      method: 'POST',
      resource: 'lists',
      queryParams,
      token
    });
  } catch (e) {
    console.error(e);
  }
};

export const removeProfileFromListApi = async ({
  profileId,
  listId,
  token
}) => {
  try {
    return await query({
      method: 'DELETE',
      resource: `lists/${listId}/relationships/profiles`,
      id: profileId,
      token
    });
  } catch (err) {
    console.error('removeProfileFromListApi', err);

    return { error: 'что-то пошло не так' };
  }
};
