import { query } from './Query';
import config from 'configs/dybr';
import moment from 'moment';

export const getNewsEntriesApi = async () => {
  const queryParams = {
    sort: '-published-at',
    'page[size]': 3,
    'page[number]': 1,
    'filters[state]': 'published'
  };
  // token is not needed here
  return await query({
    method: 'GET',
    resource: `blogs/${config.dybrNewsId}/entries`,
    queryParams
  });
};

export const getFilteredEntriesApi = async ({
  pageSize,
  pageNumber,
  pageStarter,
  token,
  tags,
  content,
  tagsGrouped,
  hideOwned,
  showHidden,
  showBanned,
  startDate,
  endDate
}) => {
  const queryParams = {
    sort: '-published-at',
    'page[size]': pageSize,
    'page[number]': pageNumber,
    'filters[state]': 'published',
    include: 'profile,community'
  };
  if (tags && tags.length > 0) {
    const validTags = tags.filter(tag => tag);
    queryParams['filters[tag]'] = validTags;
    if (validTags.length > 1 && !tagsGrouped) {
      queryParams['tags_union'] = 1;
    }
  }

  if (content) {
    queryParams['filters[content|contains]'] = content;
  }
  const excluded = [];
  if (hideOwned) {
    excluded.push('owned');
  }
  if (!showBanned) {
    excluded.push('banned');
  }
  if (!showHidden) {
    excluded.push('hidden');
  }

  if (excluded && excluded.length) {
    queryParams['filters[blog-id]'] = excluded
      .map(value => '!' + value)
      .join(',');
  }
  if (startDate) {
    queryParams['filters[from]'] = moment(startDate).format('YYYY-MM-DD');
  }
  if (endDate) {
    queryParams['filters[to]'] = moment(endDate).format('YYYY-MM-DD');
  }
  if (pageStarter) {
    queryParams['page[starter]'] = pageStarter;
  }

  return await query({
    method: 'GET',
    resource: 'entries',
    queryParams,
    token
  });
};

export const getFeedEntriesApi = async ({
  pageSize,
  pageNumber,
  pageStarter,
  token
}) => {
  const queryParams = {
    sort: '-published-at',
    'page[size]': pageSize,
    'page[number]': pageNumber,
    'filters[state]': 'published',
    include: 'profile,community',
    'filters[feed]': 1
  };

  if (pageStarter) {
    queryParams['page[starter]'] = pageStarter;
  }

  return await query({
    method: 'GET',
    resource: 'entries',
    queryParams,
    token
  });
};

export const getFavoriteEntriesApi = async ({
  profileId,
  pageSize,
  pageNumber,
  token
}) => {
  const queryParams = {
    'page[size]': pageSize,
    'page[number]': pageNumber,
    sort: '-published-at',
    'filters[state]': 'published',
    include: 'profile,community'
  };

  return await query({
    method: 'GET',
    resource: `favorites/${profileId}/entries`,
    queryParams,
    token
  });
};

export const getCommunitiesEntriesApi = async ({
  profileId,
  pageSize,
  pageNumber,
  token
}) => {
  const queryParams = {
    'page[size]': pageSize,
    'page[number]': pageNumber,
    sort: '-published-at',
    'filters[state]': 'published',
    include: 'profile,community'
  };

  return await query({
    method: 'GET',
    resource: `communities/${profileId}/entries`,
    queryParams,
    token
  });
};

export const getSelectedEntriesApi = async ({ ids, token }) => {
  const queryParams = {
    sort: '-published-at',
    include: 'profile,community'
  };

  if (ids) {
    queryParams['filters[id]'] = ids.join(',');
  }

  return await query({
    method: 'GET',
    resource: 'entries',
    queryParams,
    token
  });
};

export const getEntryApi = async ({ entryId, sort, include, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `entries/${entryId}`,
      queryParams: {
        sort: sort || '-published-at',
        include
      },
      token
    });
    return { success: true, data: res.data, meta: res.meta };
  } catch (e) {
    console.error(e);
    return { error: 'что-то пошло не так, не удалось получить запись' };
  }
};

export const getBlogFilteredEntriesApi = async ({
  blogId,
  tags,
  tagsGrouped = false,
  pageSize,
  pageNumber,
  sort = 'desc',
  token,
  content,
  startDate,
  endDate
}) => {
  const queryParams = {
    sort: sort === 'asc' ? 'published-at' : '-published-at',
    'page[size]': pageSize,
    'page[number]': pageNumber,
    'filters[state]': 'published',
    'filters[tag]': tags,
    'filters[blog-id]': [blogId],
    include: 'profile,community'
  };
  if (content) {
    queryParams['filters[content|contains]'] = content;
  }
  if (tags && tags.length > 1 && !tagsGrouped) {
    queryParams['tags_union'] = 1;
  }
  if (startDate) {
    queryParams['filters[from]'] = moment(startDate).format('YYYY-MM-DD');
  }
  if (endDate) {
    queryParams['filters[to]'] = moment(endDate).format('YYYY-MM-DD');
  }

  return await query({
    method: 'GET',
    resource: 'entries',
    queryParams,
    token
  });
};

export const getBlogEntriesApi = async ({
  blogId,
  tags,
  pageSize,
  pageNumber,
  sort = 'desc',
  token
}) => {
  const queryParams = {
    sort: sort === 'asc' ? 'published-at' : '-published-at',
    'page[size]': pageSize,
    'page[number]': pageNumber,
    'filters[state]': 'published',
    include: 'profile,community'
  };
  if (tags && tags.length) {
    queryParams['filters[tag]'] = tags;
  }

  const res = await query({
    method: 'GET',
    resource: `blogs/${blogId}/entries`,
    queryParams,
    token
  });
  if (res.error) return res;

  return { success: true, data: res.data, meta: res.meta };
};

export const createEntryApi = async ({
  title,
  content,
  targetBlog,
  state,
  tags,
  settings,
  token
}) => {
  try {
    if (typeof content === 'string' && content !== '') {
      const targetBlogType = targetBlog.isCommunity ? 'community' : 'profile';

      const queryParams = {
        content,
        title,
        state,
        tags,
        ...{
          [targetBlogType]: {
            id: targetBlog.id,
            type: 'profiles'
          }
        },
        settings,
        relationshipNames: [targetBlogType]
      };

      const res = await query({
        method: 'POST',
        resource: 'entries',
        queryParams,
        token
      });
      return { status: 'ok', data: res.data };
    } else {
      return { error: 'пустая запись!' };
    }
  } catch (err) {
    console.log('requestEntryCreate', err);
    return { error: 'что-то пошло не так' };
  }
};

export const updateEntryApi = async ({ data, entryId, token }) => {
  try {
    if (typeof data.content === 'string' && data.content !== '') {
      const res = await query({
        method: 'PATCH',
        resource: 'entries',
        queryParams: data,
        id: entryId,
        token
      });
      return { status: 'ok', data: res.data };
    } else {
      return { error: 'пустая запись!' };
    }
  } catch (err) {
    console.log('requestEntryUpdate', err);
    return { error: 'что-то пошло не так и запись не обновилась' };
  }
};

export const removeEntryApi = async ({ entryId, token }) => {
  try {
    await query({ method: 'DELETE', resource: 'entries', token, id: entryId });
    return { status: 'ok' };
  } catch (err) {
    console.log('requestEntryDelete', err);
    return { error: 'что-то пошло не так, но ничего не пропало' };
  }
};
