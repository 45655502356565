// colors and other things that can be overriden via the designer tool

import bg from 'styles/img/blog_header_bg_full.jpg';

const BlogDesign = {
  id: '0',
  name: 'новый дизайн',
  colors: {
    borders: 'rgba(198,198,198,1)',
    dividers: 'rgba(198,198,198,1)', // horizontal lines in entries and later in menues
    headers: 'rgba(51,51,51,1)',
    meta: 'rgba(146,146,146,1)',
    links: 'rgba(81,132,115,1)',
    text: 'rgba(51,51,51,1)',
    offtop: 'rgba(146,146,146,1)',
    blocks: 'rgba(250,250,250,1)', // blocks background
    background: 'rgba(222,222,222,1)', //page background
    elements: 'rgba(146,146,146,1)',
    elementsBack: 'rgba(238,238,238,1)',
    accent: 'rgba(222, 65, 58, 1)', // various additional stuff
    buttons: 'rgba(81,132,115,1)'
  },

  background: {
    enable: false,
    url: '', //bg,
    size: 'auto',
    position: 'top left',
    repeat: 'repeat',
    attachment: 'normal'
  },

  layout: {
    maxWidth: 1200,
    layout: 1, // 1 - one column, 2 - two column left menu,  3 - two column right menu
    align: 'center',
    blocks: true,
    epigraph: true
  },

  header: {
    background: 'rgba(222,222,222,1)',
    color: 'rgba(255,255,255,1)',
    font: {
      family: "'Montserrat Alternates', sans-serif",
      size: 36,
      style: 'normal',
      weight: 'normal',
      shadow: true
    },
    image: {
      enable: true,
      url: bg,
      size: 'cover',
      repeat: 'repeat',
      position: 'center center',
      attachment: 'normal'
    }
  },

  fonts: {
    headers: {
      family: 'Roboto, sans-serif',
      size: 24,
      weight: 'bold',
      style: 'normal'
    },
    entries: {
      family: 'Roboto, sans-serif',
      size: 15,
      weight: 'normal',
      style: 'normal',
      height: 1.45,
      align: 'justify'
    },
    elements: {
      family: 'Roboto, sans-serif',
      size: 16,
      weight: 'normal',
      style: 'normal'
    },
    menu: {
      family: 'Roboto, sans-serif',
      size: 16,
      weight: 'normal',
      style: 'normal'
    }
  }
};

export default BlogDesign;
