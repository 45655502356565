import React, { useRef } from 'react';
import { StyledSidebar, StyledSidebarContent } from './styled';
import { NotificationsSidebarContainer } from './NotificationSidebar';

import useClickAway from 'utils/hooks/useClickAway';
import { MenuSidebarContainer } from './MenuSidebar';
import { SearchSidebarContainer } from './search/SearchSidebar';

function Sidebar({ onClickAway, type }) {
  const wrapperRef = useRef();
  useClickAway(onClickAway, wrapperRef);

  if (!type || type === 'none') return null;

  return (
    <div ref={wrapperRef}>
      <StyledSidebar>
        <StyledSidebarContent
          className="scroll-wrapper"
          data-simplebar
          data-simplebar-auto-hide="true"
        >
          {/** simplebar does not like when you take away its child and crashes, a wrapping div works. This could be investigated later */}
          <div>
            {type === 'menu' && <MenuSidebarContainer />}
            {type === 'comments' && <NotificationsSidebarContainer />}
            {type === 'search' && <SearchSidebarContainer />}
          </div>
        </StyledSidebarContent>
      </StyledSidebar>
    </div>
  );
}

export default Sidebar;
