import React, { useState } from 'react';
import TagsSelect from 'components/FormElements/TagsSelect';
import TextField from 'components/FormElements/TextField';
import Checkbox from 'components/FormElements/Checkbox';
import { ActionButton } from 'components/Shared/Buttons';
import { navigate } from '@reach/router';
import DatePicker from 'components/FormElements/DatePicker';
import {
  AnimatedConditionalComponent,
  GroupedFields,
  GroupedFieldsRow,
  Label,
  SidebarErrorWrapper,
  SidebarFormWrapper
} from '../SidebarForm';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { formSearchParams } from '../../../../../utils/helpers/searchParamUtils';

function GlobalSearchForm() {
  const initSearchParams = useSearchParams();
  const [searchParams, setSearchParams] = useState(initSearchParams);
  const minQueryLength = 3;
  const [formErrors, setFormErrors] = useState({});

  function handleTermChange(event) {
    const value = event.target.value;
    setSearchParams({ ...searchParams, content: value });
    if (value && value.length < minQueryLength) {
      formErrors.content = 'Минимум ' + minQueryLength + ' символа';
    } else {
      formErrors.content = '';
    }
  }

  function validateSearchFields() {
    let isValid = true;
    const errors = {};
    if (searchParams.content && searchParams.content < minQueryLength) {
      errors.content = 'Минимум ' + minQueryLength + ' символа';
      isValid = false;
    } else {
      errors.content = '';
    }
    if (
      (!searchParams.tags || searchParams.tags.length === 0) &&
      !searchParams.content
    ) {
      errors.form = 'Укажите тег или ключевое слово';
      isValid = false;
    } else {
      errors.form = '';
    }

    setFormErrors(errors);
    if (isValid) {
      let url = ['/search', formSearchParams(searchParams)].join('?');
      navigate(url);
    }
  }

  return (
    <SidebarFormWrapper>
      <TextField
        type="text"
        value={searchParams.content}
        meta={{}}
        onChange={handleTermChange}
        error={formErrors.content}
        label="ключевые слова"
        touched
      />
      <GroupedFields gap={'small'}>
        <TagsSelect
          searchAllTags={true}
          onChange={tags => setSearchParams({ ...searchParams, tags })}
          value={searchParams.tags}
          className={'sidebar-select'}
        />
        {searchParams.tags && searchParams.tags.length > 1 && (
          <AnimatedConditionalComponent
            as={Checkbox}
            label={'все теги в одной записи'}
            checked={searchParams.tagsGrouped}
            onChange={() =>
              setSearchParams({
                ...searchParams,
                tagsGrouped: !searchParams.tagsGrouped
              })
            }
          />
        )}
      </GroupedFields>
      <GroupedFields>
        <Label>за период</Label>
        <GroupedFieldsRow>
          <DatePicker
            value={searchParams.startDate}
            onChange={date =>
              setSearchParams({ ...searchParams, startDate: date })
            }
            isClearable={true}
            maxDate={new Date()}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            label={'с начала'}
          />
          <Label>по</Label>
          <DatePicker
            value={searchParams.endDate}
            onChange={date =>
              setSearchParams({ ...searchParams, endDate: date })
            }
            isClearable={true}
            maxDate={new Date()}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            label={'сегодня'}
          />
        </GroupedFieldsRow>
      </GroupedFields>
      <GroupedFields>
        <Checkbox
          label={'показывать свои записи'}
          checked={!searchParams.hideOwned}
          onChange={() =>
            setSearchParams({
              ...searchParams,
              hideOwned: !searchParams.hideOwned
            })
          }
        />
        <Checkbox
          label={'показывать записи забаненных'}
          checked={searchParams.showBanned}
          onChange={() =>
            setSearchParams({
              ...searchParams,
              showBanned: !searchParams.showBanned
            })
          }
        />
        <Checkbox
          label={'показывать записи скрытых'}
          checked={searchParams.showHidden}
          onChange={() =>
            setSearchParams({
              ...searchParams,
              showHidden: !searchParams.showHidden
            })
          }
        />
      </GroupedFields>
      <SidebarErrorWrapper show={formErrors.form}>
        {formErrors.form}
      </SidebarErrorWrapper>
      <ActionButton onClick={() => validateSearchFields()}>искать</ActionButton>
    </SidebarFormWrapper>
  );
}

export default GlobalSearchForm;
