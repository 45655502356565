import { createEntryApi, updateEntryApi } from 'api/entries';
import { navigate } from '@reach/router';
import moment from 'moment';

export const publishEntry = ({
  blogSlug,
  entryId,
  targetBlog,
  tags,
  hotUpdateTagList,
  isPinned,
  unpinEntryIfPinned,
  pinEntry,
  requestFields,
  updatePublishAt,
  saveEntryDraft,
  token
}) => async (dispatch) => {
  let res;
  if (entryId && entryId !== '0') {
    res = await updateEntryApi({
      data: {
        ...requestFields,
        ...(updatePublishAt ? { publishedAt: moment().toISOString() } : {})
      },
      entryId,
      token
    });
  } else {
    res = await createEntryApi({
      ...requestFields,
      targetBlog,
      token
    });
  }

  if (res.error) {
    console.error(res.error);
    window.alert('не получилось сохранить запись :( ');
  } else {
    if (tags.length) {
      hotUpdateTagList(tags);
    }

    if (isPinned) {
      await pinEntry(res.data.id);
    } else {
      unpinEntryIfPinned(res.data.id);
    }

    setTimeout(
      () => saveEntryDraft({ id: entryId, content: '' }, targetBlog),
      0
    );

    setTimeout(() => {
      if (entryId && entryId !== '0') {
        navigate(`/blog/${blogSlug}/${entryId}`);
      } else {
        navigate(`/blog/${blogSlug}/`);
      }
    }, 100);
  }
};
