import React from 'react';

export const FavoritesIcon = ({ className }) => (
  <svg
    className={`icon icon-favorites ${className}`}
    viewBox="0 0 30 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="star-fill filling-area"
      d="m 15.000061,5.5e-4 c -0.204096,0 -0.39087,0.115932 -0.481141,0.299591 L 10.174213,9.1029563 0.46011576,10.514689 c -0.202446,0.02933 -0.370433,0.171286 -0.433852,0.365668 -0.06324,0.193831 -0.01008,0.407091 0.13582,0.549875 l 7.02968544,6.851389 -1.6598368,9.675423 c -0.034826,0.201346 0.047656,0.404892 0.2131685,0.525131 0.093387,0.06782 0.2045538,0.102552 0.3157204,0.102552 0.085231,0 0.1712863,-0.02016 0.2497353,-0.0614 l 8.6894034,-4.567635 8.688487,4.567635 c 0.181001,0.09614 0.400493,0.08065 0.565914,-0.04124 0.164871,-0.120331 0.247536,-0.323785 0.213169,-0.525131 l -1.65926,-9.674858 7.029686,-6.852415 c 0.146634,-0.142327 0.198689,-0.356045 0.13582,-0.549876 -0.06415,-0.194931 -0.231498,-0.336066 -0.433944,-0.365668 L 19.825276,9.1024065 15.481138,0.299591 C 15.391325,0.115932 15.204551,0 14.999905,0 Z"
    />
    <path
      className="star-outline filling-outline"
      d="m 15.000061,5.5e-4 c -0.204004,0 -0.39087,0.115932 -0.481141,0.299591 L 10.174213,9.1029563 0.46011576,10.514689 c -0.202446,0.02933 -0.370433,0.171286 -0.433852,0.365668 -0.06324,0.193831 -0.01008,0.407091 0.13582,0.549875 l 7.02968544,6.851389 -1.6598368,9.675423 c -0.034826,0.201346 0.047656,0.404892 0.2131685,0.525131 0.093387,0.06782 0.2045538,0.102552 0.3157204,0.102552 0.085231,0 0.1712863,-0.02016 0.2497353,-0.0614 l 8.6894034,-4.567635 8.688487,4.567635 c 0.181001,0.09614 0.400493,0.08065 0.565914,-0.04124 0.164871,-0.120331 0.247536,-0.323785 0.213169,-0.525131 l -1.65926,-9.674858 7.029686,-6.852415 c 0.146634,-0.142327 0.198689,-0.356045 0.13582,-0.549876 -0.06415,-0.194931 -0.231498,-0.336066 -0.433944,-0.365668 L 19.825276,9.1024065 15.481138,0.299591 C 15.391325,0.115932 15.204551,0 14.999905,0 Z m 2e-6,3.0677907 3.637028,7.2389843 c 0.07112,0.141968 0.209127,0.240543 0.368737,0.263133 l 8.131331,1.160902 -5.884185,5.635405 c -0.115053,0.110635 -0.167896,0.269888 -0.14053,0.425815 l 1.388759,7.956862 -7.272931,-3.756642 c -0.143499,-0.07377 -0.313374,-0.07377 -0.455792,0 L 7.4985347,25.749442 8.8878486,17.792576 C 8.9148566,17.636659 8.8626456,17.476957 8.7473223,17.366761 L 2.8631468,11.73136 10.994469,10.570913 c 0.159611,-0.02254 0.297254,-0.121266 0.368738,-0.263134 z"
    />
  </svg>
);
