import styled from 'styled-components';

export const NotificationNumber = styled.div`
  background: ${p => p.theme.brand};
  color: ${p => p.theme.textLighter};
  font-size: 12px;
  padding: 10px 7px;
  border-radius: 10px;
  position: absolute;
  top: -9px;
  right: -14px;
`;

export const IconButton = styled.div`
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*width: 100%;
  height: 100%;*/
  line-height: 0;

  /* box-shadow: ${props =>
    props.active ? 'inset 0 0 16px #000000' : 'none'}; */

  .icon-description {
    color: ${props => props.theme.textLighter};
    height: 10px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    font-weight: 100;
    position: absolute;
    top: 65px;
    opacity: 0;
    transition: opacity 0.2s;
  }
  .icon-description--arrowed {
    top: 57px;
    /* small arrow */
    .icon {
      position: relative;
      top: 3px;
      margin-left: 4px;
    }
  }

  /* new stuff */

  .icon-comments,
  .icon-journal {
    height: 27px;
    width: 27px;
  }
  .icon-new-post,
  .icon-search {
    height: 24px;
    width: 24px;
  }
  .icon-fast-forward-quick-access {
    height: 22px;
    width: 22px;
  }

  .icon-favorites {
    height: 26px;
    width: 26px;
  }

  .icon {
    .main-outline,
    .filling-outline,
    .filling-area {
      fill: ${props => props.theme.textLighter};
      transition: opacity 0.3s;
    }
    .filling-area,
    .secondary-filling-area {
      opacity: 0;
    }
  }

  &:hover .icon,
  .icon.icon-active {
    .filling-area {
      opacity: 1;
    }
    .plus-sign.main-outline {
      fill: ${props => props.theme.backgroundDarker};
    }
  }
`;

export const AvatarButton = styled.button`
  max-width: 44px;
  max-height: 44px;
  cursor: pointer;
  transition: all 0.2s;
  border: 0;
  background: transparent;
  font-size: 0px;

  &:hover img {
    border-color: ${props => props.theme.brand};
  }

  &:active img {
    outline: none;
  }

  img {
    height: auto;
    width: auto;
    max-width: 44px;
    max-height: 44px;
    border: 2px solid ${props => props.theme.textLighter};
    border-radius: 2px;
  }
`;

//* * buttons that look like buttons (register or login) */

// use this for buttons that lead to another page
export const LinkButton = styled.div`
  margin-right: 5px;
  width: 100px;
  font-size: 14px;
`;

// use this for buttons that do something on the current page (like opening the sidebar)
/*export const Button = styled(ActionButton)`
  margin-right: 5px;
  width: 100px;
  font-size: 14px;
`;
*/
