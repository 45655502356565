import createPersistedState from 'use-persisted-state';
import { parseToken } from 'store/user/reducer/parseToken';

export const useTokenState = createPersistedState('token');

export function useActiveProfile() {
  const [token] = useTokenState();

  return parseToken(token);
}

export function isActiveProfileValid(activeProfileId) {
  return Boolean(activeProfileId) && activeProfileId !== '0';
}
