import styled from 'styled-components';

export const DesignList = styled.div`
  background: ${p => p.theme.backgroundLight};
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 460px;
  position: relative;

  .simplebar-scrollbar {
    width: 5px;
  }
  .horizontal.simplebar-track .simplebar-scrollbar {
    height: 5px;
  }
  .simplebar-scrollbar:before {
    background: ${p => p.theme.backgroundDark};
    border-radius: 0px;
  }

  .design {
    position: relative;
    padding: 20px 40px;
    margin: 20px 30px;
    border: 1px solid ${p => p.theme.backgroundDark};
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media screen and (max-width: 480px) {
    .design {
      padding: 20px;
    }
  }

  .design span {
    display: inline-block;
  }

  .design.current {
    border: 1px solid ${p => p.theme.currentDesign};
  }

  .design.current .action-buttons {
    display: none;
  }

  .action-buttons,
  .preview {
    flex-basis: 100%;
  }

  .preview {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  .design-controls button {
    display: inline-block;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .design-controls button:last-child {
    margin-left: 10px;
  }

  .action-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 1;
  }

  .icon-edit,
  .icon-copy-design {
    height: 20px;
    width: 22px;
  }

  .icon-copy-design {
    margin-right: 11px;
  }

  .design.current .icon-check-mark {
    height: 24px;
    width: 24px;
    position: absolute;
    left: -10px;
  }

  /***** WHILE NO PREVIEW *****/
  .preview img {
    display: none;
  }

  .preview {
    border-top: 2px solid ${p => p.theme.backgroundDark};
  }

  /* .icon-check-mark {
  left: -12px;
  top: 24px;
} */
  /**************************/

  .icon-garbage-bin {
    transition: opacity 0.2s;
    transition: fill 0.2s;
    width: 18px;
    height: 20px;
  }

  .garbage-bin-fill-with-holes {
    opacity: 0;
  }

  .icon {
    fill: ${p => p.theme.textLight};
  }

  .icon-edit,
  .icon-copy-design {
    transition: fill 0.2s;
  }

  .icon-check-mark .check-mark.main-outline {
    fill: ${p => p.theme.backgroundLight};
  }
  .icon-check-mark .circle-fill.main-outline {
    fill: ${p => p.theme.currentDesign};
  }

  button:hover .icon-garbage-bin {
    fill: ${p => p.theme.brand};
    .garbage-bin-detail-lines {
      opacity: 0;
    }
    .garbage-bin-fill-with-holes {
      opacity: 1;
    }
  }
  button:hover .icon-edit,
  button:hover .icon-copy-design {
    fill: ${p => p.theme.accent};
  }
`;

export const StyledArchiveUpload = styled.div`
  width: 100%;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }

  .dropzone {
    background: ${p => p.theme.backgroundLight};
    border: 2px dashed ${p => p.theme.divider};
    border-radius: 0;
    div {
      height: 70px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  .dropzone-reject {
    border-color: ${props => props.theme.brand};
  }

  a {
    color: ${props => props.theme.accentDark};
  }
`;

export const StyledCustomPrivacySettings = styled.div`
  .privacy-setting {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .label {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select {
    width: 50%;
    flex-shrink: 0;
  }
`;

export const StyledHint = styled.p`
  color: ${p => p.theme.textLight};
`;

export const StyledStatusList = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 40px;

  td,
  th {
    padding: 6px;
    border-bottom: 1px solid ${p => p.theme.divider};
    text-align: left;
  }
`;
