import React, {Fragment} from 'react/index';
import {Child} from '../components/styled';
import FontSettings from '../components/FontSettings';
import TextAlignSetting from '../components/AlignTextSetting';

export const FontSettingsTab = ({ design, changeSetting }) => (
  <Fragment>
    <Child>
      <FontSettings
        label="тема записи"
        setting={design.fonts.headers}
        update={changeSetting}
        nodeKey="fonts.headers"
      />
    </Child>
    <Child>
      <FontSettings
        label="служебные элементы"
        setting={design.fonts.elements}
        update={changeSetting}
        nodeKey="fonts.elements"
      />
    </Child>
    <Child className="extra-space">
      <FontSettings
        label="текст записи"
        setting={design.fonts.entries}
        update={changeSetting}
        nodeKey="fonts.entries"
      />
      <TextAlignSetting
        setting={design.fonts.entries.align}
        update={changeSetting}
        nodeKey={'fonts.entries.align'}
      />
    </Child>
    {/*<Child>
        <FontSettings
          label="меню"
          setting={design.fonts.menu}
          update={this.changeSetting}
          nodeKey={'fonts.menu'}
        />
      </Child>*/}
  </Fragment>
);
