import LeaveCommunityIcon from 'styles/Icons/LeaveCommunityIcon';
import JoinCommunityIcon from 'styles/Icons/JoinCommunityIcon';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { joinCommunityThunk } from 'store/user/thunks/joinCommunityThunk';
import { leaveCommunityThunk } from 'store/user/thunks/leaveCommunityThunk';
import { selectCommunities } from 'store/user/selectors/selectCommunities';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

const StyledCommunityButton = styled.div`
  .icon {
    display: block;
  }

  margin-bottom: 10px;

  .icon-leave-community,
  .icon-join-community {
    fill: ${(p) => p.colors.text};
    width: 41px;
    height: 40px;

    @media (max-width: 480px) {
      width: 34px;
      height: 33px;
    }

    cursor: pointer;

    .filling-area {
      transition: all 0.4s;
      fill: ${(p) => p.colors.fillColor};
    }

    &:hover .main-outline {
      fill: ${(props) => props.colors.fillColor};
    }
  }
`;

function CommunitySideButtons({
  community,
  colors,
  memberOfCommunities,
  leaveCommunityThunk,
  joinCommunityThunk
}) {
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  if (
    !community.isCommunity ||
    activeProfileId === community.id ||
    memberOfCommunities === undefined
  ) {
    return null;
  }

  const isInCommunity = Boolean(memberOfCommunities[community.id]);

  const leaveCommunity = () => {
    if (window.confirm('Вы уверены, что хотите выйти из сообщества?')) {
      leaveCommunityThunk({
        activeProfileId,
        communityId: community.id,
        token
      });
    }
  };
  const joinCommunity = () => {
    joinCommunityThunk({
      activeProfileId,
      community,
      token
    });
  };

  return (
    <div>
      <StyledCommunityButton
        colors={colors}
        onClick={isInCommunity ? leaveCommunity : joinCommunity}
        title={isInCommunity ? 'выйти из сообщества' : 'вступить в сообщество'}
      >
        {isInCommunity ? <LeaveCommunityIcon /> : <JoinCommunityIcon />}
      </StyledCommunityButton>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    memberOfCommunities: selectCommunities(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  leaveCommunityThunk,
  joinCommunityThunk
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(CommunitySideButtons)
);
