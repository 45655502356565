import React, { useContext, useState } from 'react';

const EntryEditContext = React.createContext();
EntryEditContext.displayName = 'EntryEditContext';

export function useEntryEditContext() {
  const context = useContext(EntryEditContext);
  if (context === undefined) {
    throw new Error(
      `useEntryEditContext must be used within a EntryEditProvider`
    );
  }
  return context;
}

export function EntryEditProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [preview, setPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [entryContent, setEntryContent] = useState('');
  const [entryTitle, setEntryTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [entrySettings, setEntrySettings] = useState({});
  const [isPinned, setIsPinned] = useState(false);
  const [updatePublishAt, setUpdatePublishAt] = useState(false);

  const value = {
    loading,
    setLoading,
    submitting,
    setSubmitting,
    error,
    setError,
    preview,
    setPreview,
    previewContent,
    setPreviewContent,
    entryContent,
    setEntryContent,
    entryTitle,
    setEntryTitle,
    tags,
    setTags,
    entrySettings,
    setEntrySettings,
    isPinned,
    setIsPinned,
    updatePublishAt,
    setUpdatePublishAt
  };
  return (
    <EntryEditContext.Provider value={value}>
      {children}
    </EntryEditContext.Provider>
  );
}
