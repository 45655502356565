import styled from 'styled-components';

export const BirthdayBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 20px 0 50px;
  position: relative;
  z-index: 5;

  .error {
    --select-color: ${p => p.theme.brand};
  }
  .valid {
    --select-color: ${p => p.theme.accent};
    .dybr-select__single-value {
      font-weight: bold;
    }
  }
  .unset {
    --select-color: ${p => p.theme.textLight};
    .dybr-select__single-value {
      color: var(--select-color);
    }
  }

  .setting-select {
    .dybr-select__indicator-separator {
      display: none;
    }
    .dybr-select__control {
      min-width: 110px;
    }
    &.dybr-select {
      border-color: var(--select-color);
    }
    &.dybr-select:hover {
      border-color: ${p => p.theme.accent};
    }
    .dybr-select__dropdown-indicator {
      color: var(--select-color);
    }
  }
`;
export const Meters = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  margin: 0 10px;
  align-items: flex-start;
  & > div:first-child {
    margin-right: 10px;
  }
`;

export const TitleBlock = styled.div`
  height: 40px;
  max-width: 25%;
  display: flex;
`;

export const Title = styled.div`
  color: ${p => p.theme.textLight};
  font-size: 1em;
  text-align: left;
  align-self: center;
`;
export const ErrorText = styled.div`
  font-size: 0.9em;
  color: ${p => p.theme.brand};
  width: 100%;
  margin-top: 10px;
`;
