import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-up-with-background ${className}`}
    viewBox="0 0 21.912875 21.912875"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="circle main-outline"
      d="M 10.956438,21.912874 A 10.956437,10.956437 0 0 1 5.0000001e-7,10.956436 10.956437,10.956437 0 0 1 10.956438,3.6090088e-7 10.956437,10.956437 0 0 1 21.912874,10.956436 10.956437,10.956437 0 0 1 10.956438,21.912874 Z"
    />
    <path
      className="arrow main-outline"
      d="m 15.709813,13.698079 c 0.243288,0.02579 0.493155,-0.04811 0.669922,-0.242188 0.302609,-0.299913 0.329692,-0.817964 0.05859,-1.146484 -1.435059,-1.478922 -2.885442,-2.9443198 -4.326172,-4.4179698 -0.33657,-0.310404 -0.629234,-0.801799 -1.15625,-0.736328 -0.527029,-0.06548 -0.819724,0.425881 -1.1562505,0.736328 -1.440447,1.4736 -2.889335,2.9391868 -4.324214,4.4179698 -0.27137,0.328296 -0.245721,0.846321 0.05664,1.146484 0.353534,0.388166 1.000812,0.294886 1.296874,-0.113281 1.374742,-1.405826 2.754372,-2.807012 4.1269545,-4.2148448 1.372582,1.4078328 2.752212,2.8090188 4.126953,4.2148448 0.148032,0.204083 0.383665,0.329682 0.626953,0.355469 z"
    />
  </svg>
);

export default Icon;
