import React from 'react';

export const CloseDeleteIcon = ({ className }) => (
  <svg
    viewBox="0 0 17.082941 17.082943"
    className={`icon icon-close-delete ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      className="cross main-outline"
      d="M 15.073183,0 8.5414686,6.5317134 2.009756,0 0,2.0097584 6.5317116,8.5414714 0,15.073184 l 2.009756,2.009758 6.5317126,-6.531713 6.5317144,6.531713 2.009758,-2.009758 -6.531714,-6.5317126 6.531714,-6.531713 z"
    />
  </svg>
);
