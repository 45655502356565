import { query } from './Query';
import { parseToken } from 'store/user/reducer/parseToken';

export const requestPasswordChangeApi = async (email) => {
  try {
    await query({ method: 'CUSTOM_POST', resource: `restore?email=${email}` });
    return { status: 'ok' };
  } catch (err) {
    console.log('RequestPasswordChange', err);
    if (err.response && err.response.status === 404)
      return { error: 'такой адрес не найден!', response: err.response };
    if (err.response && err.response.status === 403)
      return { error: 'адрес почты не подтвержден', response: err.response };
    else
      return {
        error: 'что-то случилось, но не то, что мы ожидали',
        response: err.response
      };
  }
};

export const loginApi = async ({ email, password }) => {
  try {
    let response = await query({
      method: 'POST',
      resource: 'sessions',
      queryParams: {
        password,
        email,
        action: 'login'
        // expireAfterMinutes: 10
      }
    });
    const { accessToken } = response.data;

    return { accessToken };
  } catch (err) {
    console.log('requestLogin error', err);

    if (err.response && err.response.status === 403)
      return { error: 'неправильная почта или пароль' };
    if (err.response && err.response.status === 400)
      return { error: 'почта не подтверждена' };
    if (
      !err.response ||
      err.response.status === 502 ||
      err.message === 'Network error'
    ) {
      return {
        error: 'сервер отключен, ведутся работы'
      };
    } else return { error: 'что-то случилось, не знаем точно что' };
  }
};

export const switchProfileApi = async ({ profileId, token }) => {
  try {
    let response = await query({
      method: 'POST',
      resource: 'sessions',
      queryParams: {
        action: 'switch-profile',
        profile: profileId
      },
      token
    });
    const { accessToken } = response.data;

    return { accessToken };
  } catch (err) {
    return { error: 'что-то пошло не так', ...err.response };
  }
};

export const confirmEmailApi = async ({ email, token }) => {
  try {
    const res = await query({
      method: 'POST',
      resource: 'sessions',
      queryParams: {
        action: 'confirm',
        email,
        token
      },
      token
    });
    return { status: 'ok', token: res.data.accessToken };
  } catch (err) {
    console.log('ConfirmEmail err', err);

    if (err.response && err.response.status === 403)
      return {
        error: 'неправильный токен, проверьте ссылку',
        response: err.response
      };
    if (err.response && err.response.status === 400)
      return {
        error: 'аккаунт не подтвержден, подождите приглашения',
        response: err.response
      };

    return { error: 'что-то случилось' };
  }
};

export const resendConfirmEmailApi = async ({ email }) => {
  try {
    await query({
      method: 'POST',
      resource: 'sessions',
      queryParams: {
        action: 'resend-confirm',
        email
      }
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('resendConfirmEmail err', err);

    return {
      error: 'cервер не хочет кооперироваться'
    };
  }
};

export const changePasswordApi = async ({ token, password }) => {
  try {
    let response = await query({
      method: 'POST',
      resource: 'sessions',
      queryParams: {
        password,
        action: 'change-password',
        token
      },
      token
    });
    const { accessToken } = response.data;

    const { sub: userId, exp: tokenExp } = parseToken(accessToken);

    return { status: 'ok', accessToken, tokenExp, userId };
  } catch (err) {
    console.log('ChangePassword', err);

    if (err.response && err.response.status === 403)
      return {
        error: 'неправильный токен, проверьте ссылку',
        response: err.response
      };
    if (err.response && err.response.status === 400)
      return {
        error: 'аккаунт не подтвержден, подождите приглашения',
        response: err.response
      };

    return { error: 'что-то случилось' };
  }
};

// TODO this api is never used
// const checkLoginApi = async id => {
//   try {
//     await query('GET', 'users', { id });
//     return { status: 'ok' };
//   } catch (err) {
//     console.log('checkLogin', err);
//     return { error: 'не удалось загрузить пользователя' };
//   }
// };
