import React from 'react';
import moment from 'moment';
import { BirthdayCakeIcon } from 'styles/Icons/BirthdayCake';
import { Cake } from './styled/StyledCake';

export const BirthdayIcon = ({ birthday, nick }) => {
  if (!birthday) return null;

  const today = moment();
  const birth = moment(birthday);
  return (
    birth.month() === today.month() &&
    birth.date() === today.date() && (
      <Cake className="birthday-icon" title={`У ${nick} сегодня День Торта`}>
        <BirthdayCakeIcon />
      </Cake>
    )
  );
};
