import { createSelector } from 'reselect';
import { selectLists } from './selectLists';

export const selectFamiliarProfiles = createSelector(selectLists, lists => {
  const readers = lists.readers;
  const nonMutualFavorites = lists.favorites.filter(
    id => !readers.includes(id)
  );
  return [...readers, ...nonMutualFavorites].map(id => lists.profiles[id]);
});
