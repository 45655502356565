import React from 'react';

const Icon = ({ className }) => (
  <svg
    height="26"
    width="26"
    viewBox="0 0 612.011 262.99864"
    className={`icon icon-message-envelope ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="envelope-inside main-outline"
      d="m 559.69192,-40.895853 c 12.19015,2.987624 20.74193,10.653134 24.01384,23.087586 L 420.3928,117.66053 583.77635,281.08418 c -3.39738,12.43423 -12.89828,19.94219 -27.89367,22.69259 L 388.9321,141.60165 312.32141,205.12484 c -1.82101,1.51773 -4.0733,2.28077 -6.3156,2.28077 -2.24221,0 -4.49449,-0.76304 -6.31561,-2.28077 L 223.08192,141.60165 56.152469,303.7955 c -15.146834,-2.73188 -24.726,-10.24406 -28.027703,-22.73488 L 191.61892,117.55707 28.181206,-17.909373 c 3.145153,-12.317855 11.638285,-19.945396 23.849403,-22.951211 L 306.00581,169.44359 Z"
    />
    <path
      className="envelope-fill filling-area"
      d="M 66.255647,-41.791091 H 545.75627 c 4.18439,0 9.40144,-0.107338 12.98874,1.50313 L 306.00595,169.2654 53.040026,-40.202423 c 3.588233,-1.609523 9.032171,-1.588668 13.215621,-1.588668 z M 29.34401,-17.324275 192.15278,117.62167 28.879427,280.90187 c -1.310585,-3.18311 -1.468028,-7.33132 -1.468028,-10.98165 V -5.5677365 c 0,-4.03755 0.341511,-8.3020955 1.932611,-11.7565385 z m 553.41318,-0.07819 c 1.59111,3.455266 1.84332,7.9029075 1.84332,11.9403165 V 270.02582 c 0,3.65022 -0.1211,7.85586 -1.43169,11.049 L 419.85913,117.7249 Z M 223.4675,141.5541 l 76.25252,63.22542 c 1.81258,1.51063 4.05412,2.27131 6.28593,2.27131 2.23181,0 4.47335,-0.76068 6.28593,-2.27131 l 76.25253,-63.22542 166.41153,161.6587 c -3.78122,1.89017 -9.36738,1.5769 -13.89009,1.57692 H 70.943704 c -4.520857,0 -10.155846,0.40227 -13.936147,-1.48698 z"
    />
    <path
      className="envelope-outside main-outline"
      d="m 51.436716,-69.098501 c -14.69,0 -27.881137,6.193583 -37.263136,15.987582 -0.299081,0.30101 -0.61169,0.63222 -0.838136,0.912843 C 5.1144466,-43.186075 0,-31.340808 0,-18.275804 V 281.26976 c 0,13.382 5.34311,25.50532 13.933112,34.59232 0.239003,0.29226 0.37789,0.43569 0.642025,0.7214 9.361003,9.541 22.394913,15.5137 36.863914,15.51365 H 560.57429 c 14.46901,0 27.51158,-5.98299 36.86158,-15.52299 0.21776,-0.24086 0.49242,-0.54397 0.61168,-0.68638 8.601,-9.08599 13.96346,-21.22267 13.96346,-34.61567 V -18.275804 h -0.0117 c 0,-13.065004 -5.15848,-24.884591 -13.39147,-33.896591 -0.25826,-0.344417 -0.53388,-0.640266 -0.79144,-0.938524 -9.36099,-9.793999 -22.55444,-15.987582 -37.24444,-15.987582 z M 583.20626,-4.9543665 V 269.15534 c -0.16631,23.24544 -15.38041,34.55038 -43.31685,34.58998 H 72.119262 C 43.99533,303.70572 28.65586,292.40078 28.804748,269.05027 V -5.0594255 C 28.65586,-29.409332 43.890194,-41.328489 67.454659,-41.101548 H 544.55635 c 23.2695,-0.226941 38.4836,11.692216 38.64991,36.1471815 z"
    />
  </svg>
);
export default Icon;
