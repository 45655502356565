import React, { useCallback, useEffect, useState } from 'react';

import TextField from 'components/FormElements/TextField';
import configs from 'configs/dybr';
import { ActionButton, SecondaryButton } from 'components/Shared/Buttons';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { connect } from 'react-redux';
import {
  deleteCrosspostCredentialsAPI,
  saveCrosspostCredentialsApi,
  testCrosspostApi
} from 'api/crosspost';
import css from 'scenes/User/Settings/CrosspostBlock.module.css';
import Switch from 'components/FormElements/Switch';
import Checkbox from 'components/FormElements/Checkbox';
import SoftAlert, { SoftAlertType } from 'components/Shared/SoftAlert';
import { classNames } from 'utils/helpers/classNames';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

function CrosspostBlock({ platform, profile, updateProfileSetting }) {
  const [token] = useTokenState();

  const platformSettings = profile.settings?.crosspost?.[platform.id];
  const [isOn, setIsOn] = useState(Boolean(platformSettings?.hasCredentials));

  const [user, setUser] = useState(platformSettings?.user || '');
  const passwordLength = platformSettings?.passwordLength;
  const [password, setPassword] = useState('');
  const [passwordManualMode, setPasswordManualMode] = useState(false);

  const [tag, setTag] = useState(platformSettings?.tag || '');
  const [footer, setFooter] = useState(Boolean(platformSettings?.footer));

  const [notification, setNotification] = useState('');
  const [error, setError] = useState('');
  const [loadingText, setLoadingText] = useState('');

  const [checkPassed, setCheckPassed] = useState(false);

  useEffect(() => {
    let timeoutHandler;
    if (notification) {
      timeoutHandler = setTimeout(() => setNotification(''), 4500);
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [notification]);

  const testLogin = async () => {
    setError('');
    setNotification('');
    setLoadingText('ведется проверка...');

    const connectionSettings = {
      host: platform.id,
      user,
      password
    };

    const response = await testCrosspostApi({ connectionSettings, token });
    setLoadingText('');

    if (response.error) {
      if (response.status === 400) {
        setError('логин или пароль введены неверно');
        return;
      }
      setError('не удалось проверить, что-то пошло не так');
      console.error(response);
    } else {
      setNotification('проверка прошла успешно');
      setCheckPassed(true);
    }
  };

  const save = async () => {
    setError('');
    setNotification('');
    setLoadingText('сохраняем...');

    const newPasswordLength = password ? password.length : passwordLength;

    updateProfileSetting(
      `crosspost.${platform.id}`,
      {
        ...platformSettings,
        passwordLength: newPasswordLength,
        // eslint-disable-next-line
        footer: footer ? '<a href="${SRC}">Оригинал записи на Дыбре</a>' : '',
        hasCredentials: true,
        user,
        tag
      },
      token
    );

    let response = {};
    if (password) {
      response = await saveCrosspostCredentialsApi({
        host: platform.id,
        password,
        token
      });
    }

    setLoadingText('');

    if (response.error) {
      setNotification('');
      setError('не удалось сохранить, что-то пошло не так');
      console.error(response);
    } else {
      setNotification('настройки сохранены');
      setPassword('');
    }
  };

  const handleSwitchChange = useCallback(() => {
    if (!isOn) {
      setIsOn(true);
    } else {
      // 1. close the block and clear ui
      setIsOn(false);
      setUser('');
      setPassword('');
      setTag('');
      setFooter(false);

      setError('');
      setNotification('');
      setLoadingText('');
      setCheckPassed(false);
      setPasswordManualMode(false);

      // 2. request to delete all data in backend
      const deleteCrosspost = async () => {
        updateProfileSetting(`crosspost.${platform.id}`, {}, token);

        const response = await deleteCrosspostCredentialsAPI({
          host: platform.id,
          token
        });

        if (response.error) {
          console.error(response);
          // todo toast notifications
        }
      };
      deleteCrosspost();
    }
  }, [isOn, platform.id, token, updateProfileSetting]);

  const hasSettingsChanges =
    tag !== (platformSettings?.tag ?? '') ||
    footer !== Boolean(platformSettings?.footer);

  const hasCredentialsChanges =
    user !== (platformSettings?.user ?? '') || Boolean(password);

  const hasChanges = hasSettingsChanges || hasCredentialsChanges;

  const hasCredentials = platformSettings?.hasCredentials;
  const canSave = hasChanges && user && (password || hasCredentials);
  const passwordValue =
    passwordManualMode || password ? password : '*'.repeat(passwordLength ?? 0);

  const willAddCredentials = !hasCredentials || hasCredentialsChanges;

  function handleChange(callback, newValue, isCredential = true) {
    setNotification('');

    if (isCredential) {
      setError('');
      setCheckPassed(false);
    }
    callback(newValue);
  }
  const showSoftAlert = notification || loadingText || error;

  return (
    <div className={css.container}>
      <div>
        <Switch
          label={`кросспост на ${platform.displayName}`}
          checked={isOn}
          onClick={handleSwitchChange}
        />
      </div>

      {isOn && (
        <>
          <TextField
            label={`${platform.displayName} логин`}
            isRequired
            value={user}
            onChange={(e) => handleChange(setUser, e.target.value)}
            error={error ? ' ' : ''}
            touched
            className={css.textFieldWrapper}
          />
          <TextField
            label={`${platform.displayName} пароль`}
            isRequired
            onFocus={() => setPasswordManualMode(true)}
            onBlur={() => setPasswordManualMode(false)}
            value={passwordValue}
            onChange={(e) => handleChange(setPassword, e.target.value)}
            error={error ? ' ' : ''}
            type="password"
            touched
            className={css.textFieldWrapper}
          />
          <TextField
            label={`тег для записи на ${platform.displayName}`}
            value={tag}
            onChange={(e) => handleChange(setTag, e.target.value, false)}
            maxLen={configs.MAX_LENGTH.crosspost.tag}
            touched
            className={css.textFieldWrapper}
          />
          <Checkbox
            label='добавить к записи подпись-линк "Оригинал записи на Дыбре"'
            onChange={() => handleChange(setFooter, !footer, false)}
            checked={footer}
            className={css.footerCheckboxContainer}
          />

          <div className={css.buttonsWrapper}>
            <ActionButton
              onClick={save}
              className={css.buttonLeft}
              disabled={
                !hasChanges ||
                Boolean(loadingText) ||
                (willAddCredentials && (!checkPassed || !canSave))
              }
            >
              Coхранить
            </ActionButton>

            <SecondaryButton
              onClick={testLogin}
              className={css.buttonLeft}
              disabled={
                Boolean(loadingText) ||
                !willAddCredentials ||
                !canSave ||
                checkPassed
              }
            >
              Проверить
            </SecondaryButton>
          </div>
          <div
            className={classNames({
              [css.softAlertContainer]: true,
              [css.active]: showSoftAlert
            })}
          >
            {notification && (
              <SoftAlert
                label={notification}
                type={SoftAlertType.success}
                className={css.softAlert}
                hideTimeout={4000}
              />
            )}
            {loadingText && (
              <SoftAlert
                label={loadingText}
                type={SoftAlertType.inProgress}
                className={css.softAlert}
              />
            )}

            {error && (
              <SoftAlert
                label={error}
                type={SoftAlertType.error}
                className={css.softAlert}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  updateProfileSetting
};

export const CrosspostBlockContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(CrosspostBlock)
);
