import React from 'react';

export const CalendarIcon = ({ className }) => (
  <svg
    viewBox="0 0 3.8770768 3.1985885"
    className={`icon icon-calendar ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="calendar-fill filling-area"
      d="M 0.12429611,1.3341467 H 3.7841262 V 3.005239 H 0.12429611 Z"
    />
    <path
      className="calendar main-outline"
      d="M 3.4893691,0.29078087 H 3.1443861 V 0.09692709 C 3.1443861,0.04361589 3.0815431,0 3.0047345,0 2.9279255,0 2.8650829,0.04361724 2.8650829,0.09692709 V 0.29078087 H 1.0119937 V 0.09692709 C 1.0119937,0.04361724 0.94915063,0 0.87234219,0 0.79553389,0 0.73269061,0.04361724 0.73269061,0.09692709 V 0.29078087 H 0.3877077 C 0.17446849,0.29078087 0,0.46524907 0,0.67848845 V 2.8108805 c 0,0.2132394 0.17446849,0.3877079 0.3877077,0.3877079 h 3.1016614 c 0.2132393,0 0.3877078,-0.1744685 0.3877078,-0.3877079 V 0.67848845 c 0,-0.21323938 -0.1744685,-0.38770758 -0.3877078,-0.38770758 z M 3.6026566,2.6839274 c 0,0.1066197 -0.087235,0.1938542 -0.1938539,0.1938542 H 0.46094989 c -0.10661961,0 -0.19385383,-0.087235 -0.19385383,-0.1938542 V 1.4839299 H 3.6026566 Z m 0.00244,-1.5208043 H 0.26221325 V 0.81276579 c 0,-0.10661969 0.0872342,-0.19385378 0.19385383,-0.19385378 h 0.2766237 v 0.15650351 c 0,0.0533077 0.0628431,0.096927 0.13965159,0.096927 0.0768083,0 0.13942773,-0.0436198 0.13965153,-0.096927 V 0.61891201 H 2.865083 v 0.15650351 c 0,0.0533096 0.062843,0.096927 0.1396516,0.096927 0.076809,0 0.1396516,-0.043617 0.1396516,-0.096927 V 0.61891201 h 0.266858 c 0.1066197,0 0.1938544,0.0872341 0.1938539,0.19385378 z"
    />
  </svg>
);
