import React, { useEffect, useState } from 'react';
import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import { connect } from 'react-redux';
import Checkbox from 'components/FormElements/Checkbox';
import { ActionButton, ImportantButton } from 'components/Shared/Buttons';
import css from 'scenes/Management/Community/JoinRequests.module.css';
import {
  editCommunityJoinRequestsApi,
  getCommunityJoinRequestsApi
} from 'api/communities';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import ProfileLink from 'scenes/Management/Community/ProfileLink';
import ScrollArea from 'components/DybrComponents/ScrollArea';
import { communityHasMemberModeration } from 'scenes/Management/Community/utils';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

function JoinRequests({ settings, profile }) {
  const [allRequests, setAllRequests] = useState(false);
  const [requests, setRequests] = useState([]);
  const [token] = useTokenState();

  useEffect(() => {
    const loadRequests = async () => {
      const res = await getCommunityJoinRequestsApi({
        token,
        communityId: profile.id
      });

      if (res.data) {
        setRequests(res.data ?? []);
      }
    };
    loadRequests();
  }, [profile.id, token]);

  const handleCheckboxClick = (id) => (e) => {
    const selected = e.target.checked;
    const newReqs = requests.map((r) => (r.id === id ? { ...r, selected } : r));

    setRequests(newReqs);
    setAllRequests(newReqs.every((r) => r.selected));
  };

  const selectedRequests = requests.filter((r) => r.selected);

  const handleRequest = (state) => async () => {
    for (const handlingRequest of selectedRequests) {
      console.log('handling request', handlingRequest);
      const res = await editCommunityJoinRequestsApi({
        request: handlingRequest,
        communityId: profile.id,
        state,
        token
      });
      console.log('res', res);
      if (res.status === 200) {
        setRequests(requests.filter((r) => r.id !== handlingRequest.id));
      } else {
        alert(
          `Не удалось обработать заявку от профиля ${handlingRequest.profile.nickname}`
        );
      }
    }
  };

  const handleSelectAll = () => {
    requests.forEach((r) => (r.selected = !allRequests));
    setAllRequests((allReq) => !allReq);
  };

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Заявки на вступление</h4>
          <p>
            Вы можете просмотреть заявки на вступление в сообщество и решить,
            что с ними сделать
          </p>
        </SettingSectionTitle>
        <Settings>
          {communityHasMemberModeration(settings) && requests.length > 0 ? (
            <>
              <Checkbox
                className={css.allRequestCheckbox}
                label="все заявки"
                onChange={handleSelectAll}
                checked={allRequests}
              />

              <ScrollArea>
                {requests.map((request) => (
                  <div key={request.id}>
                    <Checkbox
                      onChange={handleCheckboxClick(request.id)}
                      checked={request.selected ?? false}
                      disabled={allRequests}
                    >
                      <ProfileLink
                        profile={request.profile}
                        className={css.profileName}
                      />
                    </Checkbox>
                  </div>
                ))}
              </ScrollArea>

              <div className={css.buttonsWrapper}>
                <ActionButton
                  onClick={handleRequest('approved')}
                  disabled={selectedRequests.length === 0}
                >
                  принять
                </ActionButton>

                <ImportantButton
                  onClick={handleRequest('rejected')}
                  className={css.buttonMargin}
                  disabled={selectedRequests.length === 0}
                >
                  отказать
                </ImportantButton>
              </div>
            </>
          ) : communityHasMemberModeration(settings) ? (
            <>
              Здесь вы увидите список заявкок на вступление, когда кто-то их
              подаст
            </>
          ) : (
            <>
              Здесь будет список заявкок на вступление, если включить
              премодерацию участников. А пока все заявки принимаются
              автоматически
            </>
          )}
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId }),
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(connect(mapStateToProps)(JoinRequests));
