import React from 'react';

import theme from 'styles/DybrTheme';
import { DybrPageWrapper } from 'components/DybrComponents/Wrappers';
import {
  ContentWrapperFlex,
  SectionWrapperBackground
} from 'components/DybrComponents/Section';

function ContentWrap(props) {
  return (
    <DybrPageWrapper>
      <SectionWrapperBackground
        background={theme.backgroundLighter}
        lastSection
      >
        <ContentWrapperFlex>{props.children}</ContentWrapperFlex>
      </SectionWrapperBackground>
    </DybrPageWrapper>
  );
}

export default ContentWrap;
