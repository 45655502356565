import { getCommentUrl, getEntryUrl } from 'utils/helpers/getUrl';

export function getCommonNotificationUrl(notifications, entries) {
  if (!notifications || notifications.length === 0 || !entries) {
    return '';
  }

  const oldestNotification = notifications[notifications.length - 1];
  const entry = entries[oldestNotification.entry];
  if (entry) {
    if (oldestNotification.comment) {
      return getCommentUrl(oldestNotification.comment, entry);
    }
    return getEntryUrl(entry);
  }

  return '';
}
