import styled from 'styled-components';
import BackgroundImage from 'styles/img/bg_tile.png';

export const StyledIconButton = styled.div`
  cursor: pointer;

  .icon {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 10px;
    margin-left: 10px;

    path {
      transition: all 0.4s;
    }

    .main-outline {
      fill: ${p => p.theme.backgroundDark};
    }
  }

  &:hover {
    .main-outline {
      fill: red;
    }
  }
`;

export const Designer = styled.div`
  box-shadow: 2px -2px 12px 1px ${props => props.theme.shadow};
  position: fixed;
  z-index: 1000;
  width: 100%;
  bottom: 0;
  background: ${p => p.theme.backgroundLighter};
  display: flex;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  /**** SCROLL BAR ****/
  .scroll-wrapper {
    height: 300px;

    @media (max-width: 800px) {
      height: 150px;
    }
  }

  .simplebar-scrollbar {
    width: 5px;
  }
  .horizontal.simplebar-track .simplebar-scrollbar {
    height: 5px;
  }
  .simplebar-scrollbar:before {
    background: ${p => p.theme.backgroundDark};
    border-radius: 0px;
  }
  /*********************/

  /**** COLOR PICKER ****/
  .color-picker .swatch-section {
    border: 1px solid ${p => p.theme.backgroundDark};
  }
  .color-picker .swatch-section:hover {
    border-color: ${p => p.theme.accent};
  }
  /*********************/

  .action-section {
    width: 320px;
    text-align: center;
    background: ${p => p.theme.backgroundLight};
    background-image: url(${BackgroundImage});
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 0;
    @media (max-width: 800px) {
      flex-direction: row;
      flex-basis: 100%;
      min-width: 550px;
    }

    .design-name {
      height: 55px;
      padding: 0px 15px;
      background: ${p => p.theme.backgroundLighter};
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 320px;

      input {
        width: 100%;
        text-align: center;
        border: none;
        box-shadow: none;
        background: transparent;
        font-size: 22px;
        font-weight: bold;
      }

      @media (max-width: 800px) {
        input {
          font-size: 12px;
        }
        height: 30px;
        width: 100%;
      }

      &:before {
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-left: 20px solid ${p => p.theme.backgroundLighter};
        border-bottom: 28px solid transparent;
        position: absolute;
        content: '';
        left: 320px;
        top: 0;
        z-index: 1001;

        @media (max-width: 800px) {
          display: none;
        }
      }
    }

    .action-buttons {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 800px) {
        flex-direction: row;
      }

      button {
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 25px;

        @media (max-width: 800px) {
          margin: 15px 10px 15px 10px;
        }
        :last-child {
          margin-bottom: 0px;

          @media (max-width: 800px) {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .design-section {
    min-width: 550px;
    position: relative;
    flex-grow: 1;
    background: ${p => p.theme.backgroundLight};

    .menu {
      background: ${p => p.theme.backgroundLight};
      border-bottom: 1px solid ${p => p.theme.divider};
      height: 56px;

      .active {
        color: ${p => p.theme.brandDark};
      }
    }
    .options {
      padding: 40px 40px 0px 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: flex-start;

      @media (max-width: 1800px) {
        justify-content: space-between;
      }

      .label-section {
        width: 150px;
        margin-right: 20px;
      }
      .extra-space .color-picker:first-child {
        margin-bottom: 40px;
      }
    }
  }

  &.preview-mode {
    .design-section {
      display: none;
    }
    .action-section {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 55px;

      @media (max-width: 1000px) {
        flex-basis: 100%;
        min-width: 550px;
        flex-wrap: wrap;
        height: 100px;

        .design-name {
          height: 30px;
          width: 100%;

          input {
            font-size: 12px;
          }
        }
        .design-name:before {
          display: none;
        }
      }

      .action-buttons {
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 40px;

        @media (max-width: 1000px) {
          justify-content: center;
          padding-left: 0;
        }

        button {
          margin-top: 0;
          margin-bottom: 0;

          @media (max-width: 1000px) {
            margin: 10px 10px 10px 10px;
          }
        }
      }
    }
  }
`;

export const Menu = styled.div`
  text-align: center;

  div {
    display: inline-block;
    margin: 0 10px;
    padding: 18px 20px 14px 20px;
    font-size: 16px;
    color: ${p => p.theme.textLight};
    cursor: pointer;
    font-weight: normal;

    &.active {
      border-bottom: 5px solid ${p => p.theme.brand};
      color: red;
      cursor: auto;
      font-weight: bold;
    }
  }
`;

export const Child = styled.div`
  margin-bottom: 40px;
  margin-left: 50px;
  margin-right: 50px;
  box-sizing: border-box;
  display: block;
  position: relative;

  .color-picker {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-field {
    width: auto;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .text-field span {
    display: none;
  }

  .range-wrapper .text-field input {
    width: 40px;
  }
  .range-wrapper .text-field {
    margin: 0;
    padding-top: 0;
    top: -2px;
  }
`;

export const FormElementWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  flex-shrink: 0;

  .layout-select {
    margin-bottom: 40px;
  }

  .label-section {
    position: relative;
    top: 11px;
  }

  .alignment-select input {
    min-width: 150px;
  }

  .entry-style-select input {
    min-width: 80px;
  }

  .background-select input {
    min-width: 80px;
  }

  .layout-select input {
    min-width: 180px;
  }

  .alignment-select .dybr-select__control {
    min-width: 200px;
  }

  .entry-style-select .dybr-select__control {
    min-width: 135px;
  }

  .background-select .dybr-select__control {
    min-width: 155px;
  }

  .layout-select .dybr-select__control {
    min-width: 230px;
  }
`;

export const TextFieldWithButton = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 50px 0px 50px 0px;

  .text-field {
    margin: 0px;
    padding-top: 2px;
    display: block;
  }

  button {
    position: relative;
    top: -5px;
    margin-left: 20px;
    min-width: 153px;
  }
`;

export const GrandParent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  flex-basis: 100%;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  .step-parent {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-grow: 1;
  }

  .step-parent .extra-space {
    flex: 0 0 100%;
  }

  .step-parent:first-child {
    margin-left: 50px;
    margin-right: 50px;
    min-width: 320px; /*IE fix*/
  }
  .step-parent:last-child {
    flex-basis: 100%;
    @media (max-width: 1045px) {
      justify-content: flex-start;
    }
  }

  .step-parent .extra-space {
    margin-left: 0;
    margin-right: 0;
  }

  .step-parent .empty-child {
    margin-top: 0px;
    margin-bottom: 0px;
    min-width: 230px;
    height: 0px;
  }
`;

export const SettingWrapper = styled.div`
  display: flex;
  width: 420px;
  font-size: 14px;

  .label-section {
    width: 150px;
    margin-right: 20px;
    position: relative;
    top: 11px;
  }

  .select-section {
    flex-grow: 1;

    .double-select {
      margin-top: 10px;
      display: flex;

      & > div:first-child {
        min-width: 90px;
      }

      & > div:last-child {
        flex-grow: 1;
        margin-left: 7px;
      }
    }

    .dybr-select {
      margin-bottom: 10px;
    }
  }
`;
