import { selectActiveProfile } from 'store/user/selectors/selectActiveProfile';
import { updateProfileApi } from 'api/profiles';
import { UpdateProfileAction } from 'store/user/actions/UpdateProfileAction';
import setFieldUpdeep from 'utils/helpers/setFieldUpdeep';
import { parseToken } from 'store/user/reducer/parseToken';

export const updateProfileSetting = (key, value, token) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { activeProfileId } = parseToken(token);
  const profile = selectActiveProfile(state, { activeProfileId });

  let settings = setFieldUpdeep(profile.settings ?? {}, key, value);

  const res = await updateProfileApi({
    data: { settings },
    activeProfileId: profile.id,
    token
  });

  if (!res.error) {
    dispatch(new UpdateProfileAction({ settings }, profile.id));
  } else {
    return { error: res.error };
  }
};
