import CustomListDropdown from 'scenes/User/Settings/Privacy/AccessSettings/CustomListDropdown';
import React from 'react';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import { selectActiveProfile } from 'store/user/selectors/selectActiveProfile';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { connect } from 'react-redux';
import { useTokenState } from 'store/localStorage/useTokenState';
import { AccessType } from 'utils/privacy/types/AccessType';
import {
  getCustomListReadAccess,
  getInternalAccessSettings
} from 'utils/privacy/accessCalculation';

function CustomListOption({ accessType, updateProfileSetting, settings }) {
  const [token] = useTokenState();
  const readAccess = getInternalAccessSettings(settings?.permissions?.access);

  const initialOptionsList =
    accessType === AccessType.allowCustomList
      ? readAccess?.allowCustomList
      : readAccess?.denyCustomList;

  function handleCustomListChange(options) {
    const newReadAccess = getCustomListReadAccess(accessType, options);

    updateProfileSetting('permissions.access', newReadAccess, token);
  }

  return (
    <div style={{ margin: '0 0 28px', maxWidth: '300px' }}>
      <CustomListDropdown
        onChange={handleCustomListChange}
        initialOptions={initialOptionsList}
      />
    </div>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId }),
    isCommunity: selectActiveProfile(state, { activeProfileId })?.isCommunity
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(CustomListOption)
);
