import React from 'react';
import styled from 'styled-components';
import 'froala-editor/js/froala_editor.pkgd.min';
import FroalaEditor from 'react-froala-wysiwyg';
import { API_URL } from 'configs/dybr';
import { useTokenState } from 'store/localStorage/useTokenState';
import Tribute from 'tributejs';
import $ from 'jquery';
import { selectReaderProfiles } from 'store/lists/selectors/selectReaderProfiles';
import { selectFavoriteProfiles } from 'store/lists/selectors/selectFavoriteProfiles';
import { connect } from 'react-redux';
import { getAllProfilesApi } from '../../api/profiles';

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

require('./plugins/morePlugin');
require('./plugins/quoteBracketsPlugin');
require('./plugins/offtopPlugin');

const Wrapper = styled.div`
  .fr-wrapper {
    min-height: 300px;
  }
`;

const htmlAllowedAttrs = [
  'align',
  'allowfullscreen',
  'allowtransparency',
  'alt',
  'background',
  'bgcolor',
  'border',
  'cellpadding',
  'cellspacing',
  'class',
  'color',
  'cols',
  'colspan',
  'height',
  'href',
  'rowspan',
  'src',
  'target',
  'title',
  'usemap',
  'valign',
  'width',
  'style',
  'background',
  'cover',
  'rel',
  'data-reply-link',
  'lang',
  'translate'
];

const htmlAllowedTags = [
  'a',
  'abbr',
  'audio',
  'b',
  'blockquote',
  'br',
  'code',
  'col',
  'colgroup',
  'div',
  'em',
  'embed',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hr',
  'i',
  'iframe',
  'img',
  'li',
  'ol',
  'p',
  'pre',
  's',
  'span',
  'strike',
  'strong',
  'sub',
  'sup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'u',
  'ul',
  'more'
];

const pluginsEnabled = [
  'align',
  'charCounter',
  'codeView',
  'draggable',
  'embedly',
  'emoticons',
  'fullscreen',
  'image',
  'inlineStyle',
  'lineBreaker',
  'link',
  'lists',
  'paragraphFormat',
  'paragraphStyle',
  'fileUpload',
  'quote',
  'save',
  'table',
  'url',
  'video',
  'wordPaste',
  'more',
  'quoteBrackets',
  'offtop',
  'codeBeautifier'
];
const toolbarButtons = [
  'fullscreen',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'offtop',
  'more',
  'quoteBrackets',
  'subscript',
  'superscript',
  'paragraphFormat',
  'align',
  'formatOL',
  'formatUL',
  'outdent',
  'indent',
  'quote',
  'insertLink',
  'insertImage',
  'insertVideo',
  'insertTable',
  'emoticons',
  'specialCharacters',
  'clearFormatting',
  'html'
];

const toolbarButtonsXS = [
  'fullscreen',
  'bold',
  'italic',
  'strikeThrough',
  'offtop',
  'more',
  'align',
  'quote',
  'insertLink',
  'insertImage',
  'insertVideo',
  'clearFormatting',
  'undo',
  'html'
];

const isScreenWide = window.matchMedia('(min-width: 500px)').matches;

const Editor = props => {
  const [token] = useTokenState();

  async function searchProfiles(text, cb) {
    const profiles = new Map();
    props.autocompleteMentionOptions.forEach(profile => {
      if (profile.nickname.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
        profiles.set(profile.id, profile.nickname);
      }
    });
    if (text) {
      const res = await getAllProfilesApi({
        token,
        searchTerm: text,
        searchAttribute: 'nickname',
        pageSize: 20,
        pageNumber: 1,
        searchAllBlogs: true
      });
      res.data.forEach(profile => {
        if (!profiles.get(profile.id)) {
          profiles.set(profile.id, profile.nickname);
        }
      });
    }
    cb(
      Array.from(profiles).map(([key, value]) => ({ id: key, nickname: value }))
    );
  }

  function searchTags(text, cb) {
    const tags = props.autocompleteTagsOptions.map(tag => ({
      id: tag.id,
      name: tag.name
    }));
    cb(tags);
  }

  const mentionsTribute = new Tribute({
    values: searchProfiles,
    lookup: 'nickname',
    fillAttr: 'nickname',
    allowSpaces: true,
    selectTemplate: function (item) {
      return `<a rel='author' href='/profile/${item.original.id}' class='fr-deletable fr-tribute'>@${item.original.nickname}</a>`;
    },
    noMatchTemplate: function () {
      return null;
    },
    menuItemLimit: 10,
    menuContainer: document.getElementById('tribute-container')
  });

  const tagsTribute = new Tribute({
    trigger: '#',
    values: searchTags,
    lookup: 'name',
    fillAttr: 'name',
    allowSpaces: true,
    selectTemplate: function (item) {
      return `<a rel='tag' href='/blog/${props.blogSlug}?tags=${item.original.name}' class='fr-deletable fr-tribute'>#${item.original.name}</a>`;
    },
    noMatchTemplate: function () {
      return null;
    },
    menuItemLimit: 10,
    menuContainer: document.getElementById('tribute-container')
  });

  return (
    <Wrapper data-testid="froala-editor-area">
      <FroalaEditor
        tag="textarea"
        model={props.value}
        onModelChange={props.onChange}
        config={{
          key: 'gB2D2B1C2xC4B3B3E4B5A1E1E4F1C3sthpaB1gc==', // we don't care it's here, key is working only for our domain
          events: {
            'froalaEditor.initialized': (e, editor) => {
              props.onInit(editor);
              // editor.myPlugin.publicMethod();

              //@ autocomplete plugin
              if (
                props.autocompleteMentionOptions &&
                props.autocompleteMentionOptions.length
              ) {
                mentionsTribute.attach(editor.el);
                editor.events.on(
                  'keydown',
                  function (e) {
                    if (
                      e.which === $.FroalaEditor.KEYCODE.ENTER &&
                      mentionsTribute.isActive
                    ) {
                      return false;
                    }
                  },
                  true
                );
              }

              //# autocomplete plugin
              if (
                props.autocompleteTagsOptions &&
                props.autocompleteTagsOptions.length &&
                props.blogSlug
              ) {
                tagsTribute.attach(editor.el);
                editor.events.on(
                  'keydown',
                  function (e) {
                    if (
                      e.which === $.FroalaEditor.KEYCODE.ENTER &&
                      tagsTribute.isActive
                    ) {
                      return false;
                    }
                  },
                  true
                );
              }
            },
            'froalaEditor.keydown': (e, editor, key) => {
              if ((key.metaKey || key.ctrlKey) && key.keyCode === 13) {
                editor.cursor.backspace();
                props.onSubmitByEnter();
              } else if (key.keyCode === 13) {
                // this is here to trigger auto scroll to the cursor position when enter is pressed
                var event = window.$.Event('keydown');
                event.which = 65; // Character 'A' which does not appear for some reason
                window.$('.fr-element.fr-view').trigger(event);
              }
            },
            'froalaEditor.blur': (e, editor) => {
              // does not work on some phones, I am abandoning the idea for now
              //if (editor.fullscreen.isActive()) editor.fullscreen.toggle();
            },
            'froalaEditor.focus': (e, editor) => {
              if (
                window.innerHeight < 700 &&
                window.innerWidth < 700 &&
                editor.fullscreen.isActive()
              ) {
                // I had the editor going to fullscreen automatically, but people said it is unintuitive
                //editor.fullscreen.toggle();

                const toolbarHeight = window.$('.fr-toolbar').outerHeight();
                const editorHeight = window.innerHeight - toolbarHeight;

                window
                  .$('.fr-wrapper')
                  .attr(
                    'style',
                    `max-height: ${editorHeight}px; min-height: ${editorHeight}px`
                  );
                window
                  .$('.fr-element.fr-view')
                  .attr(
                    'style',
                    `max-height: ${editorHeight}px; min-height: ${editorHeight}px`
                  );
                editor.events.focus(true);
              }
            },
            'froalaEditor.image.error': (e, editor, error) => {
              console.log(error);
              // 1 - Bad link.
              // 2 - No link in upload response.
              // 3 - Error during image upload.
              // 4 - Parsing response failed.
              // 5 - Image too text-large.
              // 6 - Invalid image type.
              // 7 - Image can be uploaded only to same domain in IE 8 and IE 9.
              if ([1, 2, 4, 7].includes(error.code)) {
                alert(
                  'Упс, что-то пошло не так в процессе загрузки. Напишите нам об этом'
                );
              }
              if (error.code === 3) {
                alert(
                  'Упс, что-то пошло не так в процессе загрузки. Попробуйте ещё раз'
                );
              }
              if (error.code === 5) {
                alert(
                  'He удалось загрузить картинку. Проверьте, что размер картинки не превышает 5Мб'
                );
              } else if (error.code === 6) {
                alert(
                  'He удалось загрузить картинку. Формат файла не поддерживается'
                );
              }
            }
          },
          toolbarSticky: isScreenWide,
          requestHeaders: {
            authorization: `Bearer ${token}`
          },
          placeholderText: `${props.placeholder ?? ''}`,
          codeViewKeepActiveButtons: ['fullscreen'],
          fileUpload: false,
          fontSizeSelection: false,
          fontFamilySelection: false,
          heightMin: 400,
          heightMax: 700,
          //heightMin: Math.min(400, window.innerHeight) - 129,
          //heightMax: Math.min(1000, window.innerHeight - 129),
          htmlAllowedAttrs,
          htmlAllowedTags,
          htmlExecuteScripts: true,
          pluginsEnabled,
          toolbarButtons,
          toolbarButtonsXS,
          toolbarStickyOffset: 0, //window.innerHeight > 600 ? 50 : 0,
          imagePaste: true,
          imageUpload: true,
          linkInsertButtons: [],
          //          videoAllowedProviders: ['youtube', 'vimeo'],
          videoInsertButtons: ['videoByURL'],
          videoTextNear: false,
          videoEditButtons: ['videoDisplay', 'videoRemove', 'videoSize'],
          emoticonsUseImage: false,
          emoticonsStep: 8,
          imageEditButtons: [
            'imageReplace',
            'imageRemove',
            'imageLink',
            'linkOpen',
            'linkEdit',
            'linkRemove',
            'imageAlign',
            'imageDisplay',
            'imageAlt',
            'imageSize',
            'imageCaption'
          ],
          language: 'ru',
          imageUploadRemoteUrls: false,
          imageUploadURL: API_URL + '/image-upload',
          imageUploadParam: 'file',
          imageUploadMethod: 'POST',
          imageMaxSize: 5 * 1024 * 1024, // 5MB
          enter: 2, // BR
          htmlUntouched: true,
          codeBeautifierOptions: {
            //end_with_newline: true,
            indent_inner_html: true,
            extra_liners:
              "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
            brace_style: 'expand',
            indent_char: ' ',
            indent_size: 2,
            wrap_line_length: 0
          }
        }}
      />
    </Wrapper>
  );
};
const mapStateToProps = state => ({
  readers: selectReaderProfiles(state),
  favorites: selectFavoriteProfiles(state)
});

export default connect(mapStateToProps)(Editor);
