import queryString from 'query-string';
import moment from 'moment';

export function parseSearchParams(search) {
  const parsed = queryString.parse(search);
  const validatedTags = []
    .concat(parsed.tag)
    .concat(parsed.tags) //Support old 'tags' parameter when selecting blog tag
    .filter(tag => tag);
  const res = {
    tags: validatedTags ?? [],
    tagsGrouped: parsed.tagsGrouped === 'true',
    content: parsed.content ?? '',
    hideOwned: parsed.hideOwned === 'true',
    showBanned: parsed.showBanned === 'true',
    showHidden: parsed.showHidden === 'true',
    startDate: parsed.startDate ? moment(parsed.startDate).toDate() : null,
    endDate: parsed.endDate
      ? moment(parsed.endDate).add(23, 'hours').add(59, 'minutes').toDate()
      : null
  };
  return res;
}

export function formSearchParams({
  content,
  tags,
  tagsGrouped,
  startDate,
  endDate,
  hideOwned,
  showBanned,
  showHidden
}) {
  const searchParams = new URLSearchParams();
  showBanned && searchParams.append('showBanned', 'true');
  showHidden && searchParams.append('showHidden', 'true');
  hideOwned && searchParams.append('hideOwned', 'true');
  content && searchParams.append('content', content);
  startDate &&
    searchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
  endDate &&
    searchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
  tags.forEach(tag => searchParams.append('tag', tag));
  if (tags && tags.length > 1 && tagsGrouped) {
    searchParams.append('tagsGrouped', tagsGrouped);
  }
  return searchParams.toString();
}
