import { Router } from '@reach/router';
import React from 'react';
import { CommonFeedContainer } from 'scenes/Dybr/Feeds/CommonFeed';
import { ServiceBackground } from 'components/DybrComponents/ServiceBackground';
import { HeaderGeneral } from 'components/DybrComponents/HeaderGeneral';

function SearchScene() {
  return (
    <ServiceBackground data-testid="search-scene">
      <HeaderGeneral paddingBottom="45px">
        <h1>результаты поиска</h1>
      </HeaderGeneral>

      <Router>
        <CommonFeedContainer path="/" isFiltered />
      </Router>
    </ServiceBackground>
  );
}

export default SearchScene;
