import React, { useState } from 'react';
import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { connect } from 'react-redux';
import RadioGroup from '../../../../components/FormElements/RadioGroup';
import { MailingOptions } from '../../../../utils/privacy/MailingOptions';
import { useTokenState } from '../../../../store/localStorage/useTokenState';

const options = [
  {
    label: 'как заголовки',
    value: MailingOptions.title
  },
  {
    label: 'полностью',
    value: MailingOptions.full
  }
].map((option, index) => ({ ...option, radioValue: option.value }));

function MailingSettings({ settings, updateProfileSetting }) {
  const [token] = useTokenState();
  const [checkedOption, setCheckedOption] = useState(
    settings?.notifyRestrictions?.forbidEmail
      ? MailingOptions.title
      : MailingOptions.full
  );

  const checkOption = (newMailingOption, id) => {
    updateProfileSetting(
      'notifyRestrictions.forbidEmail',
      newMailingOption === MailingOptions.title,
      token
    );
    setCheckedOption(id);
  };

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Рассылка ваших записей</h4>
          <p>
            Пользователи могут получать ваши новые посты по почте, но вы можете
            решить, как именно это будет происходить
          </p>
        </SettingSectionTitle>
        <Settings>
          <RadioGroup
            title="ваши записи рассылаются"
            name="mailingOptions"
            checked={checkedOption}
            onCheck={checkOption}
            items={options}
          />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(MailingSettings)
);
