import React from 'react';

import Pagination from 'components/BlogComponents/BlogPagination';

export default function PaginationWrapper({
  page,
  totalRecords,
  pageSize,
  url,
  children,
  scrollToRef
}) {
  return (
    <>
      <Pagination
        classModifier="top"
        totalRecords={totalRecords}
        currentPage={page}
        onChange={() => scrollToRef?.current?.scrollIntoView()}
        pageSize={pageSize}
        url={url}
        data-testid={`blog-entry-pagination-top`}
      />
      {children}
      <Pagination
        classModifier="bottom"
        totalRecords={totalRecords}
        currentPage={page}
        onChange={() => scrollToRef?.current?.scrollIntoView()}
        pageSize={pageSize}
        url={url}
        data-testid={`blog-entry-pagination-bottom`}
      />
    </>
  );
}
