import React from 'react';

export const QuickAccessIcon = ({ className }) => (
  <svg
    className={`icon icon-fast-forward-quick-access ${className}`}
    viewBox="0 0 480 447.99442"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="arrows-fill filling-area"
      d="M 474.08,211.57842 218.08,3.5784343 c -4.8,-3.90400003 -11.456,-4.672 -16.96,-2.016 -5.568,2.624 -9.12,8.256 -9.12,14.4319997 V 138.36242 L 26.08,3.5784343 c -4.8,-3.90400003 -11.424,-4.672 -16.96,-2.016 C 3.552,4.1864343 0,9.8184343 0,15.994434 V 431.99442 c 0,6.176 3.552,11.808 9.12,14.464 2.208,1.024 4.544,1.536 6.88,1.536 3.584,0 7.2,-1.216 10.08,-3.584 L 192,309.62642 v 122.368 c 0,6.176 3.552,11.808 9.12,14.464 2.208,1.024 4.544,1.536 6.88,1.536 3.584,0 7.2,-1.216 10.08,-3.584 l 256,-208 c 3.776,-3.04 5.92,-7.616 5.92,-12.416 0,-4.8 -2.144,-9.376 -5.92,-12.416 z"
    />
    <path
      className="arrows-outline main-outline"
      d="M 474.08,211.57842 218.08,3.5784243 c -4.8,-3.90400003 -11.456,-4.672 -16.96,-2.016 -5.568,2.624 -9.12,8.256 -9.12,14.4319997 V 138.36242 L 26.08,3.5784243 c -4.8,-3.90400003 -11.424,-4.672 -16.96,-2.016 C 3.552,4.1864243 0,9.8184243 0,15.994424 V 431.99442 c 0,6.176 3.552,11.808 9.12,14.464 2.208,1.024 4.544,1.536 6.88,1.536 3.584,0 7.2,-1.216 10.08,-3.584 L 192,309.62642 v 122.368 c 0,6.176 3.552,11.808 9.12,14.464 2.208,1.024 4.544,1.536 6.88,1.536 3.584,0 7.2,-1.216 10.08,-3.584 l 256,-208 c 3.776,-3.04 5.92,-7.616 5.92,-12.416 0,-4.8 -2.144,-9.376 -5.92,-12.416 z m -277.024,52.704 c -0.128,0.128 -0.32,0.224 -0.448,0.352 L 32,398.39442 V 49.594424 L 246.624,223.99442 Z M 224,398.39442 v -114.784 l 58.08,-47.2 c 3.776,-3.04 5.92,-7.616 5.92,-12.416 0,-4.8 -2.144,-9.376 -5.92,-12.416 l -58.08,-47.2 V 49.594424 L 438.624,223.99442 Z"
    />
  </svg>
);
