import React, { useState } from 'react';
import TextField from 'components/FormElements/TextField';
import css from 'scenes/Blog/designer/components/NumberInRangeSetting.module.css';

function NumberInRangeSetting({
  value,
  onChange,
  min,
  max,
  units = '',
  label = ''
}) {
  const [intValue, setIntValue] = useState(parseInt(value) ?? 0);

  const validateAndSave = v => {
    let value = intValue;
    if (isNaN(value)) {
      value = 0;
    }
    value = Math.max(min, value);
    value = Math.min(max, value);

    onChange(value);
  };

  // TODO range-wrapper should be overriden in src/scenes/Blog/designer/components/styled.js
  // because of that we need this classname here.
  // all that is needed because TextField has its own margin setting and doesn't allow
  // to override it from the external code :(
  return (
    <div className={`range-wrapper ${css.rangeWrapper}`}>
      <div className={css.label}>{label}</div>
      <TextField
        value={intValue}
        onChange={e => setIntValue(parseInt(e.target.value) ?? 0)}
        onBlur={validateAndSave}
      />
      <div className={css.units}>{units}</div>
    </div>
  );
}

export default NumberInRangeSetting;
