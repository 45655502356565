import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-tag ${className}`}
    viewBox="0 0 6.38483 6.3463096"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="tag main-outline"
      d="M 5.6511522,0 H 3.6702229 C 3.4721299,0 3.2887109,0.0734 3.1566489,0.21277 L 0.2146008,3.1401398 c -0.286134,0.28614 -0.286134,0.7483499 0,1.0344899 l 1.9662561,1.95892 c 0.139399,0.1394002 0.322819,0.2127602 0.5209121,0.2127602 0.198092,0 0.381512,-0.0734 0.5209109,-0.2127602 l 2.9420463,-2.94205 C 6.3041252,3.0594398 6.3848302,2.87602 6.3848302,2.68526 V 0.73368 C 6.3848302,0.33016 6.0546752,0 5.6511522,0 Z"
    />
    <path
      className="tag-hole"
      d="m 4.7707395,0.87308 c -0.403523,0 -0.733678,0.33015 -0.733678,0.73367 0,0.40353 0.330155,0.73368 0.733678,0.73368 0.403523,0 0.733677,-0.33015 0.733677,-0.73368 0,-0.40352 -0.330154,-0.73367 -0.733677,-0.73367 z"
    />
  </svg>
);

export default Icon;
