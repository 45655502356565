import React from 'react';
import { Link } from '@reach/router';
import AvatarWithMenu from 'components/Shared/AvatarWithMenu';
import { BirthdayIcon } from 'scenes/Dybr/Users/BirthdayIcon';

function BlogHeader({ path: subpage, profile }) {
  const { id, nickname, blogSlug, blogTitle, birthday } = profile;

  switch (subpage) {
    case 'bookmarks':
    case 'favorites':
    case 'communities':
      return (
        <>
          <div className="blog-header--thin">
            <AvatarWithMenu className="blog-header-avatar" profile={profile} />

            <div className="blog-header-profile">
              <BirthdayIcon birthday={birthday} nick={nickname} />
              <Link to={`/profile/${id}`}>{nickname}</Link>
            </div>
          </div>
          <div className="blog-header-tabs">
            <div className="blog-header-tabs-position">
              <Link
                className={
                  subpage === 'favorites' ? 'blog-header-tabs--active' : ''
                }
                to={`/blog/${blogSlug}/favorites`}
                data-testid="blog-my-favorites-link"
              >
                избранное
              </Link>
              <Link
                className={
                  subpage === 'bookmarks' ? 'blog-header-tabs--active' : ''
                }
                to={`/blog/${blogSlug}/bookmarks`}
                data-testid="blog-my-bookmarks-link"
              >
                закладки
              </Link>
              <Link
                className={
                  subpage === 'communities' ? 'blog-header-tabs--active' : ''
                }
                to={`/blog/${blogSlug}/communities`}
              >
                мои сообщества
              </Link>
            </div>
          </div>
        </>
      );
    default:
      return (
        <div className="blog-header--main">
          <div className="blog-header-title">
            <h1>
              <Link to={`/blog/${blogSlug}`}>{blogTitle}</Link>
            </h1>
          </div>
          <div className="blog-header-profile">
            <BirthdayIcon birthday={birthday} nick={nickname} />
            <h4>
              <Link
                to={`/profile/${id}`}
                data-testid="blog-to-user-profile-link"
              >
                {nickname}
              </Link>
            </h4>
          </div>
        </div>
      );
  }
}

export default BlogHeader;
