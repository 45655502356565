import React from 'react';

function InformationIcon() {
  return (
    <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M43.2442 8.73178C33.7512 -0.761279 18.304 -0.762216 8.81002 8.73178C-0.683976 18.2258 -0.68304 33.672 8.81002 43.166C18.3031 52.66 33.7502 52.66 43.2442 43.166C52.7373 33.672 52.7373 18.2248 43.2442 8.73178V8.73178Z"
          stroke="currentColor"
          strokeWidth="2.99672"
        />
        <path
          d="M27.9953 37.1008H24.3821V15.968H27.9953V37.1008ZM24.0891 10.3625C24.0891 9.77658 24.2649 9.28179 24.6164 8.87814C24.981 8.4745 25.5149 8.27267 26.218 8.27267C26.9211 8.27267 27.455 8.4745 27.8196 8.87814C28.1841 9.28179 28.3664 9.77658 28.3664 10.3625C28.3664 10.9485 28.1841 11.4367 27.8196 11.8274C27.455 12.218 26.9211 12.4133 26.218 12.4133C25.5149 12.4133 24.981 12.218 24.6164 11.8274C24.2649 11.4367 24.0891 10.9485 24.0891 10.3625Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default InformationIcon;
