import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { unregister } from './registerServiceWorker';
import 'index.css';
import 'components/Froala/plugins/tribute.css';
import 'components/Froala/plugins/hide_license_warning.css';

import 'froala-editor/js/froala_editor.pkgd.min';
import 'configs/translations/froala/ru.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import $ from 'jquery';

import axios from 'axios';

import theme from 'styles/DybrTheme';
import { Provider } from 'unstated';
import { Provider as ReduxProvider } from 'react-redux';
import { store as reduxStore } from 'store';

import ErrorBoundary from 'components/Helpers/ErrorBoundary';

import { API_URL } from 'configs/dybr';

import moment from 'moment';
import 'moment/locale/ru';
import { AppContainer } from 'App';
import { StateMonitorContainer } from 'StateMonitor';
import PrivacyListProvider from 'scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';

moment.locale('ru');

unregister(); // disable service workers

window.$ = $;

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/vnd.api+json';
axios.defaults.headers.patch['Content-Type'] = 'application/vnd.api+json';
axios.defaults.timeout = 1000 * 60 * 5;

render(
  <ReduxProvider store={reduxStore}>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Provider>
          <StateMonitorContainer>
            <PrivacyListProvider>
              <AppContainer />
            </PrivacyListProvider>
          </StateMonitorContainer>
          <div id="modal-root" />
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </ReduxProvider>,
  document.querySelector('#root')
);
