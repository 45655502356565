import React, { Fragment } from 'react';
import {
  Child,
  FormElementWrapper
} from 'scenes/Blog/designer/components/styled';
import NumberInRangeSetting from 'scenes/Blog/designer/components/NumberInRangeSetting';
import SelectAlignSetting from 'scenes/Blog/designer/components/AlignSetting';

export const LayoutSettingsTab = ({ design, changeSetting }) => (
  <Fragment>
    <Child>
      <NumberInRangeSetting
        min={600}
        max={1400}
        value={design.layout.maxWidth}
        label="ширина дневника"
        units="px"
        onChange={changeSetting('layout.maxWidth')}
      />
    </Child>

    <Child>
      <FormElementWrapper>
        <div className="label-section" style={{ position: 'static' }}>
          выравнивание дневника
        </div>
        <SelectAlignSetting
          setting={design.layout.align}
          update={changeSetting}
          nodeKey={'layout.align'}
        />
      </FormElementWrapper>
    </Child>
    {/*<Child>
        <FormElementWrapper>
          <div className="label-section">макет страницы</div>
          <Select
            options={[
              { label: '1 колонка', value: 'l' },
              { label: '2 колонки - меню справа', value: '2' },
              { label: '2 колонки - меню слева', value: '3' },
            ]}
            className="layout-select"
            isSearchable={false}
            captureMenuScroll={true}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </FormElementWrapper>
        <FormElementWrapper>
          <div className="label-section">записи показаны</div>
          <Select
            options={[
              { label: 'блоками', value: 'l' },
              { label: 'лентой', value: '2' },
            ]}
            className="entry-style-select"
            isSearchable={false}
            captureMenuScroll={true}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </FormElementWrapper>
          </Child>*/}
    {/*<Child>
        <FormElementWrapper>
          <Switch label="показывать эпиграф" checked={true} />
        </FormElementWrapper>
      </Child>*/}
  </Fragment>
);
