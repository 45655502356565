import React, { Fragment, useState } from 'react';
import {
  Child,
  FormElementWrapper,
  GrandParent,
  TextFieldWithButton
} from 'scenes/Blog/designer/components/styled';
import ColorSetting from 'scenes/Blog/designer/components/ColorSetting';
import Switch from 'components/FormElements/Switch';
import TextField from 'components/FormElements/TextField';
import DropzoneImage from 'scenes/Blog/designer/DropzoneImage';
import SelectBackgroundDisplay from 'scenes/Blog/designer/components/BackgroundDisplaySetting';

export const ColorSettingsTab = ({
  design,
  changeSetting,
  toggleSetting,
  handleDrop
}) => {
  const [backgroundImageLink, setBackgroundImageLink] = useState(
    design.background.url
  );

  const updateBackgroundImageLink = (e) => {
    if (backgroundImageLink !== design.background.url) {
      changeSetting('background.url')(e);
    }
  };

  return (
    <GrandParent>
      <div className="step-parent">
        <Child className="extra-space">
          <ColorSetting
            label="фон дневника"
            value={design.colors.background}
            onChange={changeSetting('colors.background')}
          />
          <FormElementWrapper>
            <Switch
              label="использовать фоновое изображение"
              checked={design.background && design.background.enable}
              onClick={toggleSetting('background.enable')}
            />
          </FormElementWrapper>
          {design.background && design.background.enable && (
            <Fragment>
              <TextFieldWithButton>
                <TextField
                  label="ссылка"
                  isRequired
                  value={backgroundImageLink}
                  touched={false}
                  onChange={(e) => {
                    setBackgroundImageLink(e.target.value);
                  }}
                  onBlur={updateBackgroundImageLink}
                />

                <DropzoneImage
                  maxSize={1.5 * 1024 * 1024}
                  onAccepted={handleDrop('background.url')}
                />
              </TextFieldWithButton>

              <FormElementWrapper>
                <div className="label-section">отображение фона</div>
                <SelectBackgroundDisplay
                  setting={design.background}
                  update={changeSetting}
                  nodeKey={'background'}
                  types={'backgroundImageDisplayTypes'}
                />
              </FormElementWrapper>
            </Fragment>
          )}
        </Child>
      </div>
      <div className="step-parent">
        <Child>
          <ColorSetting
            label="фон блоков"
            value={design.colors.blocks}
            onChange={changeSetting('colors.blocks')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="границы"
            value={design.colors.borders}
            onChange={changeSetting('colors.borders')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="разделители"
            value={design.colors.dividers}
            onChange={changeSetting('colors.dividers')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="заголовки"
            value={design.colors.headers}
            onChange={changeSetting('colors.headers')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="текст"
            value={design.colors.text}
            onChange={changeSetting('colors.text')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="ссылки"
            value={design.colors.links}
            onChange={changeSetting('colors.links')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="оффтоп"
            value={design.colors.offtop}
            onChange={changeSetting('colors.offtop')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="мета"
            value={design.colors.meta}
            onChange={changeSetting('colors.meta')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="фон служебных блоков"
            value={design.colors.elementsBack}
            onChange={changeSetting('colors.elementsBack')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="текст служебных блоков"
            value={design.colors.elements}
            onChange={changeSetting('colors.elements')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="акцент"
            value={design.colors.accent}
            onChange={changeSetting('colors.accent')}
          />
        </Child>
        <Child>
          <ColorSetting
            label="кнопки в формах"
            value={design.colors.buttons}
            onChange={changeSetting('colors.buttons')}
          />
        </Child>

        <Child className="empty-child" />
        <Child className="empty-child" />
        <Child className="empty-child" />
        <Child className="empty-child" />
      </div>
    </GrandParent>
  );
};
