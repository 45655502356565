import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  createCustomProfileListApi,
  getCustomListsApi,
  removeCustomListApi,
  updateCustomListAPI
} from 'api/lists';
import {
  isActiveProfileValid,
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';

const PrivacyListContext = React.createContext();
PrivacyListContext.displayName = 'PrivacyListContext';

export function usePrivacyListContext() {
  const context = useContext(PrivacyListContext);

  if (context === undefined) {
    throw new Error(
      `usePrivacyListContext must be used within a PrivacyListProvider`
    );
  }
  return context;
}

const compareNames = (a, b) => (a.name > b.name ? 1 : -1);

function PrivacyListProvider({ children }) {
  const [lists, setLists] = useState([]);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();
  const menuPortalRef = useRef();

  useEffect(() => {
    async function fetchLists() {
      if (token && isActiveProfileValid(activeProfileId)) {
        const res = await getCustomListsApi({ token });
        if (res.data) {
          const customLists = res.data.filter(
            (item) => item.action === 'custom'
          );
          setLists([...(customLists ?? [])].sort(compareNames));
        }
      }
    }
    fetchLists();
  }, [token]);

  const handleRemove = async (itemToRemove) => {
    const deleteConfirmed = window.confirm(
      `Вы уверены, что хотите удалить список "${itemToRemove.name}"?`
    );

    if (deleteConfirmed) {
      const res = await removeCustomListApi({ listId: itemToRemove.id, token });

      if (!res.error) {
        setLists((currentLists) =>
          currentLists.filter((l) => l.id !== itemToRemove.id)
        );
      }
    }
  };

  const handleAddList = async (listName, profileIds) => {
    const response = await createCustomProfileListApi({
      profileIds,
      listName,
      token
    });
    if (response.data) {
      setLists((currentLists) =>
        [...currentLists, response.data].sort(compareNames)
      );
    }
    return response;
  };

  function updateListAfterProfileChange(list, data) {
    if (data) {
      setLists((currentLists) =>
        currentLists.map((l) =>
          l.id === list.id
            ? {
                ...l,
                profiles: data.profiles
              }
            : l
        )
      );
    }
  }
  const handleAddUsers = async (list, profileIds) => {
    const res = await updateCustomListAPI({
      list,
      // keep only unique IDs:
      profileIds: Array.from(
        new Set([...(list.profiles?.map((p) => p.id) ?? []), ...profileIds])
      ),
      token
    });
    updateListAfterProfileChange(list, res.data);
  };

  const handleRemoveUser = async (list, profileIdToRemove) => {
    const res = await updateCustomListAPI({
      list,
      profileIds: list.profiles
        .filter(({ id }) => id !== profileIdToRemove)
        ?.map((p) => p.id),
      token
    });
    updateListAfterProfileChange(list, res.data);
  };

  const value = {
    lists,
    handleRemove,
    handleAddList,
    handleAddUsers,
    handleRemoveUser,
    menuPortalRef
  };

  return (
    <div ref={menuPortalRef}>
      <PrivacyListContext.Provider value={value}>
        {children}
      </PrivacyListContext.Provider>
    </div>
  );
}

export default PrivacyListProvider;
