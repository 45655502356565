import React, { useEffect } from 'react';
import ContentWrap from 'components/DybrComponents/ContentWrap';

import {
  SectionNarrowBlock,
  SectionTitle
} from 'components/DybrComponents/Section';

const Contacts = () => {
  useEffect(() => {
    document.title = `Контакты - Дыбр`;
  }, []);

  return (
    <ContentWrap>
      <SectionTitle data-testid="contacts">Контакты</SectionTitle>
      <SectionNarrowBlock size="600px">
        <p>
          Если вы нашли баг или какую-нибудь проблему с сайтом, сообщить об этом
          можно{' '}
          <a
            href="https://gitlab.com/groups/dybr-ru/-/issues"
            target="_blank"
            rel="noopener noreferrer"
          >
            здесь
          </a>
          .
        </p>
        <p>
          Почта: <a href="mailto:support@dybr.ru">support@dybr.ru</a>.
        </p>
      </SectionNarrowBlock>
    </ContentWrap>
  );
};

export default Contacts;
