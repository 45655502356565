import React from 'react';
import { Link } from '@reach/router';

const currentState = {
  key: 'current',
  title: 'Текущее состояние проекта',
  content: (
    <>
      <p>
        Дыбр открыл свои двери для всех желающих вести блоги о чем угодно. Он
        продолжает расти и развиваться в своём неспешном темпе, постепенно
        обрастая запланированными полезными функциями.
      </p>
      <p>
        Больше о планах, и о том, над чем мы сейчас работаем, можно узнать в
        нашем{' '}
        <a
          href="https://gitlab.com/groups/dybr-ru/-/boards"
          target="_blank"
          rel="noopener noreferrer"
        >
          гитлабе
        </a>
        . В <Link to="/blog/dybr">Дыброновостях</Link> периодически появляется
        подробная информация о последних изменениях и текущем прогрессе.
      </p>
    </>
  )
};
const bugs = {
  key: 'bugs',
  title: 'Баги и помощь',
  content: (
    <p>
      Если вы нашли баг или какую-нибудь проблему с сайтом, пожалуйста, сообщите
      нам об этом, сделав <Link to="/errorlog">отчет об ошибке</Link> (ничего
      сложного, достаточно описать в двух словах проблему и нажать на кнопку
      “отправить”) или добавив подробное описание ишью в наш{' '}
      <a
        href="https://gitlab.com/groups/dybr-ru/-/issues"
        target="_blank"
        rel="noopener noreferrer"
      >
        баг-трекер
      </a>{' '}
      (необходима регистрация). Если вам нужна помощь, напишите нам на почту{' '}
      <a href="mailto:support@dybr.ru">support@dybr.ru</a>. Также обо всех
      проблемах с Дыбром можно сообщать под любой записью в{' '}
      <Link to="/blog/dybr">Дыброновостях</Link>.
    </p>
  )
};

const support = {
  key: 'support',
  title: 'Помощь проекту',
  content: (
    <>
      <p>
        Дыбр &mdash; некоммерческий проект без рекламы, работа которого
        поддерживается заинтересованными пользователями. Вы можете
        присоединиться, став нашим подписчиком на{' '}
        <a
          href="https://www.patreon.com/bePatron?u=8122465"
          target="_blank"
          rel="noopener noreferrer"
        >
          Патреоне
        </a>
        .
      </p>
      <p>
        Мы всегда рады комментариям и предложениям по функционалу. Вы можете
        оставлять их в нашем{' '}
        <a
          href="https://gitlab.com/groups/dybr-ru/-/issues"
          target="_blank"
          rel="noopener noreferrer"
        >
          баг-трекере
        </a>{' '}
        или в комментариях к новостям в{' '}
        <Link to="/blog/dybr">официальном блоге Дыбра</Link>.
      </p>
    </>
  )
};

const androidApp = {
  key: 'android',
  title: 'Приложение под Android',
  content: (
    <p>
      К сожалению, приложение устарело и больше не поддерживается нашим Android
      разработчиком. Мы не можем разместить его в Google Store, так как
      требуется обновление под новые версии. Приложение будет работать на уже
      установленных устройствах, но мы прекращаем его поддержку и рекомендуем
      использовать сайт.
    </p>
  )
};

export const loggedOutInfo = [currentState, androidApp, bugs];

export const loggedInInfo = [support, currentState, androidApp, bugs];
