import React from 'react';
import { Redirect, Router } from '@reach/router';

import Footer from 'scenes/Footer/Footer';
import { CommonSettingsContainer } from './Settings/SettingsCommon';
import ProfilePrivacySettings from 'scenes/User/Settings/PrivacySettings';
import { BlogSettingsScene } from 'scenes/User/Settings/Blog/SettingsBlog';
import ProfileSettings from 'scenes/User/Settings/Blog/ProfileSettings/ProfileSettings';
import { ServiceBackground } from 'components/DybrComponents/ServiceBackground';
import { useTokenState } from 'store/localStorage/useTokenState';
import { HeaderGeneral } from 'components/DybrComponents/HeaderGeneral';
import TabsContainer from 'components/DybrComponents/TabsContainer';
import { NavTab } from 'components/DybrComponents/NavTab';
import { AccountSettingsContainer } from './Settings/AccountSettings';

function SettingsPage() {
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  // we do this instead of routing because in case of logout from these pages it is better if user
  // does not see 404 page
  if (!isLoggedIn) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <ServiceBackground data-testid="settings">
      <HeaderGeneral paddingBottom="45px">
        <h1>настройки</h1>
        <TabsContainer>
          <NavTab to="common" data-testid="tab-common">
            общие
          </NavTab>
          <NavTab to="account" data-testid="tab-account">
            аккаунт
          </NavTab>
          <NavTab to="profile" data-testid="tab-profile">
            профиль
          </NavTab>
          <NavTab to="privacy" data-testid="tab-privacy">
            приватность
          </NavTab>
          <NavTab to="blog" data-testid="tab-blog">
            блог
          </NavTab>
        </TabsContainer>
      </HeaderGeneral>

      <Router>
        <Redirect from="/" to="common" noThrow />
        <CommonSettingsContainer path="common" />
        <AccountSettingsContainer path="account" />
        <ProfileSettings path="profile" />
        <ProfilePrivacySettings path="privacy" />
        <BlogSettingsScene path="blog" />
      </Router>

      <Footer withLemur />
    </ServiceBackground>
  );
}

export default SettingsPage;
