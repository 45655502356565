import { AccessType } from 'utils/privacy/types/AccessType';

export function getInternalAccessSettings(readAccess) {
  const internalSettings = {
    accessType: undefined,
    denyCustomList: [],
    allowCustomList: []
  };
  if (!readAccess?.length) {
    return internalSettings;
  }
  for (const access of readAccess) {
    if (!access?.type) {
      internalSettings.accessType = null;
      continue;
    }
    if (access.type === 'list') {
      access.deny
        ? internalSettings.denyCustomList.push(access.id)
        : internalSettings.allowCustomList.push(access.id);
    } else {
      internalSettings.accessType = access.type;
    }
  }
  return internalSettings;
}

export function getCurrentAccessType(settings) {
  const readAccess = settings?.permissions?.access;
  const internalSettings = getInternalAccessSettings(readAccess);

  if (internalSettings.denyCustomList?.length) {
    return AccessType.denyCustomList;
  } else if (
    internalSettings.allowCustomList?.length &&
    internalSettings.accessType === AccessType.private
  ) {
    return AccessType.allowCustomList;
  }
  return internalSettings.accessType;
}

export function calculateReadAccessSettings(selectedAccess, settings) {
  const internalSettings = getInternalAccessSettings(
    settings?.permissions?.access
  );
  switch (selectedAccess?.type) {
    case AccessType.allowCustomList:
      return [
        { type: AccessType.private },
        ...(internalSettings.allowCustomList?.map((id) => ({
          type: 'list',
          id
        })) ?? [])
      ];
    case AccessType.denyCustomList:
      return [
        { type: AccessType.registered },
        ...(internalSettings.denyCustomList?.map((id) => ({
          type: 'list',
          id,
          deny: true
        })) ?? [])
      ];
    default:
      return selectedAccess ? [selectedAccess] : [];
  }
}

export function getCustomListReadAccess(accessType, options) {
  return accessType === AccessType.allowCustomList
    ? [
        { type: AccessType.private },
        ...options.map((option) => ({
          type: 'list',
          id: option.value // `option.value` is an ID of the custom list
        }))
      ]
    : [
        { type: AccessType.registered },
        ...options.map((option) => ({
          type: 'list',
          id: option.value, // `option.value` is an ID of the custom list
          deny: true
        }))
      ];
}
