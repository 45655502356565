import styled from 'styled-components';

// top wrapper for dybr pages. Has common styles for content such as headers, text, lists.
// it has the basic background color.
// it is not doing anything layout related and always is 100% width;
export const DybrPageWrapper = styled.div`
  /* padding-top: ${props => (props.main ? '0' : '46px')}; */
  background: ${props => props.background || props.theme.backgroundLighter};
  overflow: hidden;
  overflow-wrap: normal;

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.57142857; /*22*/

  h1,
  h2,
  h3,
  h4 {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  h2 {
    color: ${props => props.theme.text};
    line-height: 1;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 28px;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  h2.large-margin {
    margin-bottom: 50px;

    @media (max-width: 800px) {
      margin-bottom: 24px;
    }
  }

  h3 {
    text-transform: lowercase;
    font-size: 24px;
  }

  p {
    margin: 0.5em 0;
    text-align: justify;
    text-align-last: left;

    @media (max-width: 350px) {
      text-align: left;
    }
  }

  a:link,
  a:visited {
    color: ${props => props.theme.accentDark};
  }

  a:hover,
  a:active,
  a:focus {
    color: ${props => props.theme.brand};
  }

  ul,
  ol {
    /* margin-left: 40px; */
    @media (max-width: 600px) {
      /*text-align: justify;*/
    }
  }
`;
