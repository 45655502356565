import styled from 'styled-components';

// TODO: remove, they are not universal

const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  font-size: 14px;

  @media (min-width: 576px) {
    flex-wrap: wrap;
  }

  @media (min-width: 1200px) {
    justify-content: flex-start;
  }

  @media (min-width: 1500px) {
    /* justify-content: center; */
  }
`;

export default FieldWrapper;
