import React, { useCallback, useEffect, useState } from 'react';
import { navigate } from '@reach/router';

import { getBlogFilteredEntriesApi, removeEntryApi } from 'api/entries';
import queryString from 'query-string';
import Entry, { canManipulate } from 'components/Shared/Entry/Entry';
import Pagination from 'components/BlogComponents/BlogPagination';
import FeedControl from 'components/BlogComponents/BlogFeedControl';
import Loading from 'components/Shared/Loading';
import { connect } from 'react-redux';
import { selectUserSettingsAugmented } from 'store/user/selectors/selectUserSettingsAugmented';
import { useBookmark } from 'scenes/hooks/useBookmark';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import { useSearchParams } from '../hooks/useSearchParams';
import { formSearchParams } from '../../utils/helpers/searchParamUtils';

/**
 * fetch entries with correct page settings
 * display pagination if there are more entries
 * do not display pagination if there aren't more entries
 * render entries with all data necessary
 */

export function BlogFeed({ settings, location, blog }) {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [maxPages, setMaxPages] = useState(1);
  const [token] = useTokenState();
  const { activeProfileId: profileId } = useActiveProfile();
  const loggedIn = Boolean(token);
  const parsedSearchParams = useSearchParams();
  const [searchParams, setSearchParams] = useState({ ...parsedSearchParams });
  const [error, setError] = useState('');

  const isOwnBlog = blog.id === profileId;

  useEffect(() => {
    document.title = `${blog.blogTitle} - Дыбр`;
  }, [blog.blogTitle]);

  const parsed = queryString.parse(location.search, {
    decode: false
  });

  const [sort, setSort] = useState(parsed.sort?.toLowerCase() || 'desc');

  const page = Number(parsed.page || parsed.p) || 1;
  const pageSize = settings.pagination.entries;

  const makePageUrl = ({
    page = 1,
    currentTags = parsedSearchParams.tags,
    currentSorting = sort
  }) => {
    const searchParams = { ...parsedSearchParams, tags: currentTags };
    const formedParams = formSearchParams(searchParams);
    const params = [];
    if (page > 1) {
      params.push(`page=${page}`);
    }

    if (currentSorting !== 'desc') {
      params.push(`sort=${currentSorting}`);
    }

    if (params.length) {
      return `${location.pathname}?${params.join('&')}${
        formedParams ? '&' + formedParams : ''
      }`;
    } else {
      return `${location.pathname}${formedParams ? '?' + formedParams : ''}`;
    }
  };

  const loadEntries = useCallback(async () => {
    setEntries([]);
    setError('');
    setLoading(true);

    const { data: entries, meta } = await getBlogFilteredEntriesApi({
      blogId: blog.id,
      pageNumber: page,
      pageSize,
      sort,
      tags: parsedSearchParams.tags,
      tagsGrouped: parsedSearchParams.tagsGrouped,
      content: parsedSearchParams.content,
      startDate: parsedSearchParams.startDate,
      endDate: parsedSearchParams.endDate,
      token
    });

    if (!entries.error) {
      const newMaxPages = Math.max(Math.ceil(meta.totalEntries / pageSize), 1);
      setMaxPages(newMaxPages);

      if (newMaxPages < page) {
        navigate(makePageUrl({ page: newMaxPages }));
      } else {
        setEntries(entries);
        setTotalEntries(meta.totalEntries);
      }
    } else {
      setError('Что-то пошло не так');
    }
    setLoading(false);
  }, [pageSize, page, sort, blog.id, token, location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadEntries();
    // eslint-disable-next-line
  }, [pageSize, page, location.search, sort, blog.id, token]);

  const deleteEntry = async entryId => {
    await removeEntryApi({ entryId, token });
    const filteredEntries = entries.filter(e => e.id !== entryId);
    setEntries(filteredEntries);

    // if no entries left, go to the previous page
    if (!filteredEntries.length) {
      navigate(makePageUrl({ page: page - 1 }));
    } else if (filteredEntries.length === pageSize - 1) {
      navigate(makePageUrl({ page }));
    }
  };

  const renderPagination = type => {
    return (
      <Pagination
        classModifier={type}
        currentPage={page}
        pageSize={pageSize}
        totalRecords={totalEntries}
        makePageUrl={makePageUrl}
      />
    );
  };
  const { toggleBookmark } = useBookmark({ setEntries, token });

  const handleTagChange = tag => {
    const currentTags = searchParams.tags.includes(tag) ? [] : [tag];
    setSearchParams({ ...searchParams, tags: currentTags });
    navigate(makePageUrl({ currentTags }));
  };
  const canAddEntries = isOwnBlog; // || is a member of community

  if (!entries.length && !loading && !error) {
    return (
      <div className="blog-info-block">
        {!(searchParams.tags && searchParams.tags.length) &&
        !searchParams.content
          ? `Похоже, что тут ничего нет.${
              canAddEntries ? ' Напишите что-нибудь.' : ' Или блог закрыт.'
            }`
          : `Ничего не найдено. Попробуйте изменить параметры поиска`}
      </div>
    );
  }

  if (!loading && error) {
    return <div className="blog-info-block">{'Что-то пошло не так.'}</div>;
  }

  function handleSortChange(sort) {
    setSort(sort);
    navigate(makePageUrl({ currentSorting: sort }));
  }

  return (
    <>
      <FeedControl
        tags={blog.tags}
        selectedTags={parsedSearchParams.tags}
        tagsOpen={tagsOpen}
        page={page}
        pages={maxPages}
        sort={sort}
        toggleTags={() => setTagsOpen(!tagsOpen)}
        onTagSelect={handleTagChange}
        onPageSelect={page => navigate(makePageUrl({ page }))}
        onSortSelect={handleSortChange}
      />

      {loading && <Loading />}

      {entries.map(entry => (
        <Entry
          key={entry.id}
          entry={entry}
          canManipulate={canManipulate(loggedIn, profileId, entry)}
          hasUserFunctions={loggedIn}
          onDelete={deleteEntry}
          onToggleBookmark={() => toggleBookmark(entry)}
        />
      ))}

      {!loading && renderPagination('bottom')}
    </>
  );
}

const mapStateToProps = state => ({
  settings: selectUserSettingsAugmented(state)
});

export const BlogFeedContainer = connect(mapStateToProps)(BlogFeed);
