import { useEntryEditContext } from 'scenes/Blog/EntryEdit/EntryEditContext';
import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'utils/helpers/general';
import { getAvatar } from 'utils/helpers/profiles';
import Editor from 'components/Froala/Editor';
import Select from 'components/FormElements/Select';
import Checkbox from 'components/BlogComponents/BlogCheckbox';
import { EntryEditButtonGroupContainer } from 'scenes/Blog/EntryEdit/EntryEditButtonGroup';
import { connect } from 'react-redux';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import PrivacySettings from 'scenes/Blog/EntryEdit/PrivacySettings';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { useSetEntryDraft } from 'store/localStorage/drafts/useSetEntryDraft';
import { useActiveProfile } from 'store/localStorage/useTokenState';
import { selectFamiliarProfiles } from 'store/lists/selectors/selectFamiliarProfiles';

function EntryEdit({ eid, authorProfile, onPublish, blog, familiarProfiles }) {
  const {
    entryContent,
    setEntryContent,
    entryTitle,
    setEntryTitle,
    tags,
    setTags,
    entrySettings,
    setEntrySettings,
    isPinned,
    setIsPinned,
    updatePublishAt,
    setUpdatePublishAt,
    setPreview,
    setPreviewContent
  } = useEntryEditContext();
  const [publishingRequested, setPublishingRequested] = useState(false);
  const saveEntryDraft = useSetEntryDraft();
  const { activeProfileId } = useActiveProfile();

  const ref = useRef();
  const editorRef = useRef();

  useEffect(() => {
    // force save content every second if editor is in html view (froala does not fire any onChange event from there)
    const saveHandler = setInterval(() => {
      const codeView = editorRef.current.codeView;

      if (codeView && codeView.isActive()) {
        const content = codeView.get();
        saveEntryDraft({ id: eid, content, tags, title: entryTitle }, blog);
      }
    }, 1000);
    return () => {
      clearInterval(saveHandler);
    };
  }, [eid, saveEntryDraft, blog]);

  function getContent() {
    const editor = editorRef.current;

    // transform html and use it if the save button was hit from the code view
    if (editor.codeView && editor.codeView.isActive()) {
      editor.html.set(editor.codeView.get());
      return editor.codeView.get();
    } else {
      return editor.html.get();
    }
  }

  const togglePreviewEntry = () => {
    setPreview(true);
    setPreviewContent(getContent());
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (publishingRequested) {
      onPublish(getContent());
      setPublishingRequested(false);
    }
  }, [publishingRequested, onPublish]);

  const isCommentingAllowed = !entrySettings?.permissions?.comments?.length;
  const isInFeed = entrySettings.feed;

  const blogTags = blog.tags
    ? [...blog.tags].sort((a, b) => (a.entries < b.entries ? 1 : -1))
    : [];

  const toggleCommentPermission = () => {
    setEntrySettings(settings => ({
      ...settings,
      permissions: {
        ...settings.permissions,
        comments: isCommentingAllowed ? [{ type: 'private' }] : null
      }
    }));
  };

  const toggleFeed = () => {
    setEntrySettings(settings => ({
      ...settings,
      feed: !isInFeed
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const saveDraft = useCallback(debounce(saveEntryDraft, 500), []);
  const saveDraft = () => debounce(500, saveEntryDraft);

  const handleTagsChange = options => {
    const newTags = options.map(option => option.value);
    setTags(newTags);
    saveDraft()(
      {
        id: eid,
        content: entryContent,
        title: entryTitle,
        tags: newTags
      },
      blog
    );
  };

  const isBlogInFeed = blog.settings.privacy.dybrfeed;
  const canCreateNewTags =
    (blog.settings.community?.anyoneCanCreateTags ?? false) ||
    blog.id === activeProfileId; // or is an admin with corresponding permission

  const canRaisePost =
    (blog.settings.community?.anyoneCanRaise ?? false) ||
    blog.id === activeProfileId; // or is an admin with corresponding permission

  const tagsOptions = blogTags.map(t => ({
    label: t.name,
    value: t.name
  }));
  const tagsValue = tags.map(tag => ({
    label: '#' + tag,
    value: tag
  }));

  return (
    <div className={`blog-entry blog-entry--no-blog-link entry-form`}>
      {authorProfile && (
        <div className="blog-entry-meta--up ">
          <div className="blog-entry-avatar">
            <img src={getAvatar(authorProfile)} alt={authorProfile.nickname} />
          </div>
          <div className="blog-entry-author-wrapping">
            <div className="blog-entry-author">{authorProfile.nickname}</div>
            {authorProfile.settings?.subtext && (
              <div className="blog-entry-subtext">
                <span>{authorProfile.settings.subtext}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="text-field">
        <input
          data-testid="new-entry-topic"
          className={`entry-form-title${entryTitle ? ' filled' : ''}`}
          type="text"
          name="title"
          value={entryTitle}
          onChange={event => {
            setEntryTitle(event.target.value);
            saveDraft()(
              {
                id: eid,
                title: event.target.value || '',
                content: entryContent,
                tags
              },
              blog
            );
          }}
        />
        <label>тема записи</label>
      </div>
      <Editor
        value={entryContent}
        onChange={value => {
          setEntryContent(value);
          saveDraft()(
            { id: eid, content: value ?? '', title: entryTitle, tags },
            blog
          );
        }}
        onInit={editor => (editorRef.current = editor)}
        onSubmitByEnter={() => setPublishingRequested(true)}
        autocompleteMentionOptions={familiarProfiles}
        autocompleteTagsOptions={blogTags}
        blogSlug={blog.blogSlug}
      />

      <div className="entry-settings">
        <Select
          isMulti
          isClearable={false}
          creatable={canCreateNewTags}
          onChange={handleTagsChange}
          options={tagsOptions}
          noOptionsMessage={() =>
            canCreateNewTags
              ? 'тэг не найден'
              : 'тэг не найден и у вас нет прав для создания нового'
          }
          formatCreateLabel={text => 'добавить #' + text}
          controlledValue
          value={tagsValue}
          placeholder="тэги записи"
          className="tags-select"
          maxMenuHeight={300}
          captureMenuScroll={true}
          menuPortalTarget={ref.current}
          menuPlacement="auto"
          blogSelect={true}
        />
        <PrivacySettings menuPortalTarget={ref.current} blog={blog} />
        <div className="settings">
          <div className="settings-label">настройки</div>
          <div className="settings-checkboxes">
            <Checkbox
              label="закрепить запись"
              id="pin-up"
              onChange={() => setIsPinned(!isPinned)}
              checked={isPinned}
            />
            {eid !== '0' && canRaisePost && (
              <Checkbox
                label="поднять запись"
                id="move-up"
                onChange={() => setUpdatePublishAt(!updatePublishAt)}
                checked={updatePublishAt}
              />
            )}
            <div style={{ flexBasis: '100%' }} />
            <br />
            <Checkbox
              label="отключить комментарии"
              id="no-comments"
              onChange={toggleCommentPermission}
              checked={!isCommentingAllowed}
            />
            {isBlogInFeed && (
              <Checkbox
                label="не включать в общую ленту"
                id="no-feed"
                onChange={toggleFeed}
                checked={!isInFeed}
              />
            )}
            {!isBlogInFeed && (
              <Checkbox
                label="включить в общую ленту"
                id="yes-feed"
                onChange={toggleFeed}
                checked={isInFeed}
              />
            )}
          </div>
        </div>
      </div>

      <EntryEditButtonGroupContainer
        onPublish={() => setPublishingRequested(true)}
        onTogglePreview={togglePreviewEntry}
        eid={eid}
        blog={blog}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId }),
    familiarProfiles: selectFamiliarProfiles(state)
  };
};

export const EntryEditContainer = WithActiveProfileId(
  connect(mapStateToProps)(EntryEdit)
);
