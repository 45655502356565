import TextField from 'components/FormElements/TextField';
import { dybrConfigs } from 'configs/dybr';
import React from 'react';

function BlogSlugField({ slug, setSlug, errorSlug, setErrorSlug, validate }) {
  return (
    <div style={{ display: 'flex', marginBottom: '20px' }}>
      <div
        style={{
          flexGrow: '0',
          fontWeight: 'bold',
          position: 'relative',
          top: '19px',
          left: '2px'
        }}
      >
        dybr.ru/blog/
      </div>
      <div style={{ flexGrow: '4' }}>
        <TextField
          id="slug"
          type="text"
          value={slug}
          meta={{}}
          onChange={(e) => {
            setSlug(e.target.value);
            setErrorSlug('');
          }}
          label="короткий адрес дневника"
          maxLen={dybrConfigs.MAX_LENGTH.blogSlug}
          onBlur={validate}
          error={errorSlug}
          touched={Boolean(errorSlug)}
        />
      </div>
    </div>
  );
}

export default BlogSlugField;
