import React, { useEffect, useState } from 'react';
import AnimatedErrorBlock from 'components/FormElements/AnimatedErrorBlock';
import queryString from 'query-string';
import theme from 'styles/DybrTheme';

import { FormWrapper } from './components/StyledForm';
import { Section } from 'components/DybrComponents/Section';
import { ActionButton } from 'components/Shared/Buttons';
import { connect } from 'react-redux';
import { resendConfirmEmailApi } from 'api/auth';
import { fetchUserThunk } from 'store/user/thunks/fetchUserThunk';
import { confirmEmailThunk } from 'store/user/thunks/confirmEmailThunk';
import { doLogoutThunk } from 'store/user/thunks/doLogoutThunk';
import Loading from 'components/Shared/Loading';
import { useTokenState } from 'store/localStorage/useTokenState';
import { parseToken } from 'store/user/reducer/parseToken';

export function ConfirmEmail({
  location,
  doLogoutThunk,
  confirmEmailThunk,
  fetchUserThunk
}) {
  const params = queryString.parse(location.search);
  const token = params.token;
  const email = (params.email || '').split(' ').join('+');

  const [state, setState] = useState({
    loading: false,
    error: '',
    emailSent: false
  });
  const [tokenLS, setToken] = useTokenState();
  const isLoggedIn = Boolean(tokenLS);

  useEffect(() => {
    document.title = `Подтверждение аккаунта - Дыбр`;
    if (isLoggedIn) {
      doLogoutThunk(setToken);
    }
    if (!token || !email) {
      setState((st) => ({ ...st, error: 'некорректная ссылка из письма' }));
      return;
    }
    async function fetchConfirmation() {
      setState((st) => ({ ...st, loading: true }));

      let res = await confirmEmailThunk({ email, token });

      if (res.error) {
        setState({ error: res.error, loading: false });
        return;
      }

      setState((st) => ({ ...st, loading: false }));

      setToken(res.token);

      setTimeout(() => {
        fetchUserThunk({
          setToken,
          token: res.token,
          ...parseToken(res.token)
        });
      }, 1000);
    }
    fetchConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendEmail = async () => {
    let res = await resendConfirmEmailApi({ email });
    if (res.error) {
      setState((st) => ({ ...st, error: res.error }));
      return;
    }
    setState((st) => ({ ...st, emailSent: true, error: '' }));
  };

  const { loading, error, emailSent } = state;

  if (loading) {
    return <Loading />;
  }

  if (error || emailSent) {
    return (
      <Section withBackground noPadding>
        <FormWrapper>
          <h2>активация аккаунта</h2>
          <br />
          {emailSent && (
            <p style={{ textAlign: 'center' }}>повторное письмо отправлено!</p>
          )}

          {error && (
            <p style={{ textAlign: 'center', paddingBottom: '20px' }}>
              Попробуйте еще раз или напишите нам на{' '}
              <a
                style={{ color: theme.accentDark }}
                href="mailto:support@dybr.ru"
              >
                support@dybr.ru
              </a>
            </p>
          )}

          <AnimatedErrorBlock show={error}>{error}</AnimatedErrorBlock>
          <ActionButton onClick={resendEmail}>
            Выслать повторное письмо
          </ActionButton>
        </FormWrapper>
      </Section>
    );
  }

  return (
    <Section withBackground noPadding>
      <FormWrapper>
        <h2>активация аккаунта прошла успешно!</h2>
        <p style={{ textAlign: 'center' }}>подождите...</p>
        <br />
      </FormWrapper>
    </Section>
  );
}

const mapDispatchToProps = {
  doLogoutThunk,
  fetchUserThunk,
  confirmEmailThunk
};

export const ConfirmEmailContainer = connect(
  null,
  mapDispatchToProps
)(ConfirmEmail);
