import React, { useEffect, useState } from 'react';

import {
  Block,
  ContentWrapper,
  MessageWrapper,
  Row,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { connect } from 'react-redux';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import TextField from 'components/FormElements/TextField';
import { ActionButton, LinkButton } from 'components/Shared/Buttons';
import { selectUserData } from 'store/user/selectors/selectUserData';
import { changeUserEmailApi, updateUserPasswordApi } from 'api/users';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import { requestNewPasswordThunk } from 'store/user/thunks/requestNewPasswordThunk';
import { validateEmailDomain } from '../../../utils/helpers/validate';

function AccountSettingsScene({ user, requestNewPasswordThunk }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRetyped, setNewPasswordRetyped] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState({});
  const [token] = useTokenState();
  const { userId } = useActiveProfile();

  useEffect(() => {
    document.title = `Настройки аккаунта`;
  }, []);

  function validateEmail() {
    if (newEmail === user.email) {
      setErrors({ ...errors, formEmail: 'Новый email совпадает с текущим' });
      return false;
    }
    if (!newEmail) {
      setErrors({ ...errors, formEmail: 'Укажите новый email' });
      return false;
    }
    let unsupportedEmailDomain = validateEmailDomain(newEmail);
    if (unsupportedEmailDomain) {
      setErrors({
        ...errors,
        formEmail: `Почтовый домен "${unsupportedEmailDomain}" не поддерживается`
      });
      return false;
    }
    return true;
  }

  async function changeEmail() {
    const isValid = validateEmail();
    if (!isValid) {
      return;
    }
    const res = await changeUserEmailApi({
      newEmail,
      userId,
      token
    });
    if (res && res.error) {
      setSuccessMessage({ ...successMessage, formEmail: '' });
      setErrors({ ...errors, formEmail: 'Не удалось отправить письмо' });
    } else {
      setErrors({ ...errors, formEmail: '' });
      setSuccessMessage({
        ...successMessage,
        formEmail:
          'Письмо с подтверждением отправлено на новый адрес. Проверьте его, чтобы завершить смену почты'
      });
    }
  }

  async function changePassword() {
    if (newPassword !== newPasswordRetyped) {
      setErrors({ ...errors, formPassword: 'Пароли не совпадают!' });
      return;
    }
    if (oldPassword && newPassword && newPasswordRetyped) {
      const res = await updateUserPasswordApi({
        oldPassword,
        newPassword,
        userId,
        token
      });
      if (res.error) {
        setErrors({ ...errors, formPassword: res.error });
        setSuccessMessage({ ...successMessage, formPassword: '' });
      } else {
        setErrors({ ...errors, formPassword: '' });
        setSuccessMessage({
          ...successMessage,
          formPassword: 'Пароль успешно изменён'
        });
      }
    } else {
      setErrors({ ...errors, formPassword: 'Заполните все поля' });
    }
  }

  async function recoverPassword({ email }) {
    const sendRecoveryMail = window.confirm(
      'Выслать письмо с восстановлением пароля?'
    );
    if (sendRecoveryMail) {
      const res = await requestNewPasswordThunk({ email });
      if (res.error) {
        window.alert(res.error);
      } else {
        window.alert('Ссылка отправлена на почту аккаунта!');
      }
    }
  }

  return (
    <ContentWrapper>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Смена почты</h4>
            <p>
              Вы можете сменить ваш email, он будет использоваться со следующего
              входа.
            </p>
            <p>
              <br />
            </p>
            <p>
              Внимание: если у вас уже зарегистрирован аккаунт на новой почте,
              произойдет слияние аккаунтов. Все профили будут доступны из одного
              аккаунта с возможностью переключения между ними.
            </p>
          </SettingSectionTitle>
          <Settings>
            <TextField disabled label="текущий email" value={user.email} />
            <TextField
              label="новый email"
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
            />
            <MessageWrapper type="error" show={errors.formEmail}>
              {errors.formEmail}
            </MessageWrapper>
            <MessageWrapper type="success" show={successMessage.formEmail}>
              {successMessage.formEmail}
            </MessageWrapper>
            <ActionButton onClick={changeEmail}>сменить почту</ActionButton>
          </Settings>
        </SettingsFlexbox>
      </Block>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Смена пароля</h4>
            <p>
              Вы можете сменить ваш пароль, он будет использоваться со
              следующего входа.
            </p>
          </SettingSectionTitle>
          <Settings>
            <TextField
              isRequired={true}
              type="password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              label="ваш пароль"
              id="old-password"
              error={errors?.oldPassword}
            />
            <Row>
              <TextField
                isRequired={true}
                type="password"
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                  setErrors({ ...errors, formPassword: '' });
                }}
                label="новый пароль"
                id="new-password"
                touched={false}
              />
              <TextField
                isRequired={true}
                type="password"
                value={newPasswordRetyped}
                onChange={e => {
                  setNewPasswordRetyped(e.target.value);
                  setErrors({ ...errors, formPassword: '' });
                }}
                label="повторите новый пароль"
                id="new-password-retyped"
                touched={false}
              />
            </Row>
            <MessageWrapper type="error" show={errors.formPassword}>
              {errors.formPassword}
            </MessageWrapper>
            <MessageWrapper type="success" show={successMessage.formPassword}>
              {successMessage.formPassword}
            </MessageWrapper>
            <ActionButton onClick={changePassword}>сменить пароль</ActionButton>
            <LinkButton onClick={() => recoverPassword({ email: user.email })}>
              Забыли пароль?
            </LinkButton>
          </Settings>
        </SettingsFlexbox>
      </Block>
    </ContentWrapper>
  );
}

const mapDispatchToProps = {
  requestNewPasswordThunk
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUserData(state)
  };
};

export const AccountSettingsContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(AccountSettingsScene)
);
