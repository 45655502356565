import styled from 'styled-components';

// on the right of interesting stuff, registration and later search

export const InteractionBlock = styled.div`
  padding: 45px 0 0 50px;

  @media (max-width: 1200px) {
    padding-top: 10px;
  }

  @media (max-width: 830px) {
    padding: 0;
    margin: 20px auto 0 auto;
  }

  @media (max-width: 350px) {
    width: 100%;
    flex: 1 0 auto;
    margin: 0;
    margin-top: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  button {
    font-size: 14px;
    font-weight: bold;
    padding: 20px 30px;
    /*height: 45px;
    width: 180px;*/
    margin: 15px auto;
  }

  button.link-button {
    font-size: 12px;
    font-weight: normal;
    display: block;
    margin: 0 auto;
    padding: 0;
  }
`;
