import React from 'react';
import styled from 'styled-components';
import theme from 'styles/DybrTheme';
import { Link, navigate } from '@reach/router';
import ContentWrap from 'components/DybrComponents/ContentWrap';

import {
  SectionNarrowBlock,
  SectionTitle
} from 'components/DybrComponents/Section';
import { ActionButton } from 'components/Shared/Buttons';
import lemur from 'components/img/lemur404.png';

const Lemur = styled.img`
  max-width: 400px;
  height: auto;
  position: relative;
  left: 46px;
`;

const PageInfo = styled(SectionNarrowBlock)`
  text-align: left;
  button {
    margin: 15px 0 15px 0;
  }
`;

const NotFound = ({ reason, ...props }) => {
  console.log('Not found: ');
  console.log(props);

  const shouldGoBack = !!window.document.referrer.match(/dybr/i);

  const goBack = () => {
    if (window.history.length > 2 && shouldGoBack) window.history.go(-1);
    else navigate('/');
  };

  return (
    <ContentWrap>
      <SectionTitle color={theme.brand} className="large-margin">
        404
      </SectionTitle>
      {/*{reason && <div>{reason}</div>}*/}
      <PageInfo size="400px">
        <Lemur src={lemur} />
        <p>Возможно, эту страницу съел лемур.</p>
        <p>Но у нас есть другие!</p>
        {/* <ActionButton>связаться с администратором</ActionButton */}
        <ActionButton onClick={goBack}>
          {shouldGoBack ? 'назад' : 'на главную'}
        </ActionButton>
        <p>
          Если вы уверены, что это ошибка,{' '}
          <Link to="/errorlog">расскажите нам!</Link>
        </p>
      </PageInfo>
    </ContentWrap>
  );
};

export default NotFound;
