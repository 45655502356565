import css from './ConversationList.module.css';
import React, { useEffect, useState } from 'react';
import Checkbox from '../../../components/FormElements/Checkbox';
import ProfileLink from '../../Management/Community/ProfileLink';
import { classNames } from '../../../utils/helpers/classNames';
import { getConversationsApi } from '../../../api/private-messages';
import { useTokenState } from '../../../store/localStorage/useTokenState';
import { selectActiveProfileAugmented } from '../../../store/user/selectors/selectActiveProfileAugmented';
import WithActiveProfileId from '../../../store/localStorage/WithActiveProfileId';
import { connect } from 'react-redux';
import { formatDateTime } from '../../../utils/helpers/formatTimeDate';
import MessageEnvelope from '../../../styles/Icons/MessageEnvelope';
import { IconButton } from '../../../components/Shared/AvatarMenu/Styled';
import Loading from '../../../components/Shared/Loading';

function ConversationList({ ownProfile }) {
  const [checkedAll, setCheckedAll] = useState(false);
  const [conversations, setConversations] = useState();
  const [total, setTotal] = useState(0);
  const [totalUnread, setTotalUnread] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  useEffect(() => {
    if (isLoggedIn) {
      loadConversations();
    } else {
      setLoading(false);
      setError('Войдите, чтобы увидеть список разговоров');
    }
  }, [ownProfile]);

  async function loadConversations() {
    setLoading(true);
    setError('');
    try {
      const res = await getConversationsApi({ profileId: ownProfile.id, token });
      res.data.forEach(conversation => {
        conversation.link = `direct/${conversation.profile.id}`;
      });
      setTotal(res.total);
      setTotalUnread(res.totalUnread);
      setConversations(res.data);
    } catch (error) {
      setError('Не удалось получить список разговоров');
    } finally {
      setLoading(false);
    }
  }

  function getStatusMessage() {
    if (conversations && conversations.length > 0) {
      if (totalUnread === 0) {
        return `Всего разговоров ${total}, непрочитанных сообщений нет`;
      } else {
        return `Всего разговоров ${total}, из них с новыми сообщениями ${totalUnread}`;
      }
    }
    if (conversations && conversations?.length === 0) {
      return `У вас ещё нет сообщений. Напишите своё первое!`;
    }
  }

  return (
    <div className={css.container}>
      {loading && <Loading />}
      {error && <div className={css.error}>{error}</div>}
      {!error && !loading && conversations?.length === 0 && (
        <>
          <div className={css.status}>{getStatusMessage()}</div>
        </>
      )}
      {!error && !loading && conversations?.length > 0 && (
        <>
          <div className={css.status}>{getStatusMessage()}</div>
          <table className={css.table}>
            <thead>
              <tr>
                <th className={css.columnCheckbox} style={{ display: 'none' }}>
                  <Checkbox
                    inputClassName={css.checkbox}
                    checked={checkedAll}
                    onChange={() => {
                      setCheckedAll(!checkedAll);
                    }}
                    invertedColor={true}
                  />
                </th>
                <th className={css.columnProfile}>ник пользователя</th>
                <th className={css.columnMessages}>сообщения</th>
                <th className={css.columnDate}>дата</th>
              </tr>
            </thead>
            <tbody>
              {conversations.map(conversation => {
                return (
                  <tr
                    key={conversation.profile.id}
                    className={classNames({
                      [css.unreadMessagesRow]:
                        conversation.unreadMessageCount > 0
                    })}
                  >
                    <td
                      className={css.columnCheckbox}
                      style={{ display: 'none' }}
                    >
                      <Checkbox
                        inputClassName={css.checkbox}
                        checked={conversation.checked}
                        onChange={() => {
                          conversation.checked = !conversation.checked;
                        }}
                      />
                    </td>
                    <td className={css.columnProfile}>
                      <ProfileLink
                        profile={conversation.profile}
                        className={css.profileName}
                        link={conversation.link}
                      >
                        {conversation.unreadMessageCount !== 0 && (
                          <div className={`${css.unreadMessagesCollapsed}`}>
                            <a
                              className={`${css.unreadMessagesMark}`}
                              href={conversation.link}
                            >
                              {conversation.unreadMessageCount}
                            </a>
                          </div>
                        )}
                      </ProfileLink>
                    </td>
                    <td className={css.columnMessages}>
                      {conversation.unreadMessageCount > 0 && (
                        <>
                          <div className={css.unreadMessagesMark}>
                            {conversation.unreadMessageCount}
                          </div>
                          <span>{' / '}</span>
                        </>
                      )}
                      {conversation.messageCount}
                    </td>
                    <td className={css.columnDate}>
                      {formatDateTime(conversation.lastMessagePublishedAt)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      {isLoggedIn && !loading && (
        <div className={css.help}>
          Чтобы написать сообщение, нажмите на аватар пользователя, а затем
          нажмите на{' '}
          <IconButton title="личные сообщения">
            <MessageEnvelope className={css.helpIcon} />{' '}
          </IconButton>
          в меню
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {};

export const ConversationListContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(ConversationList)
);
