import RadioGroup from 'components/FormElements/RadioGroup';
import React, { useState } from 'react';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import { selectActiveProfile } from 'store/user/selectors/selectActiveProfile';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { connect } from 'react-redux';
import { useTokenState } from 'store/localStorage/useTokenState';
import CustomListOption from 'scenes/User/Settings/Privacy/AccessSettings/CustomListOption';
import { AccessType } from 'utils/privacy/types/AccessType';
import {
  calculateReadAccessSettings,
  getCurrentAccessType
} from 'utils/privacy/accessCalculation';

const commonPrivacyOptions = [
  {
    label: 'открыт всем',
    value: null // или вообще пустое
  },
  {
    label: 'открыт зарегистрированным',
    value: { type: AccessType.registered }
  },
  {
    label: 'открыт спискам',
    value: { type: AccessType.allowCustomList },
    component: () => (
      <CustomListOption accessType={AccessType.allowCustomList} />
    )
  },
  {
    label: 'закрыт спискам',
    value: { type: AccessType.denyCustomList },
    component: () => <CustomListOption accessType={AccessType.denyCustomList} />
  }
];

const commonOnlyPrivateOption = {
  label: 'закрыт от всех',
  value: { type: AccessType.private }
};

const privacyOptions = [
  ...commonPrivacyOptions,

  {
    label: 'открыт моим читателям',
    value: { type: AccessType.subscribers }
  },
  {
    label: 'открыт моим избранным',
    value: { type: AccessType.favorites }
  },
  commonOnlyPrivateOption
].map((option, index) => ({ ...option, radioValue: index }));

const communityPrivacyOptions = [
  ...commonPrivacyOptions,
  {
    label: 'открыт для читателей и участников',
    value: { type: AccessType.subscribers }
  },
  {
    label: 'открыт для участников',
    value: { type: AccessType.participants }
  },
  {
    label: 'открыт только для админов',
    value: { type: AccessType.admins }
  },
  commonOnlyPrivateOption
].map((option, index) => ({ ...option, radioValue: index }));

function getCheckedPermissionSetting(settings, isCommunity) {
  const options = isCommunity ? communityPrivacyOptions : privacyOptions;
  const typeToSearch = getCurrentAccessType(settings);

  return (
    options.find(option => option.value?.type === typeToSearch)?.radioValue ?? 0
  );
}

function PermissionSettings({ updateProfileSetting, settings, isCommunity }) {
  const [token] = useTokenState();
  const [checkedPermissionSetting, setCheckedPermissionSetting] = useState(
    getCheckedPermissionSetting(settings, isCommunity)
  );

  const changeBlogPrivacySetting = (requestedAccessType, id) => {
    const newReadAccess = calculateReadAccessSettings(
      requestedAccessType,
      settings
    );

    updateProfileSetting('permissions.access', newReadAccess, token);
    setCheckedPermissionSetting(id);
  };

  return (
    <>
      <RadioGroup
        title="ваш блог"
        name="blogPrivacyOptions"
        checked={checkedPermissionSetting}
        onCheck={changeBlogPrivacySetting}
        items={isCommunity ? communityPrivacyOptions : privacyOptions}
      />
    </>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId }),
    isCommunity: selectActiveProfile(state, { activeProfileId })?.isCommunity
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(PermissionSettings)
);
