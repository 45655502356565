import React from 'react';

const SearchIcon = ({ className }) => (
  <svg
    viewBox="-1 -1 30 30"
    className={`icon icon-search ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      className="filling-area"
      d="m 22.44844,21.1843 c 1.95608,-2.24883 3.14038,-5.18297 3.14038,-8.38989 C 25.58882,5.7352 19.84697,6e-7 12.79441,6e-7 5.7352,6e-7 0,5.74185 0,12.79441 c 0,7.05257 5.74185,12.79442 12.79441,12.79442 3.20692,0 6.14105,-1.1843 8.38989,-3.14039 z"
    />
    <path
      className="main-outline"
      fill="currentColor"
      d="M 29.74052,28.47638 22.44844,21.1843 c 1.95608,-2.24883 3.14038,-5.18297 3.14038,-8.38989 C 25.58882,5.7352 19.84697,0 12.79441,0 5.7352,0 0,5.74185 0,12.79441 c 0,7.05257 5.74185,12.79442 12.79441,12.79442 3.20692,0 6.14105,-1.1843 8.38989,-3.14039 l 7.29208,7.29208 c 0.17299,0.17299 0.40586,0.26614 0.63207,0.26614 0.22621,0 0.45908,-0.0865 0.63207,-0.26614 0.34597,-0.34597 0.34597,-0.91816 0,-1.26414 z M 1.78975,12.79441 c 0,-6.06786 4.9368,-10.998 10.99801,-10.998 6.06786,0 10.998,4.93679 10.998,10.998 0,6.06122 -4.93014,11.00466 -10.998,11.00466 -6.06121,0 -10.99801,-4.93679 -10.99801,-11.00466"
    />
  </svg>
);
export default SearchIcon;
