export default ({ colors, header, font }) => `
  .blog-comment.entry-form {
    padding: 40px;
    margin: 20px 0;
    background: ${colors.blocks};
    border: 1px solid ${colors.borders};
  }

  .entry-form .blog-comment-avatar {
    padding-left: 0px;
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    .entry-form .blog-comment-avatar {
      margin-right: 0px;
    }
  }

  .blog-comment.entry-form .blog-comment-author a::before {
    border: none;
  }

  .blog-comment.entry-form .blog-comment-date {
    padding-top: 50px;
    margin: 0;
  }
  @media(max-width: 700px){
    .blog-comment.entry-form {
      padding: 20px;
    }
  }


`;
