import React, { useState } from 'react';
import { StyledCommentCounter, StyledDiscussionSection } from './styled';
import ArrowIconUp from '../../../../styles/Icons/SimpleArrowUpWithBackground';
import ArrowIconDown from '../../../../styles/Icons/SimpleArrowDownWithBackground';

function Accordion({
  title,
  initialState,
  canChangeState = true,
  onClick,
  notificationsCounter,
  notificationsLink,
  notificationsTitle,
  children
}) {
  const [isOpen, setOpen] = useState(initialState === 'open');
  return (
    <StyledDiscussionSection>
      <div
        className={`section-header ${
          isOpen ? 'section-open' : 'section-closed'
        }`}
      >
        <div
          className="section-title"
          onClick={canChangeState ? () => setOpen(!isOpen) : onClick}
        >
          <div className="arrow">
            {canChangeState && (isOpen ? <ArrowIconUp /> : <ArrowIconDown />)}
          </div>
          {title}
        </div>
        {notificationsCounter > 0 && (
          <a href={notificationsLink} title={notificationsTitle}>
            <StyledCommentCounter className="section-notification-count">
              {notificationsCounter}
            </StyledCommentCounter>
          </a>
        )}
      </div>
      {isOpen && children}
    </StyledDiscussionSection>
  );
}

export default Accordion;
