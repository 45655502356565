import React, { useState } from 'react';

import Switch from 'components/FormElements/Switch';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { connect } from 'react-redux';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

function IndexingSettings({ settings, updateProfileSetting }) {
  const [allowSearchEngines, setAllowSearchEngines] = useState(
    !settings.privacy?.hideContentFromSearchEngines
  );
  const [token] = useTokenState();

  const toggleIndexProtection = () => (e) => {
    updateProfileSetting(
      'privacy.hideContentFromSearchEngines',
      !e.target.checked,
      token
    );
    setAllowSearchEngines(e.target.checked);
  };

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Индексация поисковыми движками</h4>
          <p>
            Эта настройка позволяет запретить поисковым движкам получать доступ
            к вашим записям и выдавать их в результатах поиска. Она может
            пригодиться в том случае, если ваш дневник открыт всем, но вы
            предпочли бы, чтобы в поисковиках нельзя было бы найти тексты ваших
            записей. Если ваши записи закрыты от незарегистрированных, то
            доступа у поисковых систем в любом случае нет, вне зависимости от
            этой настройки. Однако ваш профиль, а также ваши комментарии в чужих
            открытых записях, эта настройка защитить не сможет.
          </p>
        </SettingSectionTitle>
        <Settings>
          <Switch
            label={`Индексация ${
              allowSearchEngines ? 'разрешена' : 'запрещена'
            }`}
            checked={allowSearchEngines}
            onClick={toggleIndexProtection()}
          />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(IndexingSettings)
);
