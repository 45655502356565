import styled from 'styled-components';

const AnimatedErrorBlock = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.textLighter};
  background-color: ${props => props.theme.brand};
  font-size: 14px;
  transition: height 0.3s, margin 0.6s;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;
  margin-bottom: 0px;

  ${props =>
    props.show
      ? `opacity: 1;
    height: 30px;
    padding: 5px;
    margin-bottom: 20px;
    `
      : ''};
`;

export default AnimatedErrorBlock;
