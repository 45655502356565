export default ({ colors, header, fonts }) => `

.blog-feed-control {
  line-height: 1;
  color: ${colors.elements};
  background-color: ${colors.elementsBack};
  font-family: ${fonts.elements.family};
  font-size: ${fonts.elements.size}px;
  font-weight: ${fonts.elements.weight};
  font-style: ${fonts.elements.style};
  text-decoration: ${fonts.elements.textDecoration};
  text-decoration-color: ${colors.elements};
  margin: 0px;
  padding: 0 40px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.blog-feed-control .icon-tag  {
  height: ${fonts.elements.size * 1.2}px;
  width:  ${fonts.elements.size * 1.2}px;
}

.blog-feed-control .icon .main-outline,
.blog-feed-control .tags-active .icon .tag-hole {
  fill: ${colors.elements};
}
/* Tag on the panel */
.blog-feed-control .selected-tag {
  color: ${colors.accent};
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-feed-control .tags {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.blog-feed-control .tags-active .icon .main-outline,
.blog-feed-control .icon .tag-hole  {
  fill: ${colors.accent};
}

.tags-block--top {
  background-color:  ${colors.blocks};
  padding: 20px 0 20px 40px;
  display: flex;
  flex-wrap: wrap;
}

/* Tag in the tag list */
.tags-block--top .tag {
  width: 25%;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  padding: 10px 40px 10px 0;
  cursor: pointer;
}
.tag:hover,
.tag--selected {
  font-weight: bold;
  color: ${colors.links};
}
.tag-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.tag-entries-count {
}
@media (max-width: 650px) {
  .tags-block--top {
  }
  .tags-block--top .tag {
    width: 50%;

  }
}
@media (max-width: 450px) {
  .tags-block--top .tag {
    width: 100%;
  }
}

.tags-block--top::after {
  content: "";
  flex: auto;
}

.blog-feed-control .icon-buttons {
  margin: 10px 0;
}
.blog-feed-control .feed-settings {
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.blog-feed-control .blog-select__control {
  border-top: 1px solid ${colors.elements};
  border-bottom: 1px solid ${colors.elements};
  margin-left: 0.7em;
}
.blog-feed-control .blog-select__dropdown-indicator {
  padding: 3px;
  color: ${colors.elements}
}
.blog-feed-control .blog-select__dropdown-indicator:hover {
  color: ${colors.accent}
}
.blog-feed-control .blog-select__single-value {
  color: ${colors.elements};
  top: 0.4em;
}
.blog-feed-control .blog-select__value-container {
  padding: 0 0 0 0.2em;
}
.blog-feed-control .page-select .blog-select__single-value {
  top: 0.5em;
}
.feed-setting__page, .feed-setting__sort {
  margin: 10px 0 10px 20px;
  display: flex;
  align-items: center;
}
.page-select-label, .sort-select-label, .page-select, .sort-select {
  flex-shrink: 0;
}

`;
