import { useState } from 'react';
import copyToClipboard from 'utils/helpers/copyToClipboard';

/**
 * Handle getting quote from selected text in entries and comments. Works on touch screens too.
 * The reply button or other target should have name attribute "reply"
 */

export default function useCopyTooltip(text) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  function copy() {
    if (copyToClipboard(text)) {
      setIsCopied(true);
      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    } else {
      setShowTooltip(true);
      setIsCopied(false);
    }
  }
  function toggle() {
    setShowTooltip(showTooltip => !showTooltip);
  }

  return { isCopied, showTooltip, copy, toggle };
}
