import React from 'react';
import { Redirect, Router } from '@reach/router';

import NotFound from 'scenes/Dybr/Static/NotFound';
import Footer from 'scenes/Footer/Footer';
import { ResetPasswordContainer } from './ResetPassword';
import { CreateAccountContainer } from 'scenes/Account/CreateAccount';
import { connect } from 'react-redux';
import { selectIsSignupIncomplete } from 'store/user/selectors/selectIsSignupIncomplete';
import { ConfirmEmailContainer } from 'scenes/Account/ConfirmEmail';
import CreateBlog from 'scenes/Account/createBlog/CreateBlog';
import { useTokenState } from 'store/localStorage/useTokenState';

/**
 * Scenes are mainly for routing and getting the data used in all sub-pages or sections
 *
 */
function Account({ isSignupIncomplete }) {
  const [token] = useTokenState();
  const loggedIn = Boolean(token);

  return (
    <>
      <Router>
        <Redirect from="/" to="/account/new" />
        {<CreateAccountContainer path="new" />}
        {isSignupIncomplete && <ConfirmEmailContainer path="confirm-email" />}
        {loggedIn && <CreateBlog path="create" />}
        <ResetPasswordContainer path="reset-password" />

        <NotFound default reason={'Аккаунт: что-то пошло не так'} />
      </Router>
      <Footer withLemur />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isSignupIncomplete: selectIsSignupIncomplete(state)
  };
};

export const AccountContainer = connect(mapStateToProps)(Account);
