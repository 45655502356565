import { createSelector } from 'reselect';
import { selectLists } from './selectLists';

export const selectReaderProfiles = createSelector(selectLists, lists => {
  return lists.readers.map(id => ({
    ...lists.profiles[id],
    isFavorite: lists.favorites.includes(id),
    isReader: true
  }));
});
