import React from 'react';
import { Link } from '@reach/router';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'styles/DybrTheme';

import logo from 'styles/Logo.svg';

const Logo = styled.img`
  display: block;
  height: 30px;
`;

// This is a container for the top nav bar

const TopBar = styled.div`
  position: relative;
  z-index: 30000;
  background-color: ${props => props.theme.backgroundDarker};
  image-rendering: -moz-crisp-edges;
  background-size: cover;
  width: 100%;
  min-height: 50px;
`;

const Container = styled.div`
  height: ${props => props.height};
  color: ${props => props.theme.textLighter};
  max-width: 1210px;
  margin: 0 auto;
  min-height: 50px;
  font-size: 20px;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;

  @media (max-width: 650px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  a.dybrLogo {
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 4px;
    margin-left: 13px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    overflow: auto;

    @media (max-width: 650px) {
      align-content: space-between;
    }

    li {
      margin: 0 22px;
      display: flex;
      align-items: center;

      @media (max-width: 500px) {
        margin: 0 10px;
      }

      @media (max-width: 360px) {
        margin: 0 8px;
      }
    }

    li:first-child {
      margin-left: 0px;
      @media (max-width: 650px) {
        margin-left: inherit;
      }
    }

    li:last-child {
      padding-right: 13px;
      margin-right: 0px;

      @media (max-width: 650px) {
        margin-bottom: 10px;
        margin-right: inherit;
        padding-right: 0px;
      }
    }
  }
`;

const Wrapper = styled.div`
  z-index: 100;
  /* position: absolute;*/
  top: 0;
  width: 100%;
`;

const NavBarContainer = ({ children }) => {
  return (
    <Wrapper>
      <ThemeProvider theme={theme}>
        <TopBar>
          <Container>
            <Link to="/" className="dybrLogo">
              <Logo src={logo} alt="Dybr logo" />
            </Link>
            {children}
          </Container>
        </TopBar>
      </ThemeProvider>
    </Wrapper>
  );
};

export default NavBarContainer;
