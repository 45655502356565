import { query } from 'api/Query';

export const hideProfileFromFeedForAllApi = async ({
  profileId,
  reason,
  token
}) => {
  try {
    const res = await query({
      method: 'CUSTOM_POST',
      resource: `admin/feed/profile/${profileId}`,
      queryParams: {
        action: 'hide',
        reason,
        'internal-note': ''
      },
      token
    });

    if (res.status !== 200) return res;
    else return { success: true };
  } catch (error) {
    return { error: true, ...error.response };
  }
};
