import { useEffect, useRef } from 'react';

/**
 * Handle getting quote from selected text in entries and comments. Works on touch screens too.
 * The reply button or other target should have name attribute "reply"
 */
function getTargetBlockId(target) {
  if (target) {
    return target.id || getTargetBlockId(target.parentNode);
  }
}

export default function useSelectionQuote() {
  const selectionTargetIdRef = useRef(null);
  const selectionRef = useRef('');

  useEffect(() => {
    function handleSelectionChange() {
      const selection = window.getSelection().toString();
      if (selection.length) {
        selectionRef.current = selection;
        // setSelection(selection);
      }
    }

    function handleMouseDown(e) {
      const targetId = getTargetBlockId(e.target);
      if (targetId) {
        selectionTargetIdRef.current = targetId;
      }
      if (e.target.getAttribute('name') === 'reply') {
        selectionRef.current = window.getSelection().toString();
      }
    }

    document.addEventListener('selectionchange', handleSelectionChange);
    document.addEventListener('mousedown', handleMouseDown);

    // Remove event listeners on cleanup
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  function getQuote(id) {
    // when I hit reply, I want to get any currently active selection
    // that belongs inside the block I am clicking
    // the problem is, once I click on reply button, the window resets the selection,
    // so I need to store it beforehand
    const quote =
      selectionTargetIdRef.current === id ? selectionRef.current : '';
    selectionRef.current = '';
    selectionTargetIdRef.current = null;
    return quote;
  }

  return {
    getQuote // get selected text inside the target block
  };
}
