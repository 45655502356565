import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContentWrap from 'components/DybrComponents/ContentWrap';
import { HOSTNAME } from 'configs/dybr';

import {
  SectionNarrowBlock,
  SectionTitle
} from 'components/DybrComponents/Section';

const Wrapper = styled.div`
  margin: 0 30px;
  h3 {
    margin: 30px 0 20px 0;
  }
  h4 {
    margin: 30px 0 20px 0;
  }
  p {
    text-align: unset;
  }
  p.small {
    font-size: 11px;
  }
`;

const Terms = () => {
  useEffect(() => {
    document.title = `Правила и политика конфиденциальности - Дыбр`;
  }, []);

  return (
    <ContentWrap>
      <SectionTitle data-testid="terms">
        Правила и политика конфиденциальности
      </SectionTitle>
      <SectionNarrowBlock size="650px">
        <Wrapper>
          <p className="small">
            Правила сайта могут обновляться и расширяться по мере необходимости.
            Пользователи будут уведомляться об этом через официальный{' '}
            <a
              href={HOSTNAME + '/blog/dybr'}
              target="_blank"
              rel="noopener noreferrer"
            >
              блог Дыбра
            </a>
            .
          </p>
          <p className="small">Последняя редакция: 30.10.2023.</p>

          <h3>правила</h3>
          <p>
            Мы выступаем за сохранение комьюнити и против вербальной,
            эмоциональной или любой другой агрессии. Мы не поощряем
            дискриминацию других людей и готовы защищать своих пользователей.
          </p>
          <p>
            При нарушении правил проекта, администрация оставляет за собой право
            применять меры. Меры могут включать: предупреждение, удаление
            нарушающих правила записей или комментариев, запрет на
            комментирование, принудительное исключение блога из лент сайта,
            временный бан, удаление аккаунта.
          </p>
          <h4>правила сообщества</h4>
          <ol>
            <li>
              Не используйте Дыбр для совершения преступлений и публикации{' '}
              <a href={HOSTNAME + '/terms/forbidden-actions'}>
                запрещённых материалов
              </a>
              .
            </li>
            <li>
              Не занимайтесь разжиганием ненависти и вражды. Открытые либо
              скрытые угрозы пользователям ресурса запрещены. Преследования и
              провокации других пользователей запрещены.
            </li>
            <li>
              Сохраняйте неприкосновенность частной жизни других людей. Не
              распространяйте личные данные из дневников/блогов пользователей
              без их согласия. Публичные намерения, призывы и поощрения к
              распространению личной информации, а также передача личной
              информации третьим лицам запрещены.
            </li>
          </ol>

          <h4>правила публикации в общую ленту</h4>
          <ol>
            <li>
              Не публикуйте то, что может быть расценено как спам: записи, не
              носящие смысловой нагрузки; повторяющиеся записи, рекламирующие
              товар или услугу; публикация большого количества записей с
              небольшим интервалом.
            </li>
            <li>
              Скрывайте контент 18+ и контент деликатного характера под спойлер
              с предупреждением.
            </li>
            <li>
              Соблюдайте чистоту общего пространства. Публикуя запись в общей
              ленте, убедитесь, что формат записи не нарушает удобство
              пользования ресурсом.
            </li>
          </ol>

          <h3>политика конфиденциальности</h3>
          <ol>
            <li>
              Мы не выдаем персональные данные пользователей (электронная почта
              и связанные с ней профили) третьим лицам.
            </li>
            <li>
              Ваши личные данные могут быть удалены по вашему запросу. Это
              включает аккаунт, профили и дневники со всеми записями и
              комментариями. Комментарии в чужих дневниках могут быть удалены
              или анонимизированы, по вашему выбору.
            </li>
            <li>
              Мы не собираем статистику использования сайта и не используем
              внешние cookies.
            </li>
          </ol>
          <h3>рекомендации</h3>
          <ol>
            <li>
              Дневник/блог - территория его владельца. В своих блогах вы имеете
              право устанавливать рамки допустимого поведения.
            </li>
            <li>
              На сайте предусмотрены инструменты приватности и фильтрации
              контента, которые постепенно расширяются. Используйте их для
              создания комфортного для себя окружения.
            </li>
            <li>
              Постарайтесь относиться уважительно друг к другу. Будьте
              котиками™.
            </li>
          </ol>
        </Wrapper>
      </SectionNarrowBlock>
    </ContentWrap>
  );
};

export default Terms;
