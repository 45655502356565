export function getUniqueOrderedEntryIds(notifications) {
  const addedEntries = {};
  const uniqueOrderedIds = [];

  for (const notification of notifications) {
    if (!addedEntries[notification.entry]) {
      uniqueOrderedIds.push(notification.entry);
      addedEntries[notification.entry] = true;
    }
  }
  return uniqueOrderedIds;
}
