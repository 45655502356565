import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`;

export const GroupedFieldsRow = styled.div`
  display: flex;
  column-gap: 20px;
`;

//Always use 'as' for it
export const AnimatedConditionalComponent = styled.div`
  animation: ${fadeIn} 0.5s;
`;

export const GroupedFields = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-top: 10px;
  row-gap: ${({ gap }) => (gap && gap === 'small' ? '10px' : '0px')};
`;

export const Label = styled.p`
  color: ${p => p.theme.textLight};
  height: 30px;
  padding: 6px 0px 10px 0px;
`;

export const SidebarFormWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 30px 50px;
  font-size: 14px;

  @media (max-width: 800px) {
    padding: 30px 30px;
  }
`;

export const SidebarErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.textLighter};
  background-color: ${props => props.theme.brand};
  font-size: 14px;
  transition: height 0.3s, margin 0.6s;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;

  ${props =>
    props.show
      ? `opacity: 1;
    height: auto;
    padding: 5px;
    margin-bottom: 20px;
    `
      : ''};
`;

export const TabContent = styled.div`
  display: ${({ active }) => (active ? 'initial' : 'none')};
`;
