import { useEntryEditContext } from 'scenes/Blog/EntryEdit/EntryEditContext';
import moment from 'moment';
import processHtmlToReact from 'utils/helpers/processHtml';
import { EntryEditButtonGroupContainer } from 'scenes/Blog/EntryEdit/EntryEditButtonGroup';
import React from 'react';

export function PreviewEntry({ eid, onPublish, blog }) {
  const {
    previewContent,
    entryTitle,
    tags,
    setPreview
  } = useEntryEditContext();

  return (
    <div
      className={`blog-entry blog-entry--no-author blog-entry--no-blog-link`}
    >
      <div className="blog-entry-meta--up ">
        <div className="blog-entry-date">
          {moment().format('HH:mm DD.MM.YYYY')}
        </div>
        <div
          className={`blog-entry-title ${
            entryTitle ? '' : 'blog-entry--no-title'
          }`}
        >
          {entryTitle}
        </div>
      </div>

      <div className="blog-entry-content">
        {processHtmlToReact(previewContent)}
      </div>

      {tags?.length > 0 && (
        <div className="blog-entry-tags">
          {tags.map((tag) => (
            <span key={tag}>#{tag}</span>
          ))}
        </div>
      )}

      <EntryEditButtonGroupContainer
        onPublish={() => onPublish(previewContent)}
        onTogglePreview={() => setPreview(false)}
        eid={eid}
        blog={blog}
      />
    </div>
  );
}
