import React from 'react';

const Icon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={`icon icon-pin ${className}`}>
    <path
      className="pin main-outline"
      d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z"
    />
  </svg>
);

export default Icon;
