import React from 'react';

export const NewPostIcon = ({ className }) => (
  <svg
    viewBox="0 0 30 30"
    className={`icon icon-new-post ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="square-fill filling-area"
      d="M 9.2738363,0 C 4.1506959,0 0,4.1506996 0,9.2738396 V 19.72617 C 0,24.84931 4.1506959,29 9.2738363,29 H 19.726162 C 24.849304,29 29,24.84931 29,19.72617 V 9.2738396 C 29,4.1506996 24.849304,0 19.726162,0 Z"
    />
    <path
      className="square-outline filling-outline"
      d="M 9.2738367,0 C 4.1506959,0 0,4.1507 0,9.27384 V 19.72617 C 0,24.84931 4.1506959,29 9.2738367,29 H 19.726162 C 24.849304,29 29,24.84931 29,19.72617 V 9.27384 C 29,4.1507 24.849304,0 19.726162,0 Z m 0,1.68622 H 19.726162 c 4.218176,0 7.587722,3.36945 7.587722,7.58762 v 10.45233 c 0,4.21817 -3.369546,7.58772 -7.587722,7.58772 H 9.2738367 c -4.2181754,0 -7.5877199,-3.36955 -7.5877199,-7.58772 V 9.27384 c 0,-4.21817 3.3695445,-7.58762 7.5877199,-7.58762 z"
    />
    <path
      className="plus-sign main-outline"
      d="m 14.153233,5.09756 c -0.2216,0 -0.400391,0.17684 -0.400391,0.39844 v 8.16406 H 5.5887794 c -0.2216001,0 -0.4003907,0.17879 -0.4003907,0.4004 v 0.88671 c 0,0.2216 0.1787906,0.40039 0.4003907,0.40039 h 8.1640626 v 8.16211 c 0,0.2216 0.178791,0.40039 0.400391,0.40039 h 0.886719 c 0.2216,0 0.40039,-0.17879 0.40039,-0.40039 v -8.16211 h 8.16211 c 0.2216,0 0.40039,-0.17879 0.40039,-0.40039 v -0.88671 c 0,-0.22161 -0.17879,-0.4004 -0.40039,-0.4004 h -8.16211 V 5.496 c 0,-0.2216 -0.17879,-0.39844 -0.40039,-0.39844 z"
    />
  </svg>
);
