import css from 'components/Shared/Entry/BlogEntryAvatar.module.css';
import AvatarWithMenu from 'components/Shared/AvatarWithMenu';
import { Link } from '@reach/router';
import JournalIcon from 'styles/Icons/Journal';
import { BirthdayIcon } from 'scenes/Dybr/Users/BirthdayIcon';
import React from 'react';
import { classNames } from 'utils/helpers/classNames';

export function getBlogEntrySubAuthorCss(feedPage = false) {
  return `
  .blog-entry-sub-author {
    margin-top: ${feedPage ? '28px' : '10px'};
  }
  .blog-entry-author.blog-entry-sub-author a::before {
    border: none;
  }`;
}

export function BlogEntryAuthor({
  entry,
  isCommunity,
  profile,
  slug,
  isBlogPage
}) {
  if (!isBlogPage && isCommunity && entry.community?.id !== profile.id) {
    const community = entry.community;

    return (
      <>
        <div className={css.communityAvatarWrapper}>
          <AvatarWithMenu className="blog-entry-avatar" profile={community} />

          <AvatarWithMenu
            className={css.communityAvatarAuthor}
            profile={profile}
            subAvatar={true}
          />
        </div>

        <div className="blog-entry-author-wrapping">
          <div className="blog-entry-blog">
            <Link
              to={`/blog/${community.blogSlug}`}
              title={community.blogTitle}
            >
              <JournalIcon />
            </Link>
          </div>

          <div className="blog-entry-author">
            <Link to={`/profile/${community.id}`}>{community.nickname}</Link>
            <BirthdayIcon
              birthday={community.birthday}
              nick={community.nickname}
            />
          </div>

          <div
            className={classNames({
              'blog-entry-author': true,
              'blog-entry-sub-author': true
            })}
          >
            <Link to={`/profile/${profile.id}`}>{profile.nickname}</Link>
            <BirthdayIcon birthday={profile.birthday} nick={profile.nickname} />
          </div>
          {profile.settings?.subtext && (
            <div className="blog-entry-subtext">
              <span>{profile.settings.subtext}</span>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <AvatarWithMenu className="blog-entry-avatar" profile={profile} />

      <div className="blog-entry-author-wrapping">
        <div className="blog-entry-blog">
          <Link to={`/blog/${slug}`} title={profile.blogTitle}>
            <JournalIcon />
          </Link>
        </div>

        <div className="blog-entry-author">
          <Link to={`/profile/${profile.id}`}>{profile.nickname}</Link>
          <BirthdayIcon birthday={profile.birthday} nick={profile.nickname} />
        </div>
        {profile.settings?.subtext && (
          <div className="blog-entry-subtext">
            <span>{profile.settings.subtext}</span>
          </div>
        )}
      </div>
    </>
  );
}
