import { selectNotificationsTotal } from 'store/notifications/selectors';
import { markAllNotificationsRead } from 'store/notifications/operations';
import { connect } from 'react-redux';
import { ResetButtonArea } from './styled';
import { SecondaryButton } from 'components/Shared/Buttons';
import React from 'react';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';

function ResetAllNotifications({
  notificationsTotal,
  markAllNotificationsRead
}) {
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  if (notificationsTotal > 0) {
    return (
      <ResetButtonArea>
        <SecondaryButton
          onClick={() => markAllNotificationsRead({ token, activeProfileId })}
          title="Отметить все уведомления прочитанными"
        >
          сбросить уведомления
        </SecondaryButton>
      </ResetButtonArea>
    );
  } else {
    return null;
  }
}

export const ResetAllNotificationsContainer = connect(
  (state) => ({
    notificationsTotal: selectNotificationsTotal(state)
  }),
  {
    markAllNotificationsRead
  }
)(ResetAllNotifications);
