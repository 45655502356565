import { query } from './Query';

export const getNewNotificationsApi = async ({ profileId, token }) => {
  const queryParams = {
    'filters[state]': 'new',
    'page[number]': 1,
    'page[size]': 1000,
    sort: '-created-at',
    include: 'comments,profiles,messages'
  };
  try {
    const res = await query({
      method: 'GET',
      resource: `profiles/${profileId}/notifications`,
      token,
      queryParams
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.error('getNewNotificationsApi', err);

    return { error: 'что-то пошло не так', data: [], ...err.response };
  }
};

export const updateNotificationAPI = async ({ profileId, data, token }) => {
  try {
    let res = await query({
      method: 'PATCH',
      resource: `profiles/${profileId}/notifications`,
      queryParams: data, // should have id
      token,
      id: data.id
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('updateNotification', err);
    return { error: 'что-то пошло не так' };
  }
};

// export const markNotificationReadAPI = async (id, profileId) => {
//   updateNotificationAPI(profileId, { id, state: 'read' });
// };

export const markAllNotificationsReadAPI = async ({ profileId, token }) => {
  try {
    let res = await query({
      method: 'POST',
      resource: `profiles/${profileId}/relationships/notifications/read-all`,
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('markAllRead', err);
    return { error: 'что-то пошло не так' };
  }
};
