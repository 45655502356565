import styled from 'styled-components';

export const HeaderGeneral = styled.div`
  background: ${(p) => p.theme.backgroundLighter};
  text-align: center;

  h1 {
    text-transform: uppercase;
    font-size: 28px;
    font-family: ${(p) => p.theme.headerFont};
    width: 100%;
    text-align: center;
    padding: 82px 10px ${(p) => p.paddingBottom ?? '82px'} 10px;
    color: ${(p) => p.theme.text};

    @media (max-width: 600px) {
      padding: 30px 10px;
    }
  }
`;
