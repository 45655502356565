import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { hexToRgba } from 'utils/helpers/colors';
import moment from 'moment';

import { HOSTNAME } from 'configs/dybr';
import processHtmlToReact from 'utils/helpers/processHtml';

import NextArrowIcon from 'styles/Icons/SimpleArrowRightWithBackground';
import PrevArrowIcon from 'styles/Icons/SimpleArrowLeftWithBackground';
import { ButtonLinkGray } from 'components/Shared/Buttons';
import Loading from 'components/Shared/Loading';
import { getNewsEntriesApi } from 'api/entries';

const Wrapper = styled.div`
  width: 100%;

  h2 a {
    color: ${(p) => p.theme.text} !important;
    text-decoration: none;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const ArticleWrapper = styled.div`
  flex-grow: 2;
  padding: 10px 30px;
  text-align: left; /*affects button position, not the text*/
  order: 0;
  position: relative;

  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
    padding: 10px 20px;
  }
  img {
    max-width: 100%;
  }

  .fade-text {
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 60px;

    background: -moz-linear-gradient(
      top,
      ${(p) => hexToRgba(p.theme.backgroundLighter, 0)} 0%,
      ${(p) => hexToRgba(p.theme.backgroundLighter, 1)} 100%
    );
    background: -webkit-linear-gradient(
      top,
      ${(p) => hexToRgba(p.theme.backgroundLighter, 0)} 0%,
      ${(p) => hexToRgba(p.theme.backgroundLighter, 1)} 100%
    );
    background: linear-gradient(
      to bottom,
      ${(p) => hexToRgba(p.theme.backgroundLighter, 0)} 0%,
      ${(p) => hexToRgba(p.theme.backgroundLighter, 1)} 100%
    );
  }
`;
const Article = styled.div`
  height: ${(p) => p.height || '300px'};
  overflow: hidden;

  h4 {
    text-align: left;
    padding: 10px 0;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  h5 {
    font-weight: normal;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  p.news {
    text-align: justify;
  }

  ol ol ul,
  ol ul ul,
  ul ol ul,
  ul ul ul {
    list-style-type: square;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ul,
  ol {
    margin-left: 40px;
  }
`;

const Arrow = styled.button`
  position: relative;
  top: -50px;
  background-color: transparent;
  overflow: hidden;
  border: none;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  background-size: contain;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (max-width: 800px) {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    order: -1;
    margin: 0 10px;
    top: 0;
  }

  /* new stuff */
  .icon-simple-arrow-left-with-background,
  .icon-simple-arrow-right-with-background {
    height: 50px;
    width: 50px;

    @media (max-width: 800px) {
      width: 40px;
      height: 40px;
    }
  }

  .circle.main-outline {
    fill: ${(p) => p.theme.backgroundIcon};
  }
  .arrow.main-outline {
    fill: ${(p) => p.theme.backgroundLighter};
  }
`;

export default class DybrNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      loading: true
    };
  }

  componentDidMount = async () => {
    try {
      const { data: news } = await getNewsEntriesApi();
      this.setState({
        news,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: 'Сервер Дыбра временно отключен, ведём важные работы!',
        loading: false
      });
    }
  };

  nextAticle = () => {
    const nextIndex =
      this.state.activeIndex === this.state.news.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  prevArticle = () => {
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.news.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  renderNews() {
    const { news } = this.state;
    if (!news || !news.length) return null;
    const article = news[this.state.activeIndex];

    const content = processHtmlToReact(article.content);

    return (
      <Wrapper>
        <h2 data-testid="dybr-news">
          <a href={HOSTNAME + '/blog/dybr'}>Дыброновости</a>
        </h2>
        <NewsWrapper>
          <Arrow prev onClick={this.prevArticle}>
            <PrevArrowIcon />
            Предыдущая
          </Arrow>
          <ArticleWrapper>
            <Article height={this.props.height}>
              <h5>{moment(article.createdAt).format('DD.MM.YYYY')}</h5>
              <h4>{article.title}</h4>
              <div className="news">{content}</div>
            </Article>
            <div className="fade-text" />
            <Link to={`/blog/dybr/${article.id}`}>
              <ButtonLinkGray>Дальше</ButtonLinkGray>
            </Link>
          </ArticleWrapper>
          <Arrow next onClick={this.nextAticle}>
            <NextArrowIcon />
            Следующая
          </Arrow>
        </NewsWrapper>
      </Wrapper>
    );
  }

  render() {
    let content;
    if (this.state.loading) content = <Loading />;
    else if (this.state.error)
      content = (
        <h1
          style={{
            textAlign: 'center',
            margin: 'auto'
          }}
        >
          {this.state.error}
        </h1>
      );
    else content = this.renderNews();

    return <Wrapper>{content}</Wrapper>;
  }
}
