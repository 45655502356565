import React from 'react';
import { EntryWithCommentsWrapper } from '../styled';
import NotificationDetailsForEntry from './NotificationDetailsForEntry';

function GroupedNotificationsForEntry({
  entries,
  notifications,
  commentsGroupedByEntryId
}) {
  const idsOfEntriesWithMentionInPost = notifications
    .filter(notification => !notification.comments)
    .filter(notification => notification.type === 'mention')
    .map(notification => notification.entry);

  if (!entries.length || !notifications.length) {
    return (
      <EntryWithCommentsWrapper>
        <div style={{ textAlign: 'center', width: '100%' }}>
          нет непрочитанных
        </div>
      </EntryWithCommentsWrapper>
    );
  }
  return entries.map(entry => {
    const comments = commentsGroupedByEntryId[entry.id];
    const entryHasMention = idsOfEntriesWithMentionInPost.includes(entry.id);
    return (
      <NotificationDetailsForEntry
        key={entry.id}
        entry={entry}
        entryHasMention={entryHasMention}
        comments={comments}
      />
    );
  });
}

export default GroupedNotificationsForEntry;
