import {
  Container,
  Content,
  ContentContainer
} from '../../../styles/blog/BlogLayout/StyledBlocks';
import BlogComment from '../../../components/BlogComponents/BlogComment';
import { CommentEditFormContainer } from '../../Blog/BlogEntryPage/CommentEditForm';
import {
  createMessageApi,
  getMessagesInChatApi
} from '../../../api/private-messages';
import React, { createRef, useEffect, useState } from 'react';
import defaultBlogDesign from '../../../configs/defaultBlogDesign';
import { useEntryPageContext } from '../../Blog/BlogEntryPage/EntryPageContext';
import PaginationWrapper from './PaginationWrapper';
import { useTokenState } from '../../../store/localStorage/useTokenState';
import queryString from 'query-string';
import WithActiveProfileId from '../../../store/localStorage/WithActiveProfileId';
import { selectUserSettingsAugmented } from '../../../store/user/selectors/selectUserSettingsAugmented';
import { selectActiveProfileAugmented } from '../../../store/user/selectors/selectActiveProfileAugmented';
import { readMessagesNotifications } from '../../../store/notifications/operations/readMessagesNotifications';
import { connect } from 'react-redux';

function ConversationMessages({
  ownProfile,
  targetProfileId,
  settings,
  readMessagesNotifications
}) {
  const theme = defaultBlogDesign;
  const { insertReply } = useEntryPageContext();

  // TODO: can be a custom hook
  const parsed = queryString.parse(location.search);
  const page = Number(parsed.page || parsed.p) || 1;

  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [editingMessage, setEditingMessage] = useState(null);
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  const pageSize = settings.pagination.comments;

  const topRef = createRef();

  useEffect(() => {
    if (isLoggedIn) {
      loadContent();
    }
  }, [targetProfileId, ownProfile.id, page]);

  async function loadContent() {
    const res = await reloadMessages();
    await readNotifications(res.data);
  }

  async function reloadMessages() {
    try {
      const res = await getMessagesInChatApi({
        ownProfileId: ownProfile.id,
        targetProfileId: targetProfileId,
        token,
        pageSize: pageSize,
        pageNumber: page
      });
      setMessages(res.data);
      setTotalMessages(res.total);
      return res;
    } catch (error) {
      console.error('Failed to reload messages:', error);
    } finally {
      setLoadingMessages(false);
    }
  }

  async function readNotifications(messages) {
    const messageIds = messages
      .filter(message => message.status === 'new')
      .map(message => message.id);

    readMessagesNotifications({
      messageIds,
      token,
      activeProfileId: ownProfile.id
    });
  }

  return (
    <div data-testid="blog" className="blog-page" ref={topRef}>
      <Container className={'blog-container ' + theme.layout.align}>
        <ContentContainer className="blog-content-container">
          <Content className="blog-content">
            <PaginationWrapper
              url={`/direct/${targetProfileId}?page=`}
              page={page}
              pageSize={pageSize}
              scrollToRef={topRef}
              totalRecords={totalMessages}
            >
              {messages.map(message => {
                const ownMessage = ownProfile.id === message.sender.id;
                return (
                  <BlogComment
                    // scrollIntoView={toMessage === message.id && scroll}
                    // onScrollIntoView={() => setScroll(false)}
                    key={message.id}
                    comment={{ ...message, profile: message.sender }}
                    url={`${location.pathname}#${message.id}`}
                    canDelete={false}
                    // onDelete={deleteMessage}
                    canEdit={false}
                    hasUserFunctions={isLoggedIn}
                    handleReply={() =>
                      insertReply(
                        message.id,
                        message.sender.id,
                        message.sender.nickname
                      )
                    }
                    // onEdit={messageId => {
                    //   const messageToEdit = messages.find(
                    //     c => c.id === messageId
                    //   );
                    //   setEditingMessage(messageToEdit);
                    //   handleMessageEdit(messageToEdit.content);
                    // }}
                  />
                );
              })}
            </PaginationWrapper>
            {isLoggedIn && (
              <CommentEditFormContainer
                commentDraft={null}
                onPublishFinished={async () => {
                  setEditingMessage(null);
                  reloadMessages();
                }}
                onUpdateComment={content => {}}
                onCreateComment={content =>
                  createMessageApi({
                    content,
                    ownProfileId: ownProfile.id,
                    targetProfileId: targetProfileId,
                    token
                  })
                }
                onSaveDraftComment={content => {}}
                navigateTo={messageId => `${location.pathname}#${messageId}`}
                sendButtonLabel="отправить сообщение"
              />
            )}
            {!isLoggedIn && (
              <div className="blog-info-block">
                Войдите, чтобы отправить сообщение.
              </div>
            )}
          </Content>
        </ContentContainer>
      </Container>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectUserSettingsAugmented(state),
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  readMessagesNotifications
};

export const ConversationMessagesContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(ConversationMessages)
);
