import React from 'react';

const Icon = ({ className }) => (
  <svg
    viewBox="0 0 24.754144 15.123782"
    className={`icon icon-watch-thread ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="eyelid main-outline"
      d="M 12.376953,0 C 9.1256955,0 6.1855414,1.6507385 4.0175781,3.2832031 1.8496148,4.9156677 0.44273357,6.5595105 0.3046875,6.7226562 c -0.4061028,0.4800514 -0.4049031,1.1985864 0,1.6777344 0.13803852,0.1631363 1.5449273,1.8089424 3.7128906,3.4414064 2.1679633,1.632464 5.1081174,3.28125 8.3593749,3.28125 3.251255,0 6.189459,-1.648786 8.357422,-3.28125 2.167963,-1.632464 3.576873,-3.2783562 3.714844,-3.4414064 l 0.002,-0.00195 c 0.372272,-0.4442746 0.405959,-1.0950591 0.07227,-1.5761719 h 0.002 C 24.396474,6.6353782 23.224433,4.9728806 21.150391,3.3144531 19.076348,1.6560257 16.08028,0 12.376953,0 Z m 0,1.6347656 c 3.214355,0 5.864969,1.4466163 7.753906,2.9570313 1.766531,1.4125371 2.675108,2.7079036 2.851563,2.9550781 -0.226766,0.2623759 -1.341646,1.5660081 -3.230469,2.988281 -2.01638,1.518324 -4.690316,2.953125 -7.375,2.953125 -2.6846863,0 -5.3586196,-1.434802 -7.3749999,-2.953125 C 3.1226075,9.1200194 2.0234115,7.8355175 1.7871094,7.5625 2.0233673,7.2895318 3.1225688,6.0030568 5.0019531,4.5878906 7.0183334,3.0695672 9.6922667,1.6347656 12.376953,1.6347656 Z"
    />
    <path
      className="eyeball filling-area"
      d="M 12.376953 3.7089844 C 10.251553 3.7089844 8.5234375 5.437108 8.5234375 7.5625 C 8.5234375 9.6879 10.251553 11.416016 12.376953 11.416016 C 14.502347 11.416016 16.230469 9.6879 16.230469 7.5625 C 16.230469 7.1998826 16.164325 6.854381 16.070312 6.5214844 A 1.3251508 1.3251508 0 0 1 14.753906 7.7011719 A 1.3251508 1.3251508 0 0 1 13.427734 6.3769531 A 1.3251508 1.3251508 0 0 1 14.753906 5.0527344 A 1.3251508 1.3251508 0 0 1 15.435547 5.2441406 C 14.731159 4.3172102 13.628598 3.7089844 12.376953 3.7089844 z"
    />
  </svg>
);
export default Icon;
