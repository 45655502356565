import { query } from 'api/Query';

export const testCrosspostApi = async ({ connectionSettings, token }) => {
  try {
    return await query({
      method: 'POST',
      resource: 'custom-api/crosspost-test',
      type: 'crosspost-credentials',
      queryParams: {
        ...connectionSettings
      },
      token,
      withNonce: false
    });
  } catch (err) {
    console.error('testCrosspostApi', err);

    return { error: 'не удалось проверить', ...err.response };
  }
};

export const saveCrosspostCredentialsApi = async ({
  host,
  user,
  password,
  token
}) => {
  try {
    return await query({
      method: 'POST',
      resource: 'crosspost',
      queryParams: { user, password, host },
      token
    });
  } catch (err) {
    console.error('POST сrosspostApi', err);

    return { error: 'не удалось сохранить', ...err.response };
  }
};

export const deleteCrosspostCredentialsAPI = async ({ host, token }) => {
  try {
    return await query({
      method: 'DELETE',
      resource: `crosspost/${host}`,
      token
    });
  } catch (err) {
    console.error('testCrosspostApi', err);

    return { error: 'не удалось проверить', ...err.response };
  }
};
