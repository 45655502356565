import { query } from './Query';
/*
получить одну закладку
GET /v2/bookmarks/5?include=entry
получить все свои закладки
GET /v2/bookmarks/?include=entry&include=profile
DELETE http://localhost:8080/v2/bookmarks/5
POST http://localhost:8080/v2/bookmarks  with: {
    "data": {
        "type": "bookmarks",
        "attributes": {},
        "relationships": {
            "entry": {
                "data": {
                    "type": "entries",
                    "id": "9472"
                }
            }
        }
    }
}*/

export const getBookmarksApi = async ({ pageSize, pageNumber, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `bookmarks`,
      queryParams: {
        include: 'entry,profile,community',
        sort: '-created-at',
        'page[size]': pageSize,
        'page[number]': pageNumber
      },
      token
    });
    return { status: 'ok', data: res.data, meta: res.meta };
  } catch (err) {
    console.log('getBookmarks', err);
    return { error: 'что-то пошло не так' };
  }
};

export const addBookmarkApi = async ({ entryId, token }) => {
  try {
    await query({
      method: 'POST',
      resource: `bookmarks`,
      queryParams: {
        entry: { id: entryId, type: 'entries' },
        relationshipNames: ['entry']
      },
      token
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('addToBookmarks', err);
    return { error: 'что-то пошло не так' };
  }
};

export const removeBookmarkApi = async ({ entryId, token }) => {
  try {
    await query({ method: 'DELETE', resource: `bookmarks/${entryId}`, token });
    return { status: 'ok' };
  } catch (err) {
    console.log('removeFromBookmarks', err);
    return { error: 'что-то пошло не так' };
  }
};
