import { changeRgbaAlpha } from 'utils/helpers/colors';

export default ({ colors, header, fonts }) => `
 .blog-entry.entry-form {
    padding: 40px;
    margin: 20px 0;
  }
  @media(max-width: 700px){
    .blog-entry.entry-form {
      padding: 20px;
    }
  }
  @media(max-width: 500px){
    .blog-entry.entry-form {
      padding: 10px;
    }
  }

  .entry-form .blog-entry-avatar
  {
    padding-left: 0px;
  }


  .edit-entry-form-buttons {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  .edit-entry-form-buttons-preview {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media(max-width: 700px){
    .edit-entry-form-buttons-preview {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media(max-width: 500px){
    .edit-entry-form-buttons-preview {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .edit-entry-form-buttons span {
    text-align: right;
    padding-top: 20px;
    flex-grow: 0;
  }
  .edit-entry-form-main-buttons {
    margin-bottom: 30px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
  }
  .edit-entry-form-main-buttons button {
    margin-bottom: 10px;
  }
  .edit-entry-form-secondary-buttons {

  }

  .edit-entry-form-buttons .edit-entry-form-main-buttons button:first-child {
    margin-right: 10px;
  }

  .edit-entry-form-buttons button {
    padding: 15px 30px;
    font-weight: bold;
    border: none;
    border-radius: 0px;
    text-transform: lowercase;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .edit-entry-form-buttons button,
  .edit-entry-form-buttons button:hover,
  .edit-entry-form-buttons button:active  {
    background: ${colors.buttons};
    color: ${changeRgbaAlpha(colors.blocks, 1)};
  }

  @media (max-width: 450px) {
    .edit-entry-form-main-buttons, .edit-entry-form-secondary-buttons {
      width: 100%;
    }
    .edit-entry-form-buttons button {
      width: 100%;
      font-size: 15px;

    }
    .edit-entry-form-buttons .edit-entry-form-main-buttons button:first-child {
      margin-right: 0;
    }

  }


  .edit-entry-form-buttons .add-entry-btn {
    background: ${colors.buttons};
    color: ${changeRgbaAlpha(colors.blocks, 1)};
  }

  .edit-entry-form-buttons .save-entry-draft-btn,
  .edit-entry-form-buttons .preview-entry-btn {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
    color: ${colors.buttons};
    border: 2px solid ${colors.buttons};
    padding: 13px 28px;
  }

  .edit-entry-form-buttons .delete-entry-btn,
  .edit-entry-form-buttons .delete-entry-btn:hover,
  .edit-entry-form-buttons .delete-entry-btn:active {
    background: ${colors.headers};
  }

  .edit-entry-form-buttons button:disabled {
    cursor: not-allowed;
  }

  .entry-form .fr-element ul,
  .entry-form .fr-element ol {
    margin-left: 40px;
  }

  .edit-entry-form-buttons .entry-saved-message {
    display: none;
  }

  /* autosave  */
  .edit-entry-form-buttons .save-entry-draft-btn {
    display: none;
  }

  .edit-entry-form-buttons .entry-saved-message {
    display: block;
    margin-left: 20px;
    font-weight: bold;
    color: ${colors.headers};
  }

  .blog-entry.entry-form .text-field {
    flex-wrap: nowrap;
    display: flex;
    padding-top: 15px;
    position: relative;
    width: 100%;
    margin-bottom: 40px;
    color: ${colors.headers};
    font-family: ${fonts.headers.family};
    font-style: ${fonts.headers.style};
    font-weight: ${fonts.headers.weight};
    text-decoration: ${fonts.headers.textDecoration};
    font-size: ${fonts.headers.size}px;
  }

  .blog-entry.entry-form .text-field label {
    position: absolute;
    transform: translate(0, -3px);
    font-size: 1em;
    pointer-events: none;
    transition: 0.2s;
    color: ${colors.meta};
  }

  .blog-entry.entry-form .text-field input {
    color: ${colors.headers};
    font-family: ${fonts.headers.family};
    font-style: ${fonts.headers.style};
    font-weight: ${fonts.headers.weight};
    text-decoration: ${fonts.headers.textDecoration};
    font-size: ${fonts.headers.size}px;
    height: auto;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid ${colors.meta};
    padding-bottom: 3px;
    background: transparent;
  }

  .blog-entry.entry-form .text-field input:focus + label {
    transform: translate(0, -1.3em);
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
  }


    .blog-entry.entry-form .text-field input.filled + label {
      transform: translate(0, -1.3em);
      font-size: 12px;
      font-family: Roboto;
      font-weight: normal;
    }

  .blog-entry.entry-form .text-field input:-webkit-autofill + label {
    transform: translate(0, -1.3em);
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
  }

  .blog-entry.entry-form .text-field input:hover,
  .blog-entry.entry-form .text-field input:focus {
    border-color:${colors.accent};
  }

  .tags-select {
    margin-top: 40px;
    border-bottom: 1px solid ${colors.meta};
    border-radius: 0px;
  }

  .privacy-settings, .custom-list-setting {
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-items: flex-start;
  }
  .privacy-settings-label, .settings-label {
    flex: 0 1 auto;
    width: 6em;
    min-width: 6em;
    font-size: ${fonts.entries.size * 0.9}px;
    margin-right: 20px;
    color: ${changeRgbaAlpha(colors.meta, 1)};
  }
  .privacy-setting-select {
    min-width: 240px;
    max-width: 240px;
    margin: 16px 0;
    width: 100%;
    border-top: 1px solid ${colors.meta};
    border-bottom: 1px solid ${colors.meta};
    border-radius: 0px;
    font-size: ${fonts.entries.size * 0.9}px;
  }
  .privacy-setting-select--is-focused {
    border-color: ${colors.accent}
  }

  .privacy-setting-select--main-list {
      margin-right: 50px;
  }
  .privacy-settings-selectors {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .custom-list-setting-item {
    display: flex;
    width: 100%;
    max-width: 240px;
    min-width: 240px;
  }

  .blog-select__control {
    border: 0;
    background: none;
    min-height: auto;
    box-shadow: none;
    border-radius: 0px;
  }
  .blog-select__placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
  }
  .blog-select__menu {
    border-radius: 0px;
    background-color: ${changeRgbaAlpha(colors.blocks, 1)};
    box-shadow: 0px 0px 6px 0px ${changeRgbaAlpha(colors.text, 0.3)};
  }
  .blog-select__menu-list {
    border-radius: 0px;
    padding: 0;
    background-color: ${changeRgbaAlpha(colors.blocks, 1)};
    color:  ${changeRgbaAlpha(colors.text, 1)};
  }
  .blog-select__option {
    font-size: ${fonts.entries.text * 0.9};
    transition: 0.2s;
    text-align: left;
    padding-left: 12px;
    transition: none;
  }
  .blog-select__option--is-selected, .blog-select__option--is-focused {
    background-color: ${changeRgbaAlpha(colors.text, 0.2)};
    color: ${colors.text};
    border-left: 8px solid ${colors.accent};
    padding-left: 5px;
  }
  .blog-select__option--is-focused {
    font-weight: bold;
  }

  .blog-select__dropdown-indicator {
    transition: 0.2s;
  }
  .blog-select__indicator-separator {
    display: none;
  }
  .blog-select__dropdown-indicator {
    color: ${changeRgbaAlpha(colors.meta, 0.8)}
  }

  .blog-select__dropdown-indicator:hover,
  .blog-select__control:hover .blog-select__dropdown-indicator {
    color: ${colors.meta};
    color: ${colors.text};
  }
  .blog-select__value-container--is-multi {
    padding-left: 0;
  }
  .blog-select__multi-value{
    background-color: ${changeRgbaAlpha(colors.text, 0.1)};
    color: ${colors.text};
  }
  .blog-select__multi-value__label{
    color: ${colors.text};

  }
  .blog-select__multi-value__remove svg {
    width: 0.75em;
    height: 0.75em;
    cursor: pointer;
  }
  .blog-select__multi-value__remove:hover {
    background-color: transparent;
    color: ${colors.buttons};
  }
  .blog-select__single-value {
    color:  ${colors.text};
  }
  .blog-select__input {
    color:  ${colors.text};

  }
  .blog-select__input input {
    font-family: ${fonts.entries.family};
  }
  .blog-select__placeholder {
    color: ${changeRgbaAlpha(colors.text, 0.8)};
  }

  .settings {
    display: flex;
  }
  .settings-checkboxes {
    display: flex;
    flex-wrap: wrap;
    font-size: ${fonts.entries.size * 0.9}px;
  }
  .settings-checkboxes div {
    margin-right: 2em;
    margin-bottom: 1em;

  }
  .blog-checkbox input {
    position: absolute;
    opacity: 0;
  }
  .blog-checkbox label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
  }

  .blog-checkbox label:before {
    content: '';
    margin-right: 1em;
    min-width: 1.3em;
    height: 1.3em;
    border: 1px solid ${colors.meta};
  }
  .blog-checkbox input:checked + label:before {
    border: 1px solid ${colors.headers};
  }
  .blog-checkbox input:checked + label:after {
    content: '';
    position: absolute;
    left: 0.7em;
    top: -0.7em;
    width: 0.7em;
    height: 1.7em;
    border-radius: 2px;
    border-bottom: 3px solid ${colors.headers};
    border-right: 3px solid ${colors.headers};
    transform: rotate(45deg);
  }

`;
