import React from 'react';
import { connect } from 'react-redux';
import {
  banProfile,
  unbanProfile,
  unhideProfile
} from 'store/lists/operations';
import { selectIsOwnProfile } from 'store/user/selectors/selectIsOwnProfile';
import { BlockUserDisplay, IconButton, Wrapper } from './Styled';
import ProfileIcon from 'styles/Icons/Profile';
import JournalIcon from 'styles/Icons/Journal';
import { NicknameUnderlinedBig } from 'components/DybrComponents/NicknameDisplay';
import BlogDisplayContainer from 'components/Shared/BlogDisplayContainer';
import { BlockUserIcon } from 'styles/Icons/BlockUser';
import { selectProfileIsBanned } from 'store/lists/selectors/selectProfileIsBanned';
import { selectProfileIsHidden } from 'store/lists/selectors/selectProfileIsHidden';
import { useTokenState } from 'store/localStorage/useTokenState';
import MessageEnvelope from '../../../styles/Icons/MessageEnvelope';

function AvatarMenu({
  profile,
  isOwnProfile,
  isBanned,
  isHidden,
  ban,
  unban,
  showInFeed
}) {
  const [token] = useTokenState();
  const isUserLoggedIn = Boolean(token);

  const toggleBan = () => {
    isBanned ? unban({ profile, token }) : ban({ profile, token });
  };

  return (
    <Wrapper>
      <ul className="icon-positioning">
        <li>
          <IconButton href={`/profile/${profile.id}`} title="профиль">
            <ProfileIcon />
          </IconButton>
        </li>
        <li>
          <IconButton href={`/blog/${profile.blogSlug}`} title="блог">
            <JournalIcon />
          </IconButton>
        </li>
        <li>
          <IconButton href={`/direct/${profile.id}`} title="личные сообщения">
            <MessageEnvelope />
          </IconButton>
        </li>
        {/* <li>
          <IconButton
            href={`/blog/${profile.blogSlug}/favorites`}
            title="избранное"
          >
            <FavoritesIcon />
          </IconButton>
        </li> */}
      </ul>
      <NicknameUnderlinedBig profile={profile} />

      {/*<ListsDisplay>
            <ul className="list-positioning">
              <li>в списках:</li>
              <li>friend</li>
              <li>work</li>
              <li>jrock</li>
            </ul>
            <Button href={`${blog.url}`} className="menu-add-to-list">
              добавить в список
            </Button>
          </ListsDisplay>*/}

      <BlogDisplayContainer profile={profile} />

      {isUserLoggedIn && (
        <BlockUserDisplay>
          {!isOwnProfile && (
            <IconButton
              className="block-user-link"
              onClick={toggleBan}
              title={
                isBanned
                  ? 'пользователь не видит ваши записи'
                  : 'пользователь не будет видеть ваши записи'
              }
            >
              <BlockUserIcon />
              <span>
                {isBanned
                  ? 'разблокировать пользователя'
                  : 'заблокировать пользователя'}
              </span>
            </IconButton>
          )}
        </BlockUserDisplay>
      )}
      {isHidden ? (
        <BlockUserDisplay>
          <IconButton
            className="show-in-feed"
            onClick={() => showInFeed({ profile, token })}
          >
            <span>вернуть в общую ленту</span>
          </IconButton>
        </BlockUserDisplay>
      ) : null}
    </Wrapper>
  );
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.profile.id;

  return {
    isBanned: selectProfileIsBanned(state, id),
    isHidden: selectProfileIsHidden(state, id),
    isOwnProfile: selectIsOwnProfile(state, { profileId: id })
  };
};

const mapDispatchToProps = {
  showInFeed: unhideProfile,
  ban: banProfile,
  unban: unbanProfile
  // hideFromFeedForAll: admin function, later
};

export const AvatarMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarMenu);
