import { UpdateProfileAction } from '../actions/UpdateProfileAction';
import cloneDeep from 'lodash/cloneDeep';

import produce from 'immer';
import keyBy from 'lodash/keyBy';
import { StoreUserDataAction } from '../actions/StoreUserDataAction';
import { SetActiveProfileIdAction } from 'store/user/actions/SetActiveProfileIdAction';
import { LogoutAction } from 'store/user/actions/LogoutAction';
import { defaultUserState } from 'store/_setup/initialState';
import { SetProfileAction } from 'store/user/actions/SetProfileAction';
import { SetUserSettingsAction } from 'store/user/actions/SetUserSettingsAction';
import { SetCommunitiesAction } from 'store/user/actions/SetCommunitiesAction';
import { LeaveCommunityAction } from 'store/user/actions/LeaveCommunityAction';
import { AddCommunityAction } from 'store/user/actions/AddCommunityAction';

/**
 *  structure:
 *
    userData: {
      createdAt: "2020-05-28T17:03:26Z",
      email: "bubnova.j.i2@gmail.com",
      ...
    }
    activeProfile: "20795",
    token: '',
    userId: 5230,
    settings: {
        pagination: {},
        disabledDesigns: ['1']
    },
    profiles: {
        "288": {
                id: "288"
                createdAt: "2018-02-25T17:47:54Z"
                isCommunity: false
                nickname: ""
                settings: {avatar: "/static/media/default_ava.9b2e26d5.jpg",…}
                updatedAt: "2018-09-10T18:35:07Z"
                meta: {entries: {public: 0}}
            }
        }

 ~~~
 previously it was:
 {
   token: '',
   profiles: [],
   activeProfile: null,
   settings: defaultUserSettings,
   loading: true,
   ...props
 }
 ~~~~
 *
 */

export const userReducer = produce((draft, action) => {
  switch (action.type) {
    case StoreUserDataAction.type:
      draft.user.userData = {
        createdAt: action.data.createdAt,
        updatedAt: action.data.updatedAt,
        email: action.data.email,
        isAdult: action.data.isAdult,
        termsOfService: action.data.termsOfService
      };

      draft.user.profiles = keyBy(action.data.profiles || [], 'id');
      draft.user.settings = action.data.settings;
      return;
    // case SetTokenAction.type: {
    //
    //   const { userId, activeProfileId } = parseToken(action.token);
    //   // todo this should be parsed from local storage
    //   draft.user.userId = userId;
    //   if (activeProfileId) {
    //     draft.user.activeProfile = activeProfileId;
    //   }
    //   return;
    // }
    case SetActiveProfileIdAction.type:
      draft.user.activeProfile = action.activeProfile;
      return;
    case SetProfileAction.type:
      draft.user.profiles[action.profile.id] = action.profile;
      return;
    case SetCommunitiesAction.type:
      draft.user.communities = {
        ...draft.user.communities,
        [action.profileId]: keyBy(action.communities || [], 'id')
      };
      return;
    case AddCommunityAction.type:
      draft.user.communities = {
        ...draft.user.communities,
        [action.profileId]: {
          ...draft.user.communities?.[action.profileId],
          [action.community.id]: action.community
        }
      };
      return;
    case LeaveCommunityAction.type: {
      if (
        !draft.user.communities ||
        !draft.user.communities[action.profileId]
      ) {
        return;
      }
      delete draft.user.communities[action.profileId][action.communityId];
      return;
    }
    case SetUserSettingsAction.type:
      draft.user.settings = action.settings;
      return;
    case UpdateProfileAction.type:
      draft.user.profiles[action.id] = {
        ...draft.user.profiles[action.id],
        ...action.data
      };
      return;
    case LogoutAction.type:
      draft.user = cloneDeep(defaultUserState);
      return;
    default:
      return;
  }
});
