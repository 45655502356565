import React from 'react';

export const BrushForUserStylesIcon = ({ className }) => (
  <svg
    viewBox="0 0 217 217"
    className={`icon icon-brush-for-user-styles ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="brush-tip filling-area"
      d="M128.801 5.76178L182.365 59.1569L176.548 74.8656L190.27 67.2278L210.759 87.717L148.912 148.737L66.956 66.78L128.801 5.76178Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="brush-tip-outline main-outline"
      d="M139.938 3.43081C135.544 -0.979729 128.408 -0.995994 123.994 3.39115L75.9758 51.1666L69.4593 57.6501L61.4634 65.6056L69.439 73.5812L143.205 147.724L151.424 155.943L213.675 93.5242C218.091 89.1303 218.112 81.9896 213.718 77.5729L202.454 66.265C198.638 62.4264 192.634 61.8398 188.147 64.8674L180.44 70.083L185.684 62.3029C188.699 57.8355 188.131 51.8598 184.33 48.0396L159.903 23.497C157.516 21.1042 154.182 19.9077 150.819 20.237C151.164 16.8756 149.983 13.5365 147.603 11.1386L139.938 3.43081ZM139.612 19.0993L131.948 11.3915L83.9534 59.1442L75.9758 67.0891L149.487 141.5L205.721 85.5268L194.463 74.2116L145.849 107.078C145.171 107.536 144.262 107.447 143.683 106.868C143.106 106.288 143.015 105.38 143.473 104.702L176.332 55.993L151.905 31.4503L133.281 40.3677C132.632 40.679 131.86 40.5462 131.347 40.0426L131.332 40.0278C130.831 39.5242 130.69 38.763 130.984 38.1147L139.612 19.0993Z"
    />
    <path
      className="brush-handle-outline main-outline"
      d="M49.5671 77.4062C45.151 81.8001 45.1303 88.9408 49.5235 93.3573L65.8803 109.787C68.2156 112.135 69.3503 115.39 68.9807 118.683C68.6116 121.972 66.7804 124.909 63.9751 126.687C53.094 133.586 40.9312 141.383 30.8483 148.064C25.8069 151.405 21.2889 154.455 17.6498 157.032C14.0106 159.61 11.6255 161.229 9.10906 163.733C-2.89925 175.682 -2.94869 195.442 9.00003 207.451C20.9492 219.459 40.7163 219.516 52.7248 207.569C55.2424 205.066 56.8736 202.684 59.4689 199.058C62.0644 195.431 65.1438 190.927 68.5094 185.903C75.2407 175.853 83.0866 163.731 90.0386 152.885C91.824 150.099 94.7672 148.284 98.0649 147.93C101.506 147.561 104.455 148.556 106.946 151.059L123.303 167.503C127.697 171.914 135.123 172.219 139.537 167.828L151.495 155.838L143.275 147.618L131.293 159.541L114.943 143.104C110.088 138.225 103.538 136.005 96.855 136.723C90.1717 137.44 84.1756 141.14 80.5488 146.799C66.6899 168.422 49.2343 195.13 44.7706 199.571C37.0828 207.222 24.6476 207.187 16.9972 199.492C9.34675 191.804 9.37409 179.377 17.0626 171.726C21.5259 167.285 48.3244 149.954 70.0162 136.201C75.6963 132.6 79.4366 126.629 80.1868 119.946C80.937 113.262 78.6207 106.596 73.8773 101.829L57.5205 85.399L69.5094 73.4756L143.275 147.618L151.495 155.838L159.5 147.93L69.3761 57.8058L49.5671 77.4062Z"
    />
    <path
      className="brush-hole main-outline"
      d="M42.5269 183.317C42.5269 184.48 42.2978 185.632 41.8526 186.707C41.4074 187.782 40.7549 188.758 39.9324 189.581C39.1098 190.404 38.1333 191.056 37.0585 191.501C35.9838 191.946 34.8319 192.175 33.6686 192.175C32.5054 192.175 31.3535 191.946 30.2787 191.501C29.204 191.056 28.2275 190.404 27.4049 189.581C26.5823 188.758 25.9299 187.782 25.4847 186.707C25.0395 185.632 24.8104 184.48 24.8104 183.317C24.8104 182.154 25.0395 181.002 25.4847 179.927C25.9299 178.853 26.5823 177.876 27.4049 177.053C28.2275 176.231 29.204 175.578 30.2787 175.133C31.3535 174.688 32.5054 174.459 33.6686 174.459C36.018 174.459 38.2711 175.392 39.9324 177.053C41.5936 178.715 42.5269 180.968 42.5269 183.317Z"
    />
  </svg>
);
