import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ArrowIconDown from 'styles/Icons/SimpleArrowDown';
import ArrowIconUp from 'styles/Icons/SimpleArrowUp';
import processHtmlToReact from 'utils/helpers/processHtml';
import { hexToRgba } from 'utils/helpers/colors';
import { useEventListener } from 'utils/hooks/useEventListener';

const Section = styled.div`
  background-color: ${p => p.theme.backgroundLight};
  margin-top: 40px;
  width: 100%;
  padding: 25px 0 0;
  position: relative;
  overflow: hidden;
  max-height: ${p => p.maxHeight}px;
  transition: all 0.5s;

  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const Block = styled.div`
  margin-bottom: 40px;
  @media (max-width: 600px) {
    flex-grow: 1;
    text-align: center;
  }
`;
const LastBlock = styled(Block)`
  flex-grow: 1;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  padding: 0 10px;
  background-color: ${p => p.theme.backgroundLight};
  display: inline-block;
  margin: 10px 50px 3px;
`;

const AboutBlock = styled.div`
  width: 100%;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const Content = styled.div`
  border-top: 1px solid ${props => props.theme.divider};
  padding: 6px 60px;

  ${AboutBlock} & {
    margin: -15px 0 0 0;
    padding: 30px 60px 40px;
    text-align: left;
  }
`;
const MoreBlock = styled.div`
  cursor: pointer;
  line-height: 12px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px 0;
  text-align: center;
  background: linear-gradient(
    to bottom,
    ${p => hexToRgba(p.theme.backgroundLight, 0)} 0%,
    ${p => hexToRgba(p.theme.backgroundLight, 0.7)} 40%,
    ${p => hexToRgba(p.theme.backgroundLight, 1)} 100%
  );
  background: -moz-linear-gradient(
    top,
    ${p => hexToRgba(p.theme.backgroundLight, 0)} 0%,
    ${p => hexToRgba(p.theme.backgroundLight, 0.7)} 40%,
    ${p => hexToRgba(p.theme.backgroundLight, 1)} 100%
  );
  background: -webkit-linear-gradient(
    top,
    ${p => hexToRgba(p.theme.backgroundLight, 0)} 0%,
    ${p => hexToRgba(p.theme.backgroundLight, 0.7)} 40%,
    ${p => hexToRgba(p.theme.backgroundLight, 1)} 100%
  );

  .main-outline {
    fill: ${p => p.theme.backgroundDarker};
  }
  .icon {
    height: 12px;
  }

  @media (max-width: 1200px) {
    margin-left: -15px;
  }
  @media (max-width: 600px) {
    margin-left: -20px;
  }
`;

const ProfileAboutSection = ({ profile }) => {
  // eslint-disable-next-line
  const contentRef = useRef(),
    maxContentHeightBeforeArrow = 300, // as Kora asked
    [collapsedHeight, setCollapsedHeight] = useState(
      maxContentHeightBeforeArrow
    ),
    [fullHeight, setFullHeight] = useState(collapsedHeight),
    [open, setOpen] = useState(false);

  const onMoreClick = () => setOpen(!open);

  const handleResize = useCallback(() => {
    const totalHeight =
      contentRef && contentRef.current
        ? contentRef.current.offsetHeight + contentRef.current.offsetTop
        : 0;
    if (contentRef.current)
      setCollapsedHeight(
        contentRef.current.offsetTop + maxContentHeightBeforeArrow
      );
    setFullHeight(totalHeight);
    setOpen(totalHeight < collapsedHeight);
  }, [collapsedHeight]);

  useEventListener('resize', handleResize);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  const isContentBig = fullHeight > collapsedHeight;
  const hasAbout = Boolean(profile.description);
  const hasBirthday = Boolean(profile.birthday);

  return (
    (hasAbout || hasBirthday) && (
      <Section
        maxHeight={
          (!open && isContentBig) || fullHeight === 0
            ? collapsedHeight
            : fullHeight
        }
      >
        {hasBirthday && (
          <Block>
            <Title>День рождения</Title>
            <Content>{moment(profile.birthday).format('D MMMM')}</Content>
          </Block>
        )}

        <LastBlock>
          <Title>Дата регистрации</Title>
          <Content>{moment(profile.createdAt).format('D MMMM YYYY')}</Content>
        </LastBlock>

        {hasAbout && (
          <AboutBlock>
            <Title> О себе </Title>
            <Content ref={contentRef} onLoad={handleResize}>
              {processHtmlToReact(profile.description)}
            </Content>
          </AboutBlock>
        )}
        {isContentBig && (
          <MoreBlock onClick={onMoreClick}>
            {open ? <ArrowIconUp /> : <ArrowIconDown />}
          </MoreBlock>
        )}
      </Section>
    )
  );
};
export default ProfileAboutSection;
