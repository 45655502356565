import { deleteDesignAPI } from 'api/designs';
import { DeleteDesignAction } from 'store/designer/actions';

export const deleteDesign = ({ designId, token, activeProfileId }) => async (
  dispatch
) => {
  const res = await deleteDesignAPI({
    designId,
    profileId: activeProfileId,
    token
  });

  if (!res.error) {
    dispatch(new DeleteDesignAction(designId));
  } else {
    // dispatch error
  }
};
