import styled from 'styled-components';

// TODO: remove, they are not universal

const Error = styled.span`
  font-size: 12px;
  color: ${props => props.theme.brand};
  position: relative;
  padding: 10px 0;
`;

export default Error;
