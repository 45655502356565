import React from 'react';
import { HOSTNAME } from 'configs/dybr';

class EntryMenu extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.inputRef) this.inputRef.select();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };
  setInputRef = node => {
    this.inputRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickAway();
    }
  };

  render() {
    return (
      <div ref={this.setWrapperRef} className="controls-dropdown-wrapper">
        <div className="controls-dropdown">
          {this.props.isUrlCopied && <span>{this.props.text}</span>}
          {!this.props.isUrlCopied && (
            <input
              ref={this.setInputRef}
              value={`${HOSTNAME}${this.props.url}`}
            />
          )}
        </div>
      </div>
    );
  }
}

export default EntryMenu;
