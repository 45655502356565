import React, { useEffect, useRef, useState } from 'react';
import { getArchiveStatus } from 'api/profiles';
import { useDropzone } from 'react-dropzone';
import RadioGroup from 'components/FormElements/RadioGroup';
import { API_URL } from 'configs/dybr';
import { createQueryString } from 'api/Query';

import Switch from 'components/FormElements/Switch';
import Select from 'components/FormElements/Select';
import TextField from 'components/FormElements/TextField';
import { ActionButton } from 'components/Shared/Buttons';
import {
  StyledArchiveUpload,
  StyledCustomPrivacySettings,
  StyledHint
} from 'scenes/User/Settings/Styled';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import UploadStatuses from 'scenes/User/Settings/Blog/ArchiveUpload/UploadStatuses';
import { useDropzoneStyle } from 'scenes/User/Settings/Blog/Dropzone.style';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

const PRIVACY_SETTINGS_OPTIONS = [
  {
    value: 'public',
    label: 'открыты'
  },
  {
    value: 'registered',
    label: 'открыты зарегистрированным'
  },
  {
    value: 'favorites',
    label: 'открыты избранному'
  },
  {
    value: 'private',
    label: 'закрыты от всех'
  }
];

const PRIVACY_SETTINGS_DIARY = [
  {
    value: 'public',
    label: 'открытые'
  },
  {
    value: 'registered',
    label: 'открытые для зарегистрированных'
  },
  {
    value: 'favorites',
    label: 'открытые для избранного'
  },
  {
    value: 'subscribers',
    label: 'открытые для постоянных читателей'
  },
  {
    value: 'white-list',
    label: 'открытые для белого списка'
  },
  {
    value: 'custom-list',
    label: 'открытые для кастомных списков'
  },
  {
    value: 'black-list',
    label: 'закрытые для кастомных списков'
  },
  {
    value: 'private',
    label: 'закрытые от всех'
  }
];

const PRIVACY_LEVELS = [
  {
    label: 'все записи закрыты от всех',
    value: 'private',
    radioValue: 'private'
  },
  {
    label: 'ранее открытые записи открыты всем, остальные закрыты',
    value: 'public',
    radioValue: 'public'
  },
  {
    label: 'настроить вручную',
    value: 'custom',
    radioValue: 'custom'
  }
];

const SETTING_OPEN = {
  public: 'public',
  registered: 'registered',
  favorites: 'private',
  subscribers: 'private',
  'white-list': 'private',
  'custom-list': 'private',
  'black-list': 'private',
  private: 'private'
};

const SETTING_CLOSED = {
  public: 'private',
  registered: 'private',
  favorites: 'private',
  subscribers: 'private',
  'white-list': 'private',
  'custom-list': 'private',
  'black-list': 'private',
  private: 'private'
};

const MAX_FILE_SIZE = 1024 * 1024 * 400; // in bytes, 400Mb
// const ALLOWED_FILE_TYPE = 'application/zip';

const handleDropRejected = fileRejections => {
  const rejection = fileRejections[0];

  if (!rejection.file) {
    window.alert(`Случилось что-то непредвиденное: файл не найден`);
    return;
  }

  if (rejection.file.size > MAX_FILE_SIZE) {
    window.alert(
      `Превышен максимальный размер в 400Мб (${Math.ceil(
        rejection.file.size / 1024 / 1024
      )}).`
    );
  }
  // else if (rejection.file.type !== ALLOWED_FILE_TYPE) {
  //   window.alert(`Разрешены только .zip архивы, созданные в нашем приложении.`);
  // }
  else {
    window.alert(
      `Случилось что-то непредвиденное, обратитесь к разработчикам, предоставив информацию ниже:\n${rejection.errors
        .map(error => `Message: ${error.message} Code: ${error.code}`)
        .join('\n')}`
    );
  }
};

export function ArchiveUpload({ profileId }) {
  const [status, setStatus] = useState('initial');
  const [privacySettingType, setPrivacySettingType] = useState('private');
  const [privacySettings, setPrivacySettings] = useState(SETTING_CLOSED);
  const [archiveTag, setArchiveTag] = useState('');
  const [addPrivacyTags, setAddPrivacyTags] = useState(false);
  const [importStatuses, setImportStatuses] = useState([]);
  const [uploadLog, setUploadLog] = useState({});
  const [token] = useTokenState();

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    // accept: ALLOWED_FILE_TYPE,
    maxSize: MAX_FILE_SIZE,
    maxFiles: 1,
    onDrop: () => setStatus('ready'),
    onDropRejected: handleDropRejected
  });

  const { style } = useDropzoneStyle(isDragActive, isDragAccept, isDragReject);

  useEffect(() => {
    async function getStatuses() {
      const res = await getArchiveStatus({
        profileId,
        token
      });
      if (res.error || !res.data) return;

      const list = res.data
        .slice()
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      setImportStatuses(list);
    }
    // query
    getStatuses();
  }, [profileId, token]);

  useEffect(() => {
    if (privacySettingType === 'public') setPrivacySettings(SETTING_OPEN);
    if (privacySettingType === 'private') setPrivacySettings(SETTING_CLOSED);
  }, [privacySettingType]);

  const file =
    acceptedFiles && acceptedFiles.length > 0 ? acceptedFiles[0] : null;

  const handleArchiveTagChange = e => {
    setArchiveTag(e.target.value.replace(',', ''));
  };

  const getArchivePrivacySettingOptionID = value => {
    return PRIVACY_SETTINGS_OPTIONS.findIndex(
      s => s.value === privacySettings[value]
    );
  };

  const handleArchivePrivacySettingsChange = (value, id) => {
    setPrivacySettings({
      ...privacySettings,
      [value]: PRIVACY_SETTINGS_OPTIONS[id].value
    });
  };

  const sendArchive = () => {
    if (!file) return;
    setStatus('uploading');
    const timeStart = Date.now();

    // eslint-disable-next-line
    let formData = new FormData();
    // eslint-disable-next-line
    const xhr = new XMLHttpRequest();

    formData.append('file', file);

    xhr.timeout = 1000 * 60 * 60 * 24; // a day for uploading a file. Should be enough?..
    xhr.ontimeout = () => {
      window.alert('Request timeout!');
    };
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      console.log('xhr', xhr);
      if (readyState === 4) {
        if (status === 200) {
          setStatus('uploaded');
        } else {
          // onFailure(response);

          setStatus('failure');
          setUploadLog({
            ...uploadLog,
            response,
            status,
            readyState,
            file: {
              name: file.name,
              size: file.size,
              type: file.type
            },
            profile: profileId,
            token,
            timeStart,
            timeEnd: Date.now(),
            time: (Date.now() - timeStart) / 1000
          });
        }
      }
    };
    const importSettings = privacySettings;
    if (archiveTag) importSettings.tags = archiveTag;
    if (addPrivacyTags) importSettings.privacyTagging = true;

    const url =
      API_URL +
      //'http://slonopotam.net/v2' +
      createQueryString('/archive', importSettings);
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.send(formData);
  };

  const ref = useRef(null);

  return (
    <>
      <StyledArchiveUpload>
        <p>
          Здесь вы можете загрузить .zip файл с архивом, созданным по{' '}
          <a
            href="https://dybr.ru/blog/dybr/3161497"
            target="_blank"
            rel="noopener noreferrer"
          >
            этой инструкции
          </a>
          . Максимальный размер файла &mdash; 400 Мб.
        </p>
        <br />
        <br />
        <div {...getRootProps({ className: 'dropzone', style })}>
          <input {...getInputProps()} />
          {file ? (
            <div>
              {file.name}
              <br />
              выбрать другой файл
            </div>
          ) : (
            <div>загрузить</div>
          )}
        </div>
      </StyledArchiveUpload>
      <RadioGroup
        title="уровень приватности"
        name="archivePostsPrivacyOptions"
        checked={privacySettingType}
        onCheck={setting => setPrivacySettingType(setting)}
        items={PRIVACY_LEVELS}
      />
      {privacySettingType === 'custom' && (
        <StyledCustomPrivacySettings>
          {PRIVACY_SETTINGS_DIARY.map(setting => (
            <div className="privacy-setting">
              <div className="label">{setting.label}</div>
              <div className="select">
                <Select
                  isClearable={false}
                  onChange={id =>
                    handleArchivePrivacySettingsChange(setting.value, id)
                  }
                  options={PRIVACY_SETTINGS_OPTIONS}
                  selectedOptionID={getArchivePrivacySettingOptionID(
                    setting.value
                  )}
                  captureMenuScroll={true}
                  menuPortalTarget={ref && ref.current}
                  menuPlacement="auto"
                />
              </div>
            </div>
          ))}
        </StyledCustomPrivacySettings>
      )}
      <br />
      <Switch
        label="пометить тэгами записи с настройками приватности"
        checked={addPrivacyTags}
        onClick={() => setAddPrivacyTags(!addPrivacyTags)}
      />
      {addPrivacyTags && (
        <StyledHint>
          Информация, которая теряется при импорте, может быть внесена в теги.
          Мы применим теги{' '}
          <i>
            diary-ru-открыта-для-избранного, diary-ru-открыта-для-читателей,
            diary-ru-открыта-для-списка, diary-ru-закрыта-для-списка{' '}
          </i>{' '}
          к записям с соответствующими настройками
        </StyledHint>
      )}
      <br />

      <TextField
        label="тэг для импортированных записей"
        value={archiveTag}
        onChange={handleArchiveTagChange}
      />
      <br />
      <br />

      {file && status === 'ready' && (
        <ActionButton onClick={sendArchive}>загрузить</ActionButton>
      )}

      {status === 'uploading' && (
        <h2>Загружаем файл, не закрывайте страницу!</h2>
      )}
      {status === 'uploaded' && <h2>Архив принят в обработку!</h2>}
      {status === 'failure' && (
        <>
          <h2>Что-то пошло не так во время загрузки.</h2>
          <p>
            Скопируйте данные ниже и пришлите на{' '}
            <a href="mailto:support@dybr.ru">support@dybr.ru</a>
            <textarea
              style={{ width: '100%', height: '100px' }}
              value={JSON.stringify(uploadLog)}
            />
          </p>
        </>
      )}

      <UploadStatuses importStatuses={importStatuses} />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profileId: selectActiveProfileAugmented(state, { activeProfileId }).id
  };
};

export const ArchiveUploadContainer = WithActiveProfileId(
  connect(mapStateToProps)(ArchiveUpload)
);
