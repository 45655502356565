import React from 'react';

const Icon = ({
  className,
  backgroundClassName,
  detailLinesClassName,
  outlineClassName
}) => (
  <svg
    viewBox="0 0 432.59999 486.4"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={backgroundClassName}
      fill="currentColor"
      d="M 64.350097,96.341504 V 414.69355 c 0,24.99044 20.373923,45.36436 45.364363,45.36436 h 213.17108 c 24.99044,0 45.36437,-20.37392 45.36437,-45.36436 h 0.10018 V 96.341504 Z M 216.3,145.40009 c 7.5,0 13.50039,6.0004 13.50039,13.5004 v 238.6003 c 0,7.49998 -6.00039,13.49853 -13.50039,13.49853 -7.5,0 -13.50039,-6.09873 -13.50039,-13.59873 v -238.5001 c 0,-7.5 6.00039,-13.5004 13.50039,-13.5004 z m -88.09951,14.79923 c 7.5,0 13.50039,6.00039 13.50039,13.50039 v 208.8998 c 0,7.49999 -6.00039,13.5004 -13.50039,13.5004 -7.4,0 -13.5004,-6.00041 -13.5004,-13.5004 v -208.8998 c 0,-7.5 6.0004,-13.50039 13.5004,-13.50039 z m 176.19902,0 c 7.5,0 13.5004,6.00039 13.5004,13.50039 v 208.8998 c 0,7.49999 -6.0004,13.5004 -13.5004,13.5004 -7.49999,0 -13.50039,-6.00041 -13.50039,-13.5004 v -208.8998 c 0,-7.5 6.0004,-13.50039 13.50039,-13.50039 z"
    />
    <path
      className={outlineClassName}
      fill="currentColor"
      d="M 419.1,70 H 317.9 V 53.5 C 317.9,24 293.9,0 264.4,0 H 168.2 C 138.7,0 114.7,24 114.7,53.5 V 70 H 13.5 C 6,70 0,76 0,83.5 0,91 6,97 13.5,97 h 24.4 v 317.2 c 0,39.8 32.4,72.2 72.2,72.2 h 212.4 c 39.8,0 72.2,-32.4 72.2,-72.2 V 97 h 24.4 c 7.5,0 13.5,-6 13.5,-13.5 0,-7.5 -6,-13.5 -13.5,-13.5 z M 141.7,53.5 C 141.7,38.9 153.6,27 168.2,27 h 96.2 c 14.6,0 26.5,11.9 26.5,26.5 V 70 H 141.7 Z m 226,360.7 c 0,24.9 -20.3,45.2 -45.2,45.2 H 110.1 c -24.9,0 -45.2,-20.3 -45.2,-45.2 V 97 h 302.9 v 317.2 z"
    />
    <path
      className={detailLinesClassName}
      fill="currentColor"
      d="m 216.3,145.40009 c -7.5,0 -13.50039,6.00039 -13.50039,13.50039 v 238.50011 c 1e-5,7.49999 6.00039,13.59872 13.50039,13.59872 7.5,0 13.50039,-5.99853 13.50039,-13.49854 V 158.90048 c 0,-7.5 -6.00039,-13.50039 -13.50039,-13.50039 z m -88.09951,14.79922 c -7.5,0 -13.50039,6.00038 -13.50039,13.5004 v 208.8998 c 0,7.49999 6.10039,13.50038 13.50039,13.50038 7.5,0 13.50039,-6.00039 13.50039,-13.50038 v -208.8998 c 0,-7.50002 -6.00039,-13.5004 -13.50039,-13.5004 z m 176.19902,0 c -7.5,0 -13.50039,6.00038 -13.50039,13.5004 v 208.8998 c 0,7.49999 6.00039,13.50038 13.50039,13.50038 7.50001,0 13.5004,-6.00039 13.5004,-13.50038 v -208.8998 c 0,-7.50002 -6.00039,-13.5004 -13.5004,-13.5004 z"
    />
  </svg>
);

export default Icon;
