import React from 'react';
import ProfileLinkWithMenu from '../Shared/ProfileLinkWithMenu';

function SimpleLink(className, href, rel, target, children) {
  return (
    <a className={className} href={href} rel={rel} target={target}>
      {children}
    </a>
  );
}

export function Link({ href, rel, className, children, target, ...other }) {
  const dataReplyLink = other['data-reply-link'];
  if (rel === 'author' && !dataReplyLink && href) {
    const profileLinkRegex = /^\/profile\/(?<profileId>\d+)$/g;
    const matches = profileLinkRegex.exec(href);
    if (matches && matches[1]) {
      const profileId = matches[1];
      return ProfileLinkWithMenu({
        profileId,
        profileName: children,
        replyLink: dataReplyLink
      });
    }
  }
  return SimpleLink(className, dataReplyLink ?? href, rel, target, children);
}
