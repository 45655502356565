import {
  BanProfileAction,
  HideProfileAction,
  StoreFavoritesAction,
  StoreListAction,
  StoreReadersAction,
  SubscribeToProfileAction,
  UnbanProfileAction,
  UnhideProfileAction,
  UnsubscribeFromProfileAction
} from './actions';
import produce from 'immer';
import keyBy from 'lodash/keyBy';
import { LogoutAction } from 'store/user/actions/LogoutAction';
import cloneDeep from 'lodash/cloneDeep';
import { defaultListsState } from 'store/_setup/initialState';
import { ClearListsAction } from 'store/lists/actions';

/*
    structure:

  {
    banned: {
      id: string,
      action: 'ban',
      kind: 'profile' | ?,
      name: string,
      scope: 'blog' | ?
      profiles: string[], // ids
    }
    hidden: {
      id: string,
      action: 'hide',
      kind: 'profile' | ?,
      name: string,
      scope: 'blog' | ?
      profiles: string[],
    },

    // these are technically subscriptions but it makes more sense to keep them here
    favorites: [],
    readers: [],

    profiles: {
      id: Profile
    }
  }

*/

function convertListPayload({ id, action, kind, name, scope, profiles = [] }) {
  const profileIDs = profiles.map(p => p.id);
  const profilesById = keyBy(profiles, 'id');

  const listData = {
    id,
    name,
    action,
    kind,
    scope,
    profiles: profileIDs
  };

  return { listData, profiles: profilesById };
}

function getListStoreName(list) {
  if (list.action === 'ban') return 'banned';
  if (list.action === 'hide') return 'hidden';
  if (list.type === 'custom') return list.name; // FUTURE FEATURE ??
}
// todo open questions:
// 1. do we really need to save all that amount of profile info???
// 2. if (!action.list) return; looks not safe
// 3. can we save only listId + profileId[] only?

export const listReducer = produce((draft, action) => {
  switch (action.type) {
    case StoreListAction.type: {
      if (!action.list) return;
      const { listData, profiles } = convertListPayload(action.list);
      const key = getListStoreName(action.list);
      // TEMP, remove when backend has fixed multiples of ban and hide lists
      if (!Object.keys(profiles).length) return;
      draft.lists[key] = listData;
      draft.lists.profiles = { ...draft.lists.profiles, ...profiles };

      return;
    }
    case StoreFavoritesAction.type:
      if (!action.list) return;
      draft.lists.favorites = action.list.map(p => p.id);
      draft.lists.profiles = {
        ...draft.lists.profiles,
        ...keyBy(action.list, 'id')
      };
      return;

    case StoreReadersAction.type:
      if (!action.list) return;
      draft.lists.readers = action.list.map(p => p.id);
      draft.lists.profiles = {
        ...draft.lists.profiles,
        ...keyBy(action.list, 'id')
      };
      return;

    case BanProfileAction.type:
      console.log('draft.lists.banned: ', draft.lists.banned);
      console.log('action.profile: ', action.profile);
      if (!action.profile || !draft.lists.banned) return; //???
      draft.lists.banned.profiles.push(action.profile.id);
      draft.lists.profiles[action.profile.id] = action.profile;
      return;

    case UnbanProfileAction.type:
      if (!draft.lists.banned) return;
      draft.lists.banned.profiles = draft.lists.banned.profiles.filter(
        id => id !== action.profileID
      );
      return;

    case HideProfileAction.type:
      if (!action.profile || !draft.lists.hidden) return;
      draft.lists.hidden.profiles.push(action.profile.id);
      draft.lists.profiles[action.profile.id] = action.profile;
      return;

    case UnhideProfileAction.type:
      if (!draft.lists.hidden) return;
      draft.lists.hidden.profiles = draft.lists.hidden.profiles.filter(
        id => id !== action.profileID
      );
      return;

    case SubscribeToProfileAction.type:
      if (!action.profile) return;
      if (!draft.lists.favorites) draft.lists.favorites = [];
      draft.lists.favorites.push(action.profile.id);
      draft.lists.profiles[action.profile.id] = action.profile;
      return;

    case UnsubscribeFromProfileAction.type:
      if (!draft.lists.favorites) return;
      draft.lists.favorites = draft.lists.favorites.filter(
        id => id !== action.profileID
      );
      return;
    case LogoutAction.type:
    case ClearListsAction.type:
      draft.lists = cloneDeep(defaultListsState);
      return;
    default:
      return draft;
  }
});
