import { query } from './Query';

/*
exports as default:

Interface = {
  add: requestAddToFavorites,
  remove: requestRemoveFromFavorites,
  list - ?
};
*/

// /favorites/profileID/subscribers
// кто подписан

// "/favorites/profile/entries"
// записи избранного

/*
добавить профиль 123 в фейвориты к профилю 1
POST /profiles/1/relationships/favorites
{
  "data": [
    { "type": "profiles", "id": "123" }
  ]
}
очистить фейвориты профиля 1
PATCH /profiles/1/relationships/favorites
{
  "data": null
}

выставить фейвориты профиля 1 в указанные значения ( 2 и 3)
(очистить и добавить)
PATCH /profiles/1/relationships/favorites
{
  "data": [
    { "type": "profiles", "id": "2" },
    { "type": "profiles", "id": "3" }
  ]
}

удалить из фейворитов профиля 1 профиль 123
DELETE /profiles/1/relationships/favorites
{
  "data": [
    { "type": "profiles", "id": "123" },
  ]
}*/

export const addToFavoritesApi = async ({ profileId, readerId, token }) => {
  try {
    const queryParams = {
      subscription: { id: profileId, type: 'profiles' },
      reader: { id: readerId, type: 'profiles' },
      relationshipNames: ['subscription', 'reader']
    };
    await query({ method: 'POST', resource: `favorites`, queryParams, token });

    return { status: 'ok' };
  } catch (err) {
    console.log('requestAddToFavorites', err);
    return { error: 'что-то пошло не так' };
  }
};

export const removeFromFavoritesApi = async ({
  profileId,
  readerId,
  token
}) => {
  try {
    await query({
      method: 'DELETE',
      resource: `profiles/${readerId}/favorites`,
      id: profileId,
      token
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('requestAddToFavorites', err);
    return { error: 'что-то пошло не так' };
  }
};

export const getFavoritesApi = async ({ profileId, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `favorites`,
      id: profileId,
      token
    });

    if (res.error) return res;

    if (res.data) {
      return { data: res.data.subscriptions };
    } else {
      // todo: dispatch error
      return { error: 'wrong data', data: res.data };
    }
  } catch (err) {
    console.log('getFavoritesApi', err);
    return { error: 'что-то пошло не так' };
  }
};

export const getReadersApi = async ({ profileId, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `favorites/${profileId}/subscribers`,
      token
    });

    if (res.error) return res;

    if (res.data) {
      return { data: res.data.subscriptions };
    } else {
      // todo: dispatch error
      return { error: 'wrong data', data: res.data };
    }
  } catch (err) {
    console.log('getReadersApi', err);
    return { error: 'что-то пошло не так' };
  }
};
