import { useMemo } from 'react';
import { parseSearchParams } from '../../utils/helpers/searchParamUtils';
import { useLocation } from '@reach/router';

export function useSearchParams() {
  const sp = useLocation().search;

  return useMemo(() => {
    return parseSearchParams(sp);
  }, [sp]);
}
