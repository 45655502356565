export default {
  headerImageDisplayTypes: [
    {
      label: 'растягивать',
      repeat: 'repeat',
      position: 'center center',
      size: 'cover',
      attachment: 'normal',
    },
    {
      label: 'повторять',
      repeat: 'repeat',
      position: 'top left',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'по центру',
      repeat: 'no-repeat',
      position: 'top center',
      size: 'contain',
      attachment: 'normal',
    },
    {
      label: 'в левом углу',
      repeat: 'no-repeat',
      position: 'top left',
      size: 'contain',
      attachment: 'normal',
    },
    {
      label: 'в правом углу',
      repeat: 'no-repeat',
      position: 'top right',
      size: 'contain',
      attachment: 'normal',
    },
    {
      label: 'повторять сверху',
      repeat: 'repeat-x',
      position: 'top center',
      size: 'contain',
      attachment: 'normal',
    },
  ],

  backgroundImageDisplayTypes: [
    {
      label: 'повторять',
      repeat: 'repeat',
      position: 'top left',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'по центру',
      repeat: 'no-repeat',
      position: 'top center',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'в левом углу',
      repeat: 'no-repeat',
      position: 'top left',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'в правом углу',
      repeat: 'no-repeat',
      position: 'top right',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'по левой стороне',
      repeat: 'repeat-y',
      position: 'top left',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'по правой стороне',
      repeat: 'repeat-y',
      position: 'top right',
      size: 'auto',
      attachment: 'normal',
    },
    {
      label: 'фиксированный растянутый',
      repeat: 'no-repeat',
      position: 'top center',
      size: 'cover',
      attachment: 'fixed',
    },
    {
      label: 'фиксированный по центру',
      repeat: 'no-repeat',
      position: 'top center',
      size: 'auto',
      attachment: 'fixed',
    },
    {
      label: 'фиксированный слева сверху',
      repeat: 'no-repeat',
      position: 'top left',
      size: 'auto',
      attachment: 'fixed',
    },
    {
      label: 'фиксированный справа сверху',
      repeat: 'no-repeat',
      position: 'top right',
      size: 'auto',
      attachment: 'fixed',
    },
    {
      label: 'фиксированный слева снизу',
      repeat: 'no-repeat',
      position: 'bottom left',
      size: 'auto',
      attachment: 'fixed',
    },
    {
      label: 'фиксированный справа снизу',
      repeat: 'no-repeat',
      position: 'bottom right',
      size: 'auto',
      attachment: 'fixed',
    },
  ],

  fontStyleTypes: [
    {
      label: 'обычный',
      weight: 'normal',
      style: 'normal',
    },
    // italic
    {
      label: 'курсив',
      weight: 'normal',
      style: 'italic',
    },
    // bold
    {
      label: 'жирный',
      weight: 'bold',
      style: 'normal',
    },
    // underline
    {
      label: 'подчеркнутый',
      weight: 'normal',
      style: 'normal',
      textDecoration: 'underline',
    },
    {
      label: 'жирный курсив',
      weight: 'bold',
      style: 'italic',
    },
  ],

  fontFamilies: [
    // Roboto
    {
      label: 'Roboto',
      value: 'Roboto, sans-serif',
    },
    // Verdana
    {
      label: 'Verdana',
      value: 'Verdana, sans-serif',
    },
    // Helvetica
    {
      label: 'Helvetica',
      value: 'Helvetica, serif',
    },
    // Times
    {
      label: 'Times New Roman',
      value: "'Times New Roman', serif",
    },
    // Georgia
    {
      label: 'Georgia',
      value: 'Georgia, serif',
    },
    {
      label: 'Palatino',
      value: 'Palatino, serif',
    },
    {
      label: 'Garamond',
      value: 'Garamond, sans-serif',
    },
    {
      label: 'Bookman',
      value: 'Bookman, serif',
    },
    {
      label: 'Montserrat Alternates',
      value: "'Montserrat Alternates', sans-serif",
    },
    {
      label: 'Lobster',
      value: 'Lobster, cursive',
    },
    {
      label: 'Amatic SC',
      value: "'Amatic SC', cursive",
    },
    {
      label: 'Poiret One',
      value: "'Poiret One', cursive",
    },
    {
      label: 'Russo One',
      value: "'Russo One', sans-serif",
    },
    {
      label: 'Caveat',
      value: "'Caveat', cursive",
    },
    {
      label: 'Rubik Mono One',
      value: "'Rubik Mono One', sans-serif",
    },
    {
      label: 'Spectral SC',
      value: "'Spectral SC', serif",
    },
  ],
  layoutAlignment: [
    {
      label: 'по центру',
      value: 'center',
    },
    {
      label: 'слева',
      value: 'left',
    },
    {
      label: 'справа',
      value: 'right',
    },
  ],
  textAlignment: [
    {
      label: 'по ширине',
      value: 'justify'
    },
    {
      label: 'по левому краю',
      value: 'left'
    },
    {
      label: 'по центру',
      value: 'center'
    },
    {
      label: 'по правому краю',
      value: 'right'
    }
  ]
};
