import { query } from './Query';

export const getEntryCommentsApi = async ({
  entryId,
  sort,
  pageSize,
  pageNumber,
  token
}) => {
  try {
    const queryParams = {
      sort: sort || 'created-at',
      'page[size]': pageSize,
      'page[number]': pageNumber,
      include: 'profile'
    };

    const res = await query({
      method: 'GET',
      resource: `entries/${entryId}/comments`,
      queryParams,
      token
    });
    if (res.error) return res;

    return { success: true, data: res.data };
  } catch (err) {
    console.log('getEntryCommentsApi', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const getCommentsApi = async ({ commentIds, sort, token }) => {
  try {
    const queryParams = {
      'filters[id]': commentIds.join(','),
      sort: sort || 'created-at',
      include: 'profile'
    };
    const res = await query({
      method: 'GET',
      resource: 'comments',
      queryParams,
      token
    });
    if (res.error) return res;

    return { success: true, data: res.data };
  } catch (err) {
    console.log('getCommentsApi', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const createCommentApi = async ({
  content,
  entryId,
  profileId,
  token
}) => {
  try {
    const res = await query({
      method: 'POST',
      resource: 'comments',
      queryParams: {
        content,
        entry: { id: entryId, type: 'entries' },
        profile: { id: profileId, type: 'profiles' },
        relationshipNames: ['entry', 'profile']
      },
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('requestCommentCreate', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const removeCommentApi = async ({ id, token }) => {
  try {
    await query({ method: 'DELETE', resource: 'comments', token, id });
    return { status: 'ok' };
  } catch (err) {
    console.log('requestCommentDelete', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const updateCommentApi = async ({ commentId, content, token }) => {
  try {
    if (typeof content === 'string') {
      const res = await query({
        method: 'PATCH',
        resource: 'comments',
        queryParams: { content },
        token,
        id: commentId
      });
      return { status: 'ok', data: res.data };
    }
    return { error: 'комментарий пуст, совсем пуст' };
  } catch (err) {
    console.log('requestCommentUpdate', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};
