import React from 'react';

export const EditIcon = ({ className }) => (
  <svg
    viewBox="0 0 12.511306 12.450299"
    className={`icon icon-edit ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="pencil main-outline"
      d="M 7.7798368,2.0777932 10.324922,4.6228452 3.8826138,11.065133 1.3389748,8.5200812 Z M 12.256146,1.4639852 11.121139,0.32898225 c -0.438643,-0.438643 -1.1509062,-0.438643 -1.5910362,0 l -1.087226,1.08722195 2.5450802,2.545072 1.268189,-1.268184 c 0.340213,-0.340233 0.340213,-0.888895 0,-1.229107 z M 0.00708308,12.097358 c -0.04632,0.208449 0.141884,0.395234 0.350361,0.34454 L 3.1935098,11.754261 0.64987208,9.2092092 Z"
    />
  </svg>
);
