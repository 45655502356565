import { useCommentDraftState } from 'store/localStorage/drafts/useDraftState';
import { useActiveProfile } from 'store/localStorage/useTokenState';

export function setCommentDraft(commentData, activeProfileId) {
  return (draft) => {
    const commentId = commentData.eid;

    if (!commentData.content) {
      if (draft?.[activeProfileId]?.[commentId]) {
        delete draft[activeProfileId][commentId];
      }
      return draft;
    } else {
      return {
        ...draft,
        [activeProfileId]: {
          ...draft?.[activeProfileId],
          [commentId]: {
            ...draft?.[activeProfileId]?.[commentId],
            ...commentData
          }
        }
      };
    }
  };
}

export function useSetCommentDraft() {
  const [, setDraft] = useCommentDraftState();
  const { activeProfileId } = useActiveProfile();

  return (commentData) =>
    setDraft(setCommentDraft(commentData, activeProfileId));
}
