import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import styled from 'styled-components';
import { CalendarIcon } from '../../styles/Icons/Calendar';
import { SidebarFormWrapper } from '../../scenes/Header/components/Sidebar/SidebarForm';

registerLocale('ru', ru);

const CalendarInputIcon = styled(CalendarIcon)`
  display: ${p => (p.hide ? 'none' : 'initial')};
  width: 32px;
  padding: 8px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  .filling-area {
    opacity: 0;
  }
  .calendar.main-outline {
    fill: ${p => p.theme.textLight};
  }
`;

const BaseInput = styled.input.attrs(props => ({
  id: props.id
}))`
  height: 30px;
  width: calc(100%);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid
    ${props =>
      (props.touched &&
        (props.error ? props.theme.brandDark : props.theme.accent)) ||
      props.theme.textLight};

  font-weight: bold;
  font-size: 1em;
  color: ${props => props.theme.text};
  background: transparent;
  -webkit-appearance: none;
  border-radius: 0;

  :hover,
  :focus {
    outline: none;
    border-color: ${props => props.theme.accent};
  }

  &::placeholder {
    color: ${props => props.theme.textLight};
    font-size: 1em;
    font-weight: normal;
    font-family: ${props => props.theme.textFont};
  }

  &:focus + label {
    transform: translate(0, -1.3em);
    font-size: 12px;
    font-family: ${props => props.theme.textFont};
    font-weight: normal;
  }

  ${props =>
    props.value &&
    `
    + label {
      transform: translate(0, -1.3em);
      font-size: 12px;
      font-family: ${props.theme.textFont};
      font-weight: normal;
    }
  `};

  &:-webkit-autofill + label {
    transform: translate(0, -1.3em);
    font-size: 12px;
    font-family: ${props => props.theme.textFont};
    font-weight: normal;
  }

  ${SidebarFormWrapper} & {
    @media (min-width: 360px) {
      padding-right: 32px;
    }
  }
`;

const DatePickerWrapper = styled(({ className, ...props }) => (
  <DatePicker {...props} wrapperClassName={className} />
))`
  .clear-button {
    right: 0px;
    padding: 0px;
    &:after {
      cursor: pointer;
      background-color: unset;
      color: ${p => p.theme.textLight};
      height: 20px;
      width: 20px;
      padding: 2px;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: '×';
    }
  }
`;

const Calendar = styled.div`
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: ${p => p.theme.backgroundLighter};

  .react-datepicker__day--outside-month {
    &:not(.react-datepicker__day--disabled) {
      color: ${p => p.theme.textLight};
    }
  }

  .react-datepicker__day.react-datepicker__day--disabled {
    color: ${p => p.theme.textLight};
    &:hover {
      border: 1px solid ${p => p.theme.textLighter};
      color: ${p => p.theme.textLight};
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 5px;
    background-color: ${p => p.theme.brand};
    color: #fff;
  }

  .react-datepicker__day {
    border: 1px solid rgba(255, 255, 255, 0);
    &:hover {
      border-radius: 5px;
      border: 1px solid ${p => p.theme.brand};
      background-color: unset;
      color: ${p => p.theme.text};
    }
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${p => p.theme.backgroundLighter};
    border-bottom: 1px solid ${p => p.theme.divider};
    border-radius: 0px;
    padding: 8px 0;
    position: relative;
  }

  .react-datepicker__month {
    height: 207px;
  }
`;

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
`;

function DybrDatePicker({ value, onChange, label, ...rest }) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <BaseInput
        readOnly={true}
        ref={ref}
        value={value}
        onClick={onClick}
        placeholder={label}
      />
      <CalendarInputIcon hide={!!value} />
    </>
  ));
  return (
    <DatePickerWrapper
      selected={value}
      onChange={onChange}
      customInput={<CustomInput />}
      locale="ru"
      popperContainer={Popper}
      calendarContainer={Calendar}
      clearButtonClassName={'clear-button'}
      dateFormat={'dd-MM-yyyy'}
      {...rest}
    />
  );
}

export default DybrDatePicker;
