import React, { useEffect, useState } from 'react';
import { useTokenState } from '../../../store/localStorage/useTokenState';
import styled from 'styled-components';
import { confirmUserEmailApi } from '../../../api/users';
import Loading from '../../../components/Shared/Loading';
import { ErrorIcon } from '../../../styles/Icons/Error';
import TickIcon from '../../../styles/Icons/TickIcon';

const CenteredMessageBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 0px 20px;

  p {
    text-align: center;
    line-height: 1.6;
  }
`;

const SuccessIconWrapper = styled.span`
  width: 40px;
  display: block;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 20px;
  color: ${p => p.theme.softAlertSuccessText};
`;

const ErrorIconWrapper = styled(SuccessIconWrapper)`
  color: ${p => p.theme.brand};

  .circle.main-outline {
    stroke: ${p => p.theme.brand};
    stroke-width: 2.99672;
    fill: none;
  }
  .cross.main-outline {
    fill: ${p => p.theme.brand};
  }
`;

export function ConfirmEmailChange({ emailToken }) {
  const results = { loading: 'loading', error: 'error', success: 'success' };

  const [token] = useTokenState();
  const [result, setResult] = useState(results.loading);

  useEffect(() => {
    confirmUserEmailApi({ emailToken, userToken: token }).then(res => {
      if (res?.status === 'ok') {
        setResult(results.success);
      } else {
        setResult(results.error);
      }
    });
  }, []);

  return (
    <CenteredMessageBox>
      {result === results.loading && (
        <>
          <Loading />
          <p>Ожидаем подтверждения</p>
        </>
      )}
      {result === results.error && (
        <>
          <ErrorIconWrapper>
            <ErrorIcon />
          </ErrorIconWrapper>
          <p>Не удалось подтвердить почту</p>
          <p>Возможно, ссылка неверна или уже устарела</p>
        </>
      )}
      {result === results.success && (
        <>
          <SuccessIconWrapper>
            <TickIcon />
          </SuccessIconWrapper>
          <p>Почта успешно изменена</p>
        </>
      )}
    </CenteredMessageBox>
  );
}
