import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const InterestingListWrapper = styled.ul`
  padding-top: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-self: flex-start;

  padding-right: 80px;
  margin-left: 0 !important;

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const InterestingListItem = styled.li`
  min-height: 134px;
  margin-top: 11px;
  text-align: left;

  h3 {
    position: relative;
    overflow: hidden;
    font-family: 'Montserrat Alternates', sans-serif;
    text-transform: lowercase;
    font-weight: bold;
    padding-left: 120px;
    font-size: 24px;
    color: ${props => props.theme.brandDark};
    text-align: left;
    @media (max-width: 1200px) {
      font-size: 21px;
      padding-left: 50px;
    }
    @media (max-width: 800px) {
      padding-left: 10px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }

    span {
      display: inline;
      vertical-align: auto;
      position: relative;
      padding: 0 8px;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 1400px;
        position: absolute;
        top: 18px;
        border-top: 1px solid ${props => props.theme.divider};

        @media (max-width: 600px) {
          top: 11px;
        }
      }

      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
  }
`;

export const InterestingListItemContent = styled.div`
  padding-top: 14px;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    padding-bottom: 0;
  }

  p {
    padding-left: 127px;
    font-size: 14px;
    line-height: 1.5;

    @media (max-width: 1200px) {
      padding-left: 57px;
    }
    @media (max-width: 800px) {
      padding-left: 18px;
      padding-right: 18px;
      font-size: 16px;
    }
  }
`;

export const InterestingList = ({ data }) => {
  const content = data.map(item => {
    return (
      <InterestingListItem key={item.title}>
        <h3 data-testid="current">
          <span>{item.title}</span>
        </h3>
        <InterestingListItemContent>{item.content}</InterestingListItemContent>
      </InterestingListItem>
    );
  });

  return <InterestingListWrapper>{content}</InterestingListWrapper>;
};
export default InterestingList;

InterestingList.propTypes = {
  data: PropTypes.array.isRequired
};
