import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-down ${className}`}
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="arrow main-outline"
      d="m 0.91555725,0.00495955 c -0.243288,-0.025786 -0.493155,0.048104 -0.669922,0.2421875 -0.302609,0.2999132 -0.329693,0.81796455 -0.05859,1.14648445 C 1.6220996,2.8725523 3.0724834,4.3379501 4.5132133,5.8116002 4.8497831,6.1220038 5.1424476,6.6133991 5.6694633,6.5479283 6.196492,6.6134119 6.4891875,6.122047 6.8257133,5.8116002 8.2661644,4.3380003 9.7150519,2.8724127 11.149932,1.3936315 11.421302,1.0653355 11.395653,0.54731005 11.093292,0.24714705 10.739758,-0.14101895 10.09248,-0.04773865 9.7964169,0.36042825 8.4216752,1.7662541 7.042045,3.167439 5.6694633,4.5752721 4.2968816,3.167439 2.9172516,1.7662541 1.5425099,0.36042825 1.3944782,0.15634475 1.1588443,0.03074585 0.91555725,0.00495955 Z"
    />
  </svg>
);

export default Icon;
