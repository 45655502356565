import { loginApi } from 'api/auth';

import { fetchUserThunk } from 'store/user/thunks/fetchUserThunk';
import { parseToken } from 'store/user/reducer/parseToken';

export const doLoginThunk = ({ email, password, setToken }) => async (
  dispatch,
  getState
) => {
  const res = await loginApi({ email, password });

  if (!res.error) {
    const token = res.accessToken;
    setToken?.(token);

    // todo maybe call fetch right from the container??? it would be not that nested
    await fetchUserThunk({ setToken, token, ...parseToken(token) })(
      dispatch,
      getState
    );
    return { status: 'ok' };
  } else {
    return { error: res.error };
  }
};
