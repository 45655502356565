import React from 'react';

const Icon = ({ className }) => (
  <svg
    viewBox="0 0 896.025 896.025"
    className={`icon icon-settings-cog ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="settings-cog main-outline"
      d="M 863.24,382.771 774.481,367.964 C 768.03,341.59 758.624,316.379 746.374,292.865 l 56.821,-70.452 c 12.085,-14.889 11.536,-36.312 -1.205,-50.682 l -35.301,-39.729 c -12.796,-14.355 -34.016,-17.391 -50.202,-7.165 l -75.906,47.716 c -33.386,-23.326 -71.204,-40.551 -112,-50.546 L 513.731,32.772 C 510.615,13.877 494.264,0.013 475.07,0.013 h -53.198 c -19.155,0 -35.561,13.864 -38.608,32.759 l -14.931,89.263 c -33.729,8.258 -65.353,21.588 -94.213,39.144 l -72.188,-51.518 c -15.558,-11.115 -36.927,-9.377 -50.504,4.171 l -37.583,37.61 c -13.548,13.577 -15.286,34.946 -4.142,50.504 l 51.638,72.326 C 143.95,302.914 130.757,334.358 122.5,367.787 L 32.757,382.772 C 13.891,385.888 0,402.24 0,421.435 v 53.156 c 0,19.193 13.891,35.547 32.757,38.663 l 89.743,14.985 c 6.781,27.508 16.625,53.784 29.709,78.147 L 95.647,676.44 c -12.044,14.875 -11.538,36.312 1.203,50.669 l 35.274,39.73 c 12.797,14.382 34.028,17.363 50.216,7.163 l 77,-48.37 c 32.581,22.285 69.44,38.664 108.993,48.37 l 14.931,89.25 c 3.048,18.896 19.453,32.76 38.608,32.76 h 53.198 c 19.194,0 35.545,-13.863 38.661,-32.759 l 14.875,-89.25 c 33.308,-8.147 64.531,-21.245 93.134,-38.5 l 75.196,53.705 c 15.53,11.155 36.915,9.405 50.478,-4.186 l 37.598,-37.597 c 13.532,-13.536 15.365,-34.893 4.127,-50.479 l -53.536,-75.059 c 17.441,-28.738 30.704,-60.238 38.909,-93.816 l 88.758,-14.82 c 18.921,-3.116 32.756,-19.469 32.756,-38.663 V 421.432 C 895.998,402.24 882.163,385.888 863.24,382.771 Z M 449.42,616.013 c -92.764,0 -168,-75.25 -168,-168 0,-92.764 75.236,-168 168,-168 92.748,0 167.998,75.236 167.998,168 0,92.75 -75.25,168 -167.998,168 z"
    />
  </svg>
);
export default Icon;
