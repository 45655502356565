import React, { useState } from 'react';
import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import Switch from 'components/FormElements/Switch';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import { connect } from 'react-redux';
import { communityHasMemberModeration } from 'scenes/Management/Community/utils';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

function ModerationSettings({ settings, updateProfileSetting }) {
  const [membersModeration, setMembersModeration] = useState(
    communityHasMemberModeration(settings)
  );
  const [token] = useTokenState();
  // const [entriesModeration, setEntriesModeration] = useState(false);
  const handleMemberModerationChange = (e) => {
    if (
      membersModeration &&
      !window.confirm(
        'При выключении премодерации все активные запросы будут автоматически одобрены. Продолжить?'
      )
    ) {
      return;
    }
    setMembersModeration(!membersModeration);
    updateProfileSetting(
      'community.joinRequest',
      e.target.checked ? 'approve' : 'auto',
      token
    );
  };

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Модерация</h4>
          <p>Вы можете включить или выключить модерацию сообщества</p>
        </SettingSectionTitle>
        <Settings>
          <Switch
            testId="new-profile-community-switcher"
            label="премодерация участников"
            checked={membersModeration}
            onClick={handleMemberModerationChange}
          />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(ModerationSettings)
);
