import { changeRgbaAlpha, darkenColor } from 'utils/helpers/colors';
import { getBlogEntrySubAuthorCss } from 'components/Shared/Entry/BlogEntryAvatar';

export default ({ colors, header, fonts }) => `
  .blog-entry,
  .blog-comment {
    background: ${colors.blocks};
    border: 1px solid ${colors.borders};
    font-size: ${fonts.entries.size}px;
    font-family: ${fonts.entries.family};
    font-style: ${fonts.entries.style};
    line-height: ${fonts.entries.height};
    text-align: ${fonts.entries.align};
    position: relative;
    padding: 40px 0 20px 0;
    margin: 20px 0;
  }

  @media (max-width: 550px) {
    .blog-entry,
    .blog-comment,
    .blog-entry p,
    .blog-comment p {
      font-size: ${fonts.entries.size * 0.9 + 'px'};
      margin: 10px 0;
    }
  }

  /******  BLOG ENTRY/COMMENT CONTENT ******/

  .blog-entry-content,
  .blog-comment-content {
    font-weight: ${fonts.entries.weight};
    text-decoration: ${fonts.entries.textDecoration};
    padding: 0px 40px 0 40px;
    word-break: break-word;
  }

  @media (max-width: 650px) {
    .blog-entry-content,
    .blog-comment-content {
      padding: 0px 15px 20px 15px;

   }
  }

  .blog-entry-content img,
  .blog-entry-content iframe,
  .blog-comment-content img,
  .blog-comment-content iframe  {
    max-width: 100%;
  }
  .blog-entry-content img,
  .blog-comment-content img {
    height: auto !important;
  }

  .blog-entry-content pre,
  .blog-comment-content pre {
    white-space: pre-wrap;
  }
  .quote_text,
  blockquote,
  blockquote blockquote,
  blockquote blockquote blockquote,
  .fr-view blockquote,
  .fr-view blockquote blockquote,
  .fr-view blockquote blockquote blockquote
  {
    display: block;
    border-left: solid 2px ${colors.text};
    margin-left: 0;
    margin: 10px 0 10px 0;
    padding-left: 5px;
    color: ${colors.text};
  }

  .blog-entry-content ol ol ul,
  .blog-entry-content ol ul ul,
  .blog-entry-content ul ol ul,
  .blog-entry-content ul ul ul {
    list-style-type: square;
  }

  .blog-entry-content ul ul,
  .blog-entry-content ol ul {
    list-style-type: circle;
  }

  .blog-entry-content ul,
  .blog-entry-content ol {
    margin-left: 40px;
  }

  .blog-entry-content p,
  .blog-comment-content p {
    margin: 1em 0;
    text-align-last: left;
  }

  .blog-entry-content p:first-child,
  .blog-comment-content p:first-child {
    margin-top: 0;
  }

  .blog-entry-content p:last-child,
  .blog-comment-content p:last-child {
    margin-bottom: 0;
  }

  .blog-entry-content span.offtop,
  .blog-comment-content span.offtop {
    color: ${colors.offtop};
  }

  .blog-entry-content a,
  .blog-entry-content a:visited,
  .blog-comment-content a,
  .blog-comment-content a:visited {
    text-decoration: none;
    color: ${colors.links};
  }

  .blog-entry-content a:hover,
  .blog-comment-content a:hover {
    text-decoration: underline;
    color: ${colors.links};
  }

  .blog-entry-tags {
    text-align: left;
    padding: 30px 40px 0 40px;
    clear: both;

  }
  @media (max-width: 650px) {
    .blog-entry-tags {
      padding: 20px 15px 0 15px;
    }
  }
  .blog-entry-tags a,
  .blog-entry-tags span {
    display: inline-block;
    color: ${colors.links};
    text-decoration: none;
    margin-right: 1em;
  }
  .blog-entry-tags a:hover,
  .blog-entry-tags a:focus,
  .blog-entry-tags a:active {
    text-decoration: underline;
  }


  /****** BLOG ENTRY/COMMENT META-UP ******/

  .blog-entry-meta--up,
  .blog-comment-meta--up {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 30px;
    overflow: hidden;
    align-items: flex-start;
  }


  @media (max-width: 550px) {
    .blog-entry-meta--up,
    .blog-comment-meta--up {
      justify-content: center;
    }
  }

  .blog-entry-bookmark,
  .blog-comment-bookmark {
    width: 27px;
    position: absolute;
    right: 40px;
    top: -9px;
    font-size: 1px;
    line-height: 1;
  }

  .blog-entry-avatar,
  .blog-comment-avatar {
    min-height: 120px;
    padding: 0 15px 0 30px;
    text-align: center;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
    line-height: 1;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;

    img {
      height: auto;
      width: auto;
      max-width: 120px;
      max-height: 120px;
    }
  }

  @media (max-width: 650px) {
    .blog-entry-avatar,
    .blog-comment-avatar {
      padding: 0 15px 0 10px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-avatar,
    .blog-comment-avatar {
      padding: 0px;
      width: 100%;
      min-height: initial;
    }
  }

  .blog-entry-avatar span,
  .blog-comment-avatar span {
    padding: 0 10px;
    display: flex;
    position: relative;
    max-height: 120px;
    align-items: center;
  }

  @media (max-width: 550px) {
    .blog-entry-avatar span,
    .blog-comment-avatar span {
      height: initial;
    }
  }

  .blog-entry-avatar span::before,
  .blog-comment-avatar span::before  {
    display: inline-block;
    content: "";
    border-top: 1px solid ${colors.dividers};
    width: 1000px;
    position: absolute;
    top: 50%;
    right: 100%;
  }

  .blog-entry-avatar span::after,
  .blog-comment-avatar span::after  {
    display: inline-block;
    content: "";
    border-top: 1px solid ${colors.dividers};
    width: 1000px;
    position: absolute;
    top: 50%;
    left: 100%;
  }


  .blog-entry-avatar img,
  .blog-comment-avatar img {
    max-width: 120px;
    max-height: 120px;
  }

  .blog-entry-author-wrapping,
  .blog-comment-author-wrapping {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    flex-shrink: 10;
    flex-basis: 0;
  }

  @media (max-width: 650px) {
    .blog-entry-author-wrapping,
    .blog-comment-author-wrapping {
      margin-right: 15px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-author-wrapping,
    .blog-comment-author-wrapping {
      flex-basis: 100%;
      margin-left: 15px;
    }

    .blog-entry-author-wrapping a,
    .blog-entry-author-wrapping span,
    .blog-comment-author-wrapping a,
    .blog-comment-author-wrapping span {
      flex-basis: 100%;
      padding: 0px;
    }

  }

  .blog-entry-blog,
  .blog-comment-blog {
    position: relative;
    left: 10px;
    height: 53px;
    line-height: 1;
    font-size: 1px;
  }

  @media (max-width: 550px) {
    .blog-entry-blog {
      position: static;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      height: auto;
    }
  }

  .blog-entry-blog a,
  .blog-comment-blog a {
    color: ${colors.links};
  }

  .blog-entry-bookmark a,
  .blog-comment-bookmark a,
  .blog-entry-blog a {
    display: inline-block;
  }

  .blog-entry-author,
  .blog-comment-author {
    font-family: ${fonts.headers.family};
    font-style: ${fonts.headers.style};
    font-weight: ${fonts.headers.weight};
    font-style: ${fonts.headers.style};
    text-decoration: ${fonts.headers.textDecoration};
    font-size:  ${fonts.headers.size * 0.75}px;
    display: block;
    margin-top: -0.5em;
    line-height: 1;
  }

  @media (max-width: 550px) {
    .blog-entry-author {
      margin-top: 0px;
    }

    .blog-entry-author,
    .blog-comment-author {
      text-align: center;
    }
  }

  .blog-entry-author a,
  .blog-entry-author a:visited,
  .blog-comment-author a,
  .blog-comment-author a:visited {
    color: ${colors.headers};
    text-decoration: ${fonts.headers.textDecoration};
    padding: 7px 10px;
    display: inline-block;
    position: relative;
  }

  .blog-entry-author a::before,
  .blog-comment-author a::before  {
    display: inline-block;
    content: "";
    border-top: 1px solid ${colors.dividers};
    width: 8000px;
    position: absolute;
    top: 50%;
    left: 100%;
  }

  @media (max-width: 550px) {
    .blog-entry-author a::before,
    .blog-comment-author a::before  {
      border: none;
    }
  }

  ${getBlogEntrySubAuthorCss()}

    .blog-entry-subtext,
    .blog-comment-subtext{
      margin-top: 0.3em;
      word-break: break-word;
    }

  .entry-form .blog-entry-subtext span,
  .entry-form .blog-comment-subtext span {
    padding: 0px;
  }

  .blog-entry-subtext span,
  .blog-comment-subtext span {
    padding: 0 10px;
    font-size: 0.85em;
    color: ${colors.meta};
    display: inline-block;
  }

  @media (max-width: 550px) {
    .blog-entry-subtext,
    .blog-comment-subtext {
      text-align: center;
    }
  }

  .blog-entry-date,
  .blog-comment-date {
    min-width: 128px;
    margin-left: auto;
    margin-right: 40px;
    text-align: right;
    font-size: 0.85em;
    padding-top: 5px;
    color: ${colors.meta};
    flex-grow: 0;
    flex-shrink: 0;
    text-decoration-color: transparent;
  }
  .blog-entry-date .icon-pin {
    height: 1.5em;
    margin-right: 5px;
    position: relative;
    top: 0.5em;
  }

  .blog-entry-date .icon .main-outline {
    fill: ${changeRgbaAlpha(colors.meta, 0.6)};
  }
  .entry-date-changed {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .entry-date-changed .icon {
    height: 0.85em;
    margin-right: 5px;
  }


  @media (max-width: 650px) {
    .blog-entry-date,
    .blog-comment-date {
      flex-basis: 100%;
      text-align: left;
      margin-top: 30px;
      margin-left: 15px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-date,
    .blog-comment-date {
      margin: 30px 0 0 0;
      padding: 0 15px 0 15px;
    }

    .blog-entry--no-author .blog-entry-date {
      margin-top: 0px;
    }
  }

  .blog-entry-title {
    font-size: ${fonts.headers.size}px;
    font-family: ${fonts.headers.family};
    font-style: ${fonts.headers.style};
    font-weight: ${fonts.headers.weight};
    text-decoration: ${fonts.headers.textDecoration};
    color: ${colors.headers};
    flex-basis: 100%;
    line-height: 1;
    word-break: break-word;
    margin: 30px 40px 0 40px;
    position: relative;
  }

  @media (max-width: 650px) {
    .blog-entry-title {
      margin: 5px 15px 0 15px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-title  {
      text-align: left;
    }
  }

  .blog-entry-title a,
  .blog-entry-title a:visited {
    color: ${colors.headers};
    text-decoration: none;
  }

  /****** BLOG ENTRY META-UP IF NO AUTHOR OR NO BLOG OR NO TITLE ******/

  .blog-entry--no-author .blog-entry-title {
    margin-top: 0px;
    flex-basis: auto;
    order: -1;
  }

  .blog-entry--no-author .blog-entry-meta--up::before,
  .blog-entry--no-author .blog-entry-author,
  .blog-entry--no-author .blog-entry-author-wrapping,
  .blog-entry--no-author .blog-entry-avatar,
  .blog-entry--no-blog-link .blog-entry-blog,
  .blog-entry--no-title {
    display: none;
  }

  .blog-entry--no-blog-link .blog-entry-author-wrapping,
  .blog-comment-author-wrapping {
    padding-top: 53px;
  }

  .blog-entry blog-entry--no-author .blog-entry-meta--up {
    flex-wrap: nowrap ;
  }

  @media (max-width: 550px) {
    .blog-entry--no-author .blog-entry-title {
      flex-basis: 100%;
      order: 2;
      margin-top: 5px;
    }

    .blog-entry--no-blog-link .blog-entry-author,
    .blog-comment-author {
      margin-top: 5px;
    }

    .blog-entry--no-blog-link .blog-entry-author-wrapping,
    .blog-comment-author-wrapping {
      padding-top: 10px;
    }
  }

  /****** BLOG ENTRY/COMMENT META-DOWN ******/

  .blog-entry-meta--down,
  .blog-comment-meta--down {
    clear: both;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${fonts.entries.size}px;
    padding: 20px 40px 0 40px;
    margin-top: 40px;
    border-top: 1px solid ${colors.dividers};
  }

  @media (max-width: 650px) {
    .blog-entry-meta--down,
    .blog-comment-meta--down {
      padding: 20px 20px 0 20px;
    }
  }

  .blog-entry-meta--down a,
  .blog-comment-meta--down a {
    color: ${colors.meta};
  }

  .blog-entry-comment-icon a {
    display: inline-block;
    height: 30px;
  }

  .entry-lock-icon {
    /*position: absolute;*/
    display: inline-block;
    margin-right: 10px;
  }
  .entry-lock-icon .icon {
    height: 0.5em;
  }

  .blog-entry-meta--down .blog-entry-interactions,
  .blog-entry-meta--down .blog-entry-controls,
  .blog-comment-meta--down .blog-entry-interactions,
  .blog-comment-meta--down .blog-entry-controls  {
    line-height: 1;
    font-size: 0.85em;
    height: 30px;
  }

  a.comment-number {
    position: relative;
    top: -9px;
    margin-left: 10px;
    text-decoration: none;
    color: ${colors.links};
  }

  .blog-entry-controls {
    display: flex;
    justify-items: right;
    align-items: center;
    position: relative;
  }

  .blog-entry-controls .icon {
    cursor: pointer;
    margin-left: 20px;
    height: 20px;

  }
  .blog-entry-controls span {
    height: 20px;
  }

  .controls-dropdown-wrapper {
    position: absolute;
    top: 28px;
    left: -80px;
  }
  .blog-entry-controls-link,
  .blog-entry-controls-cog {
    position: relative;
  }

  .blog-entry-controls-link .controls-dropdown {
    padding: 10px;
    font-size: 10px;
    text-align: center;
  }

  .blog-entry-controls-link .controls-dropdown input {
    height: 25px;
    width: 100%;
    background: none;
    color: ${colors.text};
    border: none;
  }



  .controls-dropdown {
    position: relative;
    background: ${changeRgbaAlpha(colors.blocks, 1)};
    z-index: 2000;
    width: 150px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0px 0px 6px 0px ${changeRgbaAlpha(colors.text, 0.3)};
  }

  .controls-dropdown:before {
    position: absolute;
    top: -9px;
    right: 30px;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${changeRgbaAlpha(colors.blocks, 1)};
    z-index: 1999;
  }

  .controls-dropdown:after {
    position: absolute;
    top: -11px;
    right: 28px;
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid ${changeRgbaAlpha(colors.text, 0.1)};
    filter: blur(1px);
    z-index: 1998;
  }

  .controls-dropdown ul {
    list-style-type: none;
  }

  .controls-dropdown ul li button,
  .controls-dropdown ul li a {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    color: ${colors.text};
    font-family: ${fonts.entries.family};
    font-style: ${fonts.entries.style};
    font-weight: ${fonts.entries.weight};
    text-decoration: ${fonts.entries.textDecoration};
    font-size: inherit;
  }

  @media (max-width: 550px) {
    .controls-dropdown ul li button, .controls-dropdown ul li a {
      font-size: 1em;
    }
  }

  .controls-dropdown ul li button:hover,
  .controls-dropdown ul li a:hover {
    padding-left: 9px;
    background: ${changeRgbaAlpha(colors.text, 0.2)};
    border-left: 10px solid ${colors.accent};
    color: ${colors.text};
    font-weight: bold;
  }


  /****** ICONS ******/

  .blog-entry .icon-comments,
  .blog-comment .icon-comments {
    height: 30px;
    width: 34px;
  }
  .blog-entry .icon-reply-to-comment,
  .blog-comment .icon-reply-to-comment {
    height: 28px;
    width: 30px;
  }

  .blog-entry .icon-journal,
  .blog-comment .icon-journal,
  .blog-entry .icon-chat,
  .blog-comment .icon-chat {
    height: 27px;
    width: 27px;
  }

  .blog-entry .icon-bookmark-normal-view,
  .blog-comment .icon-bookmark-normal-view {
    height: 30px;
  }

  .blog-entry .icon-settings-cog,
  .blog-comment .icon-settings-cog {
    height: 20px;
    width: 20px;
  }

  .blog-entry-bookmark .main-outline,
  .blog-entry-author-wrapping .main-outline,
  .blog-entry-interactions .main-outline,
  .blog-entry-bookmark .filling-outline,
  .blog-entry-author-wrapping .filling-outline,
  .blog-entry-interactions .filling-outline,
  .blog-entry-bookmark .filling-area,
  .blog-entry-author-wrapping .filling-area,
  .blog-entry-interactions .filling-area {
    fill: ${colors.links};
  }
  .icon-reply-to-comment .main-outline,
  .icon-reply-to-comment .filling-outline,
  .icon-reply-to-comment .filling-area {
    fill: ${colors.meta};
  }
  .icon-lock .main-outline {
    fill: ${colors.headers};
  }


  .blog-entry-controls .icon-watch-thread.active .filling-area {
    transition: fill 0.2s;
    fill: ${colors.headers};
    opacity: 1;
  }
  .blog-entry-controls .blog-entry-controls-watch {
    height: 17px;
  }
  .blog-entry-controls-watch .icon-watch-thread {
    height: 17px;
  }

  .blog-entry-controls .icon .main-outline,
  .blog-entry-controls .icon .filling-area {
    fill: ${colors.meta};
    opacity: 0.5;
  }

  .blog-entry-controls .icon:hover .main-outline {
    transition: opacity 0.2s;
    opacity: 1;
  }

  .blog-entry-bookmark .icon .secondary-filling-area,
  .blog-entry-author-wrapping .icon .secondary-filling-area,
  .blog-entry-interactions .icon .secondary-filling-area {
     opacity: 0;
  }

  .blog-entry-bookmark .icon .filling-area,
  .blog-entry-author-wrapping .icon .filling-area,
  .blog-entry-interactions .icon .filling-area {
    transition: opacity 0.2s;
    opacity: 0;
    cursor: pointer;
  }

  .blog-entry-author-wrapping .icon:hover .filling-area,
  .blog-entry-interactions .icon:hover .filling-area {
    opacity: 1;
  }

  .blog-entry .icon-bookmark-normal-view .main-outline,
  .blog-comment .icon-bookmark-normal-view .main-outline  {
    fill: ${colors.text};
  }

  .blog-entry .icon-bookmark-normal-view .filling-area,
  .blog-comment .icon-bookmark-normal-view .filling-area  {
    fill: ${colors.blocks};
    transition: fill 0.2s;
    cursor: pointer;
    opacity: 1;
  }

  .blog-entry .icon-bookmark-normal-view .secondary-filling-area,
  .blog-comment .icon-bookmark-normal-view .secondary-filling-area {
    fill: ${darkenColor(colors.blocks)};
    cursor: pointer;
    opacity: 1;
  }


  .blog-entry .active .icon-bookmark-normal-view .filling-area,
  .blog-comment .active .icon.icon-bookmark-normal-view .filling-area {
    fill: ${colors.accent};
  }
  .blog-entry .active .icon-bookmark-normal-view .secondary-filling-area,
  .blog-comment .active .icon-bookmark-normal-view .secondary-filling-area {
    fill: ${darkenColor(colors.accent)};
  }
`;
