import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-right ${className}`}
    viewBox="0 0 7 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="arrow main-outline"
      d="m 0.00495962,10.422309 c -0.0257863,0.243288 0.0481045,0.493155 0.2421875,0.669922 0.2999132,0.302609 0.81796448,0.329693 1.14648438,0.05859 C 2.8725523,9.7157666 4.3379501,8.2653828 5.8116002,6.824653 6.1220038,6.4880832 6.6133991,6.1954187 6.5479283,5.668403 6.6134119,5.1413743 6.122047,4.8486788 5.8116002,4.512153 4.3380003,3.0717019 2.8724127,1.6228144 1.3936315,0.18793468 c -0.328296,-0.27137 -0.84632138,-0.245721 -1.14648438,0.05664 -0.388166,0.353534 -0.2948857,1.00081202 0.1132812,1.29687472 C 1.7662541,2.9161911 3.167439,4.2958213 4.5752721,5.668403 3.167439,7.0409847 1.7662541,8.4206146 0.36042832,9.7953563 0.15634482,9.943388 0.03074592,10.179022 0.00495962,10.422309 Z"
    />
  </svg>
);

export default Icon;
