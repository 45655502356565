import axios from 'axios';
import { API_URL } from 'configs/dybr';

export const reportErrorApi = async ({
  userToken,
  userInfo,
  userMessage,
  userEmail,
  appVersion,
  appStoredVersion,
  appStorage,
  browserInfo,
  errorMeta,
  reportHtml
}) => {
  try {
    let axiosOptions = {};
    if (userToken) {
      axiosOptions = {
        headers: { Authorization: `Bearer ${userToken}` }
      };
    }

    const response = await axios({
      ...axiosOptions,
      method: 'POST',
      data: {
        message: userMessage,
        private_data: reportHtml,
        userMessage,
        userToken,
        userInfo,
        userEmail,
        appVersion,
        appStoredVersion,
        appStorage,
        browserInfo,
        errorMeta
      },
      url: `${API_URL}/errors`
    });

    return { status: 'ok', ...response };
  } catch (error) {
    console.log('Error report', error);

    return { error };
  }
};
