import { HeaderGeneral } from 'components/DybrComponents/HeaderGeneral';
import React from 'react';
import { ContentWrapper } from 'components/DybrComponents/StyledServicePagesElements';
import ModerationSettings from 'scenes/Management/Community/ModerationSettings';
import { ServiceBackground } from 'components/DybrComponents/ServiceBackground';
import JoinRequests from 'scenes/Management/Community/JoinRequests';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

function ManageCommunity({ profile }) {
  if (!profile.isCommunity) {
    // todo change to управление профилем when it is available
    navigate('/settings');
    return null;
  }

  return (
    <ServiceBackground data-testid="ManageCommunity">
      <HeaderGeneral>
        <h1 data-testid="manage-community-header">управление сообществом</h1>
      </HeaderGeneral>
      <ContentWrapper>
        <ModerationSettings />
        <JoinRequests />
      </ContentWrapper>
    </ServiceBackground>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(connect(mapStateToProps)(ManageCommunity));
