import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 980px;
  margin: 70px auto 0 auto;
  padding-bottom: 180px;
`;

export const Block = styled.div`
  width: 100%;
  background-color: ${p => p.theme.backgroundLighter};
  margin-bottom: 35px;
  padding: 38px;
`;

export const Settings = styled.div`
  width: 100%;
  font-size: 14px;

  p:not(:first-child),
  button:not(:first-child) {
    margin-top: 20px;
  }

  .text-field {
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  column-gap: 40px;

  div {
    flex-grow: 1;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.textLighter};
  font-size: 14px;
  transition: height 0.3s, margin 0.6s;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;

  ${props => {
    switch (props.type) {
      case 'success':
        return `background-color: ${props.theme.accent};`;
      case 'error':
        return `background-color: ${props.theme.brand};`;
    }
  }}

  ${props =>
    props.show
      ? `opacity: 1;
    height: auto;
    padding: 5px;
    margin-bottom: 20px;
    `
      : ''};
`;

export const SettingsFlexbox = styled.div`
  display: flex;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
export const SettingSectionTitle = styled.div`
  h4 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 8px;
  }
  padding-right: 60px;
  width: 350px;
  font-size: 13px;
  flex-shrink: 0;

  @media (max-width: 760px) {
    width: 200px;
  }
  @media (max-width: 640px) {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
  }
`;

export const SettingSectionTitleBeta = styled(SettingSectionTitle)`
  h4 {
    &:after {
      content: 'beta';
      background: ${p => p.theme.brand};
      color: ${p => p.theme.textLighter};
      font-size: 12px;
      padding: 2px 4px;
      border-radius: 4px;
      margin-left: 4px;
    }
  }
`;

export const TextFieldWithButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 310px;
  column-gap: 20px;
  justify-content: flex-start;

  /* margin-top: 25px;*/

  div {
    flex: 1 0 160px;
  }

  .text-field {
    margin-bottom: 0px;
  }

  button {
    position: relative;
    margin-top: 0px;
    /*flex-grow: 1;
    height: 50px;
    padding: 13px 25px;
    width: 165px;*/
  }
`;
