import { createProfileApi } from 'api/profiles';
import { SetProfileAction } from 'store/user/actions/SetProfileAction';
import { setActiveProfileThunk } from 'store/user/thunks/setActiveProfileThunk';

export const createProfileThunk = ({
  blogSlug,
  blogTitle,
  nickname,
  isCommunity,
  settings = {},
  token,
  setToken
}) => async (dispatch, getState) => {
  let res = await createProfileApi({
    nickname,
    isCommunity,
    settings,
    blogSlug,
    blogTitle,
    token
  });

  if (!res.error) {
    dispatch(new SetProfileAction(res.data));
    // todo unless I put give it some timeout, it fails with 403
    // maybe because of too many requests
    setTimeout(async () => {
      await setActiveProfileThunk({ profileId: res.data.id, setToken, token })(
        dispatch,
        getState
      );
    }, 100);
    return res;
  } else {
    return { error: res.error };
  }
};
