import { addBookmarkApi, removeBookmarkApi } from 'api/bookmarks';

export function useBookmark({ setEntries, token, setEntry }) {
  async function toggleBookmark(entry) {
    let res;

    if (entry.meta.bookmark) {
      res = await removeBookmarkApi({ entryId: entry.id, token });
    } else {
      res = await addBookmarkApi({ entryId: entry.id, token });
    }

    if (res.error) return false;

    setEntries?.((currentEntries) =>
      currentEntries.map((e) =>
        e.id === entry.id
          ? {
              ...entry,
              meta: { ...entry.meta, bookmark: !entry.meta.bookmark }
            }
          : e
      )
    );
    setEntry?.((currentEntry) => ({
      ...currentEntry,
      meta: { ...currentEntry.meta, bookmark: !entry.meta.bookmark }
    }));
    return true;
  }
  return { toggleBookmark };
}
