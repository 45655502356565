import React from 'react';

export const CopyDesignIcon = ({ className }) => (
  <svg
    viewBox="0 0 27.390625 29.220704"
    className={`icon icon-copy-design ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="copy main-outline"
      d="M 9.1992188,0 5.2539062,0.005859 C 4.7798292,0.00930487 4.390625,0.4015777 4.390625,0.87109372 V 4.9394531 l -3.52734375,0.00391 C 0.38920352,4.9468053 0,5.3390777 0,5.8085937 V 28.365235 c 1.3130715e-4,0.465053 0.38957733,0.855468 0.86132812,0.855468 H 22.166016 C 22.606611,29.2213 23,28.830288 23,28.365235 v -4.082032 h 3.556641 c 0.440595,5.97e-4 0.833984,-0.390415 0.833984,-0.855468 V 0.87109372 c 0,-0.46951602 -0.392909,-0.85937498 -0.855469,-0.85937498 h -3.988281 z m -0.015625,1.7265625 13.3671872,0.021484 3.105469,-0.00195 c -4.17e-4,6.9334619 6.71e-4,13.8674195 0,20.8007815 H 23 V 5.8085937 c 0,-0.469516 -0.392909,-0.859375 -0.855469,-0.859375 H 18.15625 L 6.1269531,4.9394531 V 1.7460937 Z M 6.1269531,6.6660156 18.160156,6.6855466 h 3.103516 c -3.18e-4,5.2870094 3e-5,10.5743164 0,15.8613284 v 1.736328 c -3.4e-5,1.067684 1.03e-4,2.135453 0,3.203126 H 1.7363281 V 6.6855468 L 4.390625,6.6660156 Z"
    />
    <path
      className="copy filling-area"
      d="M 23,22.546878 V 5.8085938 c 0,-0.469516 -0.392909,-0.859375 -0.855469,-0.859375 h -3.988277 l -12.029296,-0.00977 -1.736329,5.4e-6 -3.527343,0.00391 C 0.389208,4.9468042 4e-6,5.3390788 4e-6,5.8085948 V 28.365235 c 1.32e-4,0.465053 0.389578,0.855468 0.861329,0.855468 h 21.304688 c 0.440595,5.97e-4 0.833984,-0.390415 0.833984,-0.855468 v -4.082032 z"
    />
  </svg>
);
