import React from 'react';
import styled from 'styled-components';

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.sidebar-tabs {
    justify-content: center;
    border-bottom: 1px ${p => p.theme.divider} solid;
    padding: 0px 30px;
    flex-wrap: nowrap;
  }
`;

const Tab = styled.div`
  display: inline-block;
  box-sizing: border-box;
  padding-bottom: 15px;
  cursor: pointer;
  height: 50px;
  padding: 10px 25px;
  color: ${p => p.theme.textLight};
  text-align: center;

  &.active {
    border-bottom: 4px solid ${p => p.theme.brand};
    font-weight: bold;
    color: ${p => p.theme.brand};
  }

  @media (max-width: 550px) {
    width: auto;
    height: 40px;
    padding: 7px;
  }

  .sidebar-tabs & {
    flex: 1 0 auto;
    padding: 7px;
  }
`;

function Tabs({ tabs, currentTab, onTabChange, containerClassName }) {
  return (
    <TabsContainer className={containerClassName}>
      {Boolean(tabs) &&
        tabs.map(t => (
          <Tab
            key={t.name}
            onClick={() => onTabChange(t.name)}
            className={currentTab === t.name ? 'active' : ''}
            data-testid={`tab-${t.name}`}
          >
            {t.title}
          </Tab>
        ))}
    </TabsContainer>
  );
}

export default Tabs;
