import React from 'react';
import NavBarContainer from './components/NavBarContainer';
// import { Button } from '../../components/StyledHeaderButton';

// import Sidebar from '../components/Sidebar';

// import PropTypes from 'prop-types';

// the navbar that shows to a guest user on all pages except the main one

function LoggedOutNavBar() {
  return (
    <NavBarContainer>
      <div data-testid="headerloggedout" />
      {/*<ul>

            <li>
              <Button onClick={this.toggleLogin}>Войти</Button>
            </li>
            <li>
          <Button>Зарегистрироваться</Button>
        </li>
        </ul>*/}
    </NavBarContainer>
  );
}

export default LoggedOutNavBar;
