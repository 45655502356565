import { Link } from '@reach/router';
import React from 'react';
import css from 'components/DybrComponents/NavTab.module.css';
import { classNames } from 'utils/helpers/classNames';

export function NavTab({ partial = true, ...props }) {
  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const isActive = partial ? isPartiallyCurrent : isCurrent;
        return {
          className: classNames({
            [css.link]: true,
            [css.active]: isActive
          })
        };
      }}
    />
  );
}
