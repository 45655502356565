import { joinCommunityApi } from 'api/communities';
import { AddCommunityAction } from 'store/user/actions/AddCommunityAction';

export const joinCommunityThunk = ({
  activeProfileId,
  community,
  token
}) => async (dispatch) => {
  const res = await joinCommunityApi({
    activeProfileId,
    communityId: community.id,
    token
  });

  if (!res.error) {
    if (res.data?.state === 'approved') {
      dispatch(new AddCommunityAction(activeProfileId, community));
    } else {
      alert('запрос на вступление отправлен');
    }

    return true;
  } else {
    alert(
      'не получилось отправить запрос. Попробуйте позднее или обратитесь за помощью к администрации'
    );

    // dispatchError(dispatch, res);
    return false;
  }
};
